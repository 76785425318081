@import '/src/css/variable.scss';

@keyframes opacityAnimation {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.addAni {

  animation: opacityAnimation 1s forwards;

}

.collectionItemEl {
  opacity: 0;
  transform: translateY(30px);
}

.dt {
  visibility: hidden;
}

.longimg {
  width: 100%;


}

.shortimg {
  width: 100%;
}


@media(min-width: 1200px) {

  .dt {
    visibility: visible;
  }
  .mo {
    visibility: hidden

  }

  .longimg {


  }


}

.collectionControl-moodwater {
  background-color: #ECE0CE !important;
  background-repeat: repeat;
  background-image: url('../../../img/pattern/natural-paper.png');
  background-size: 523px 384px;
}


.collectionItems {

  .moodwater {
    margin: 0 16px 40px 16px;
  }

  margin-top: 16px;
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  // padding-left: 16px;
  // padding-right: 16px;
  transition: all 500ms;

  .collectionLongImg {

    // background-color: red;
    width: 100%;
    max-width: 500px;
    height: 100%;

    // margin-left: 30px;
    margin-bottom: 40px;
    position: relative;
    transition: all 500ms;
  }

  .collectionItem {

    // background-color: blue;
    width: calc(50% - 24px);
    min-height: 300px;
    max-width: 250px;
    margin-left: 16px;
    margin-bottom: 46px;
    margin-top: 0;
    transition: all 500ms;
  }


}


@media(min-width: 1000px) {
  .collectionControl {

    max-width: unset;
  }


  .collectionItems {
    margin-top: 16px;
    width: 100%;
    max-width: 1000px;
    display: flex;
    margin-left: -48px;

    .moodwater {
      margin: 0 0 48px 48px;
    }

    // flex-wrap: wrap;

    .collectionLongImg {

      // width: 100%;
      width: calc(50% - 48px);

      margin-left: 48px;
      margin-bottom: 88px;

    }

    .collectionItem {
      margin-left: 48px;

      width: calc(25% - 48px);
      min-height: 300px;
      margin-bottom: 88px;
    }
  }
}


@media(min-width: 1200px) {
  .collectionControl {
    padding-left: 105px !important;
    padding-right: 105px !important;
    max-width: 1680px !important;

  }


  .collectionItems {
    margin-top: 16px;
    width: 100%;
    max-width: 1680px;
    padding-left: 90px;
    padding-right: 90px;
    // margin-right: -30px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -48px;

    .moodwater {
      margin: 0 0 88px 48px;
    }

    .collectionLongImg {
      //
      // width: 40%;
      width: calc(50% - 48px);

      max-width: unset;
      margin-left: 48px;
      // outline: black;
      margin-bottom: 88px;
    }

    .collectionItem {
      //
      // width: 20%;
      width: calc(25% - 48px);
      max-width: unset;
      min-height: 300px;
      margin-left: 48px;
      margin-bottom: 88px;
      // outline: black;
    }
  }
}

@media(min-width: 2780px) {
  .collectionControl {
    // padding-left: 120px;
    // padding-right: 120px;
    max-width: 2650px !important;
  }


  .collectionItems {
    margin-top: 16px;
    width: 100%;
    max-width: 2650px;
    // padding-left: 120px;
    // padding-right: 120px;
    display: flex;
    flex-wrap: wrap;

    .collectionLongImg {

      width: calc(33% - 48px);
      // width: 25%;
      max-width: unset;
      margin-left: 48px;
    }

    .collectionItem {

      width: calc(16.5% - 48px);
      // width: 12.5%;
      max-width: unset;
      min-height: 300px;
      margin-left: 48px;
    }
  }
}


.upSellingPopUp {
  // transition: $transition_default;

  &.popup-bg {
    background-color: $dim_bg;
    display: flex;
    align-items: flex-end;

    .popup-box {
      position: relative;
      height: 440px;
      width: 100%;
      top: unset;
      left: unset;
      right: unset;
      // bottom: 0;

      background-color: $main_bg;
      transition: transform 500ms;
      // transition-delay: 1s;

      transform: translateY(100%);
      // overflow: hidden;


    }

    overflow: hidden;
  }

  .upSellButton {
    width: 100%;
    position: absolute;
    bottom: 0;
  }


  &.visible {
    opacity: 1;
    z-index: 40;

    &.popup-bg {


      .popup-box {
        transform: translateY(0);


      }
    }


  }

  &.invisible {
    opacity: 0;
    z-index: -100;

  }

  .upSellContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;


    .discountBadge {
      padding: 5px 12px;
      background-color: $main-point;
      @include f12-sa(500);
      color: $main-bg;


    }

    .upSellText1 {
      margin-top: 16px;
      @include f18-se;
      color: $main-black;
    }

    .upSellText2 {
      margin-top: 8px;
      @include f13-sa(300);
      color: $main-black;
      text-align: center;
    }

    .upSellImage {
      margin-top: 24px;
      width: 264px;
      height: auto;
    }


    .upSellClose {
      position: absolute;
      right: 16px;
    }

    .upSellClose.mo {
      display: inline;
      top: -46px;

    }

    .upSellClose.dt {
      display: none;
      top: 16px

    }


  }

}


@media(min-width: $breakpoint-desktop) {

  .upSellingPopUp {
    // transition: $transition_default;

    &.popup-bg {
      background-color: $dim_bg;
      display: flex;


      .popup-box {
        height: 606px;
        width: 460px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // transition: opacity 1000ms;
        background-color: $main_bg;
        transition: unset;
        opacity: 0;


      }
    }

    .upSellButton {
      margin-top: 40px;
      width: 332px;
      position: relative;
      bottom: 0;
    }


    &.visible {
      opacity: 1;
      z-index: 1000;

      &.popup-bg {


        .popup-box {
          opacity: 1;
          transform: translate(-50%, -50%);


        }
      }


    }

    &.invisible {
      opacity: 0;
      z-index: -20;

    }

    .upSellContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 64px;


      .discountBadge {
        padding: 5px 14px;
        background-color: $main-point;
        @include f14-sa(500);
        color: $main-bg;


      }

      .upSellText1 {
        margin-top: 30px;
        @include f20-se;
        color: $main-black;
      }

      .upSellText2 {
        margin-top: 8px;
        @include f14-sa(300);
        color: $main-black;
        text-align: center;
      }

      .upSellImage {
        margin-top: 32px;
        width: 332px;
        height: auto;
      }


      .upSellClose.mo {
        display: none;
      }

      .upSellClose.dt {
        display: inline;

      }


    }

  }

}
