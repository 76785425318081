@import '/src/css/variable.scss';

.restocking-button-wrapper {
  width: 100%;
  display: block;

  .temporarily-out-of-stock {
    opacity: 0.3;
    background-color: #604448;
    padding: 17px 31px;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .temporarily-out-of-stock-text {
    @include font-seif(15px, normal);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  .restocking-button {
    cursor: pointer;
    background-color: #ff5316;
    border: solid 1px #000;
    padding: 17px 0;
    display: flex;
    justify-content: center;

    .alarm-img-wrapper {
      width: 16px;
      margin-right: 4px;
      align-items: center;
      display: flex;

      img {
        width: 100%;
      }
    }

    .alarm-text {
      @include font-seif(15px, normal);
      line-height: 1.47;
      color: #000;
    }
  }
}
