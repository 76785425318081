@import '/src/css/variable.scss';

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.collectionContentPositionDt {
  width: 100%;
  // padding: 120px;
}

// #ch-plugin-launcher {
//   display: none !important;
// }

.collectionPage-moodwater {
  background-color: #ECE0CE;
  background-repeat: repeat;
  background-image: url('../../img/pattern/natural-paper.png');
  background-size: 523px 384px;


  .mainHeaderContainer {
    background-color: transparent !important;

    .mainHeader.bg-color {
      background-color: #ECE0CE;
      background-repeat: repeat;
      background-image: url('../../img/pattern/natural-paper.png');
      background-size: 523px 384px;
    }
  }

  .subMenuArea {
    // padding-right: 16px;
    // padding-left: 16px;

    .swiper {
      // overflow: visible;

      .swiper-wrapper {
        border-bottom: 0 !important;
      }
    }
  }

  .collectionHeadComponent {
    margin-top: 0 !important;
    position: relative !important;
    width: 100%;
    background-color: #fffff5;


    .collectionHeadComponent-text-wrapper {
      position: absolute;
      left: 50%;
      width: 100%;
      top: 50%;
      z-index: 9;
      transform: translate(-50%, -50%);
      color: #FFFFF5;

      .decoText {
        color: #FFFFF5;
      }

      .collectionSelectorWrapper .curCollection {
        color: #FFFFF5;
      }
    }

    .collectionHeadComponent-moodwater-background {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      display: block !important;
      transition: opacity linear 0.5s;
      padding-bottom: 80%;
      background: transparent url('../../img/moodwater/collection_m.jpg') no-repeat center center;
      background-size: cover;
      video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: flex;
      }
    }

  }

  .expand {
    .collectionHeadComponent-moodwater-background {
      opacity: 0
    }

    .collectionHeadComponent-text-wrapper {
      position: absolute;
      left: 50%;
      width: 100%;
      top: 50%;
      z-index: 9;
      transform: translate(-50%, -50%);
      color: #604448 !important;;

      .decoText {
        color: #604448 !important;;

      }

      .collectionSelectorWrapper .curCollection {
        color: #604448 !important;
      }
    }

  }

}

.collectionPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 96px;

  // animation: fade-in 3s;
  animation-fill-mode: forwards;


  .collectionHeadComponent {
    margin-top: 72px;
    position: unset;

    .collectionHeadComponent-moodwater-background {
      display: none;
    }

    .decoText {
      color: #FFFFF5;
    }

    .decoText {
      @include f16-se;
      font-weight: 200;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      text-align: center;
      color: $main-black;
    }

    .selectorPosition {
      @include flex-center;
    }
  }

  .subMenuArea {
    width: 100%;
    margin-top: 24px;
    // padding-right: 16px;
    // padding-left: 16px;

    .swiper {
      // overflow: visible;

      .swiper-wrapper {
        border-bottom: 1px solid $main_line;
        width: 100%;
        margin: unset;
        padding-left: 16px;

        .swiper-slide {
          cursor: pointer;
          width: auto !important;

          &.on {
            .bottom-border {
              background-color: $main_brown;
              height: 2px;
              margin-top: 7px;
            }
          }

          .subMenu {
            @include font-sans(14px, 500);
            color: $main_brown;
            // padding-bottom: 7px;
            text-align: center;
          }
        }
      }
    }
  }
}

.header-logo-dt {
  opacity: 1;
  transition: $transition_default;

  &.transparent {
    opacity: 0;
  }
}

.collectionControl {
  margin-top: 16px;
  // animation: fade-in 500ms 500ms;
  animation-fill-mode: forwards;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  // padding-left: 16px;
  // padding-right: 16px;
  position: sticky;
  top: 0;
  transition: all 500ms;
  background-color: $main-bg;
  z-index: 8;

  .totalCount {
    @include f13-sa(300);
    color: $main_black;
  }
}

@media (min-width: $breakpoint-desktop-mid) {
  .collectionPage-moodwater {
    background-color: #ECE0CE;
    background-repeat: repeat;
    background-image: url('../../img/pattern/natural-paper.png');
    background-size: 523px 384px;

    .mainHeaderContainer {
      background-color: transparent !important;
    }

    .subMenuArea {
      display: none;
    }

    .collectionHeadComponent {
      margin-top: 104px !important;
      padding: 0 120px;
      background-color: #ECE0CE;
      background-repeat: repeat;
      background-image: url('../../img/pattern/natural-paper.png');
      background-size: 523px 384px;

      .collectionHeadComponent-text-wrapper {
        position: absolute;
        left: 50%;
        width: 100%;
        top: 50%;
        z-index: 9;
        transform: translate(-50%, -50%);
        color: #FFFFF5;

        .decoText {
          color: #FFFFF5;
        }

        .collectionSelectorWrapper .curCollection {
          color: #FFFFF5;
        }
      }

      .collectionHeadComponent-moodwater-background {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        display: block !important;
        transition: opacity linear 0.5s;
        padding-bottom: 40%;
        background: transparent url('../../img/moodwater/collection_p.jpg') no-repeat center center;
        background-size: cover;


        video {

          width: 100%;
          display: flex;

          img {
            width: 100%;
            display: flex;
            object-fit: cover;
          }
        }
      }

      .subMenuArea .swiper .swiper-wrapper {
        border-bottom: none !important;
      }
    }

    .expand {
      .collectionHeadComponent-moodwater-background {
        opacity: 1
      }

      .collectionHeadComponent-text-wrapper {
        position: absolute;
        left: 50%;
        width: 100%;
        top: 50%;
        z-index: 9;
        transform: translate(-50%, -50%);
        color: #FFFFF5 !important;;

        .decoText {
          color: #FFFFF5 !important;;

        }

        .collectionSelectorWrapper .curCollection {
          color: #FFFFF5 !important;
        }
      }

    }

  }
  .collectionPage {
    .subMenuArea {
      width: 100%;
      // margin-top: 72px;
      display: flex;

      padding-left: unset;
      border-bottom: unset;
      // border-style : groove;

      .swiper {
        .swiper-wrapper {
          border-bottom: unset;

          .swiper-slide {
            border-bottom: unset;

            &.on {
              .bottom-border {
                // visibility: hidden;
              }
            }
          }

          .subMenu {
            @include font-sans(16px, 500);
          }
        }
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .collectionPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 260px;

    .collectionHeadComponent {
      margin-top: 152px;

      .decoText {
        @include f36-se;
        font-weight: 200;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        text-align: center;
        color: $main-black;
      }

      .selectorPosition {
        @include flex-center;
      }
    }

    .subMenuArea {
      width: 100%;
      margin-top: 72px;
      display: flex;

      padding-left: unset;
      border-bottom: unset;
      // border-style : groove;

      .swiper {
        .subMenu {
          @include font-sans(16px, 500);
        }
      }
    }
  }

  .header-logo-dt {
    opacity: 1;
    transition: $transition_default;

    &.transparent {
      opacity: 0;
    }
  }

  .collectionControl {
    margin-top: 47px;
    display: flex;
    // height: 36px;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    padding-left: 0px;
    padding-right: 0px;
    position: sticky;
    top: 0;
    transition: all 500ms;
    background-color: $main-bg;
    z-index: 8;

    .totalCount {
      @include f13-sa(300);
      color: $main_black;
    }
  }

  .collectionItems {
    margin-top: 16px;
  }

  .collectionContentPositionDt {
    width: 100%;
    padding: 120px;
  }
}
