@import '/src/css/variable.scss';

.FooterIncenseVer {
  padding: 64px 40px;
  background-color: #27282a;
  color: #ece0ce;

  &.home {
    padding: 80px 40px;
    //background-color: rgba(0, 0, 0, 0.75);
    background-color: rgba(0, 0, 0);
    position: relative;
    z-index: 1;
  }

  .categories {
    white-space: nowrap;

    .categoriesWrapper {
      display: block;

      .categoryItem {
        margin-bottom: 12px;
        @include font-sans(18px, 500);
        line-height: normal;

        .img-icon {
          width: 14px;
          height: 14px;
          margin-left: 8px;
        }

        &:hover {
          cursor: pointer;
        }

        &.category {
          display: flex;
          align-items: center;
        }

        &.open {
          margin-bottom: 0;
        }
      }
    }
  }

  .categoriesBox {
    padding: 24px 0;
    display: grid;
    gap: 10px 24px;
    grid-template-columns: repeat(4, 1fr);

    &.pc {
      display: none;
    }

    div {
      width: fit-content;
      color: #ff5e26;
      @include font-sans(15px, 500);
      line-height: normal;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .divider {
    margin: 32px 0;
    height: 1px;
    background-color: #ece0ce;
  }

  .companyInfo {
    .title {
      margin-bottom: 12px;
      @include font-sans(14px, 500);
      line-height: 1.14;
    }

    .companyDetails {
      margin-bottom: 4px;
      @include font-sans(11px, 300);
      line-height: 1.45;
      color: rgba(235, 223, 204, 0.5);
    }

    .mores {
      display: flex;
      align-items: center;
      @include font-sans(12px, bold);
      line-height: 1.33;

      div {
        margin-right: 8px;
        color: rgba(235, 223, 204, 0.5);

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .loginOrSignup {
    margin-top: 24px;
    @include font-sans(14px, 500);
    line-height: 1.14;
    display: flex;
    align-items: center;

    div {
      padding: 13px 0;
      flex-grow: 1;
      border: 1px solid #ece0ce;
      text-align: center;

      &:hover {
        cursor: pointer;
      }

      &:last-child {
        border-left: none;
      }
    }
  }

  .icons {
    margin: 24px 0 32px;

    img {
      width: 30px;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .copyright {
    @include font-sans(12px, 300);
    line-height: 1.45;
  }
}

@media (min-width: $breakpoint-desktop-mid) {
  .FooterIncenseVer {
    padding: 64px 94px;

    &.home {
      padding: 80px 94px;
    }

    .categories {
      margin-bottom: 48px;
      display: block;
      // justify-content: space-between;
      align-items: center;

      .categoriesWrapper {
        display: flex;
        margin-top: 20px;

        &.open {
          margin-bottom: 0;
        }

        .categoryItem {
          margin-bottom: 0;
          margin-right: 32px;
        }
      }
    }

    .categoriesBox {
      &.mb {
        display: none;
      }

      &.pc {
        display: flex;
        gap: none;
      }

      &:last-child {
        margin-right: 0;
      }

      div {
        margin-right: 24px;
      }
    }

    .divider {
      display: none;
    }

    .loginOrSignup {
      width: 280px;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .FooterIncenseVer {
    padding: 85px 0 85px 180px;

    &.home {
      padding: 180px;
    }

    &.productPage {
      padding: 85px 48px;

      .divider {
        display: block;
      }

      .categories {
        margin-bottom: 0;
        flex-direction: column;
        align-items: flex-start;

        .categoriesWrapper {
          display: flex;
          flex-wrap: wrap;

          .categoryItem {
            margin-right: 20px;
            margin-bottom: 12px;

            &.open {
              margin-bottom: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .categoriesBox {
        &.mb {
          display: grid;
        }

        &.pc {
          display: none;
        }
      }
    }

    .categories {
      margin-bottom: 64px;
    }

    .loginOrSignup {
      width: 334px;
      margin: 48px 0 32px;
    }
  }
}
