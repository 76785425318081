$noto_serif: "Noto Serif KR", serif;
$noto_sans: "Noto Sans KR", sans-serif;
$BN_cringe_sans: "BNCringeSans", sans-serif;
$dillan: "MadeDillan", sans-serif;
$AntonRegular: "AntonRegular", sans-serif;
$BergenSans_Semi: "BergenSansSemi", sans-serif;
//main color
$main_bg: #fffff5;
$main_line: #efebe2;
$main_etc: #ecd7ae;
$main_brown: #c0a691;
$main_black: #604448;
$main_black_2: #654a4e;
$main_green_deep: #3a5244;
$main_white: #ffffff;
$main_button: #d5ff00;
$main_point: #ff5316;
$main_point_light: #ff642e;
$main_point_light_transparent: #ff5e26;
$main_point_pale: #b96b4f;
$main_placeholder: #cbb6a3;
$main_real_black: #000000;
$main_green: #e0ff43;
$main_strong_brown: #a7856a;
$main_bg_thick: #f7f7ed;
$main_bg_yellow: #f2efe5;
$main_bg_grey: #e8e4dd;

$menu_back: rgba(239, 235, 226, 0.5);
$product_back: rgba(239, 235, 226, 0.2); //  rgb(251,251, 242)
$product_back_rgb: rgb(251, 251, 242);
$cart_back: rgba(239, 235, 226, 0.3);
$account_back: rgba(239, 235, 226, 0.15);
$account_bg: rgb(250, 249, 240);

$dim_bg: rgba(96, 68, 72, 0.5);

// blue set
$blue_bg: #ecfbff;
$blue_font: #2f53a5;
$blue_subfont: #97bfd9;
$blue_point: #2a46d1;

// light blue set
$light_blue_bg: #f5fffc;
$light_blue_font: #182916;
$light_blue_subfont: #698d78;

// red set
$red_bg: #fef7f6;
$red_font: #9f5151;
$red_subfont: #dfa590;

// beige_set
$beige_bg: #ede2ce;

//breakpoints

$breakpoint-desktop: 1200px;
$breakpoint-desktop-home: 900px;
$breakpoint-desktop-mid: 768px;
$breakpointDt2: 1600px;

// transitio
$transition_default: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

//font func
@mixin font-dillan($fontSize, $fontWeight, $lineHeight) {
  font-family: $dillan;
  font-size: $fontSize;
  font-weight: $fontWeight;
  line-height: $lineHeight;
}

@mixin font-seif($font-size, $weight) {
  font-family: $noto_serif;
  font-weight: $weight;
  font-size: $font-size;
}

@mixin font-bergen($font-size, $weight) {
  font-family: $BergenSans_Semi;
  font-weight: $weight;
  font-size: $font-size;
}

@mixin font-anton($font-size, $weight) {
  font-family: $AntonRegular;
  font-weight: $weight;
  font-size: $font-size;
}

@mixin font-sans($font-size, $weight) {
  font-family: $noto_sans;
  font-weight: $weight;
  font-size: $font-size;
}

// desktop mixin
@mixin f36-se {
  font-family: $noto_serif;
  font-weight: 300;
  font-size: 36px;
  line-height: 50px;
}

@mixin f28-se {
  font-family: $noto_serif;
  font-weight: 200;
  font-size: 28px;
  line-height: 40px;
}

@mixin f20-se {
  font-family: $noto_serif;
  font-weight: 200;
  font-size: 20px;
  line-height: 28px;
}

@mixin f18_se {
  font-family: $noto_serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
}

@mixin f15-se {
  font-family: $noto_serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
}

@mixin f18-sa($weight) {
  font-family: $noto_sans;
  font-weight: $weight;
  font-size: 18px;
  line-height: 1.44;
}

// mobile mixin

@mixin f24-se {
  font-family: $noto_serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 34px;
}

@mixin f16-se {
  font-family: $noto_serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

@mixin f16b-se {
  font-family: $noto_serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

@mixin f14-se {
  font-family: $noto_serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}

@mixin f30-se {
  font-family: $noto_serif;
  font-size: 30px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
}

@mixin f40-se {
  font-family: $noto_serif;
  font-weight: 300;
  font-size: 40px;
  line-height: 1;
}

@mixin f14b-se {
  font-family: $noto_serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

@mixin f13-se {
  font-family: $noto_serif;
  font-weight: 200;
  font-size: 13px;
  line-height: 22px;
}

@mixin f14-sa($weight) {
  font-family: $noto_sans;
  font-weight: $weight;
  font-size: 14px;
  line-height: 20px;
}

@mixin f9-sa($weight) {
  font-family: $noto_sans;
  font-weight: $weight;
  font-size: 9px;
  line-height: 1.2;
}

@mixin f10-sa($weight) {
  font-family: $noto_sans;
  font-weight: $weight;
  font-size: 10px;
  line-height: 1.2;
}

@mixin f12-sa($weight) {
  font-family: $noto_sans;
  font-weight: $weight;
  font-size: 12px;
  line-height: 18px;
}

@mixin f16-sa($weight) {
  font-family: $noto_sans;
  font-weight: $weight;
  font-size: 16px;
  line-height: 24px;
}

@mixin f15-se {
  font-family: $noto_serif;
  font-weight: 200;
  font-size: 15px;
  line-height: 22px;
}

@mixin f12-se {
  font-family: $noto_serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 22px;
}

@mixin f13-sa($weight) {
  font-family: $noto_sans;
  font-weight: $weight;
  font-size: 13px;
  line-height: 18px;
}

@mixin f11-sa($weight) {
  font-family: $noto_sans;
  font-weight: $weight;
  font-size: 11px;
  line-height: 16px;
}

@mixin f15-sa($weight) {
  font-family: $noto_sans;
  font-weight: $weight;
  font-size: 15px;
  line-height: 16px;
}

@mixin f-bnc($font-size, $weight) {
  font-family: $BN_cringe_sans;
  font-size: $font-size;
  font-weight: $weight;
}

@mixin cta_button {
  width: 264px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: $main_black;
  @include font-sans(14px, normal);
  border: 1px solid $main_brown;
}

@mixin cta_button_dt {
  width: 380px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: $main_black;
  @include f15-se;
  font-weight: normal;
  border: 1px solid $main_brown;
}

@mixin cta_gray_button {
  width: 264px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: $main_line;
  color: $main_black;
  @include f14-se;
  border: 0px;
}

@mixin cta_gray_button_dt {
  width: 380px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: $main_line;
  color: $main_black;
  @include f15-se;
  border: 0px;
}

@mixin cta_brown_button {
  width: 264px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: $main_black;
  color: $main_white;
  opacity: 0.2;
  @include f14-se;
  border: 0px;
}

@mixin cta_brown_button_dt {
  width: 380px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: $main_black;
  color: $main_white;
  opacity: 0.2;
  @include f15-se;
  border: 0px;
}

@mixin cta_green_button {
  width: 264px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: $main_green;
  color: $main_real_black;
  @include f14-se;
  border: 0;
}

@mixin cta_green_button_dt {
  width: 380px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: $main_green;
  color: $main_real_black;
  @include f15-se;
  border: 0;
}

@mixin cta_gray_button_sm {
  width: 96px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: $main_line;
  color: $main_black;
  @include f13-sa(300);
}

@mixin flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin transition($opt) {
  -webkit-transition: unquote($opt);
  -moz-transition: unquote($opt);
  -ms-transition: unquote($opt);
  -o-transition: unquote($opt);
  transition: unquote($opt);
}

@mixin transform-transition($opt) {
  -webkit-transition: -webkit-transform unquote($opt);
  -moz-transition: -moz-transform unquote($opt);
  -ms-transition: -ms-transform unquote($opt);
  -o-transition: -o-transform unquote($opt);
  transition: transform unquote($opt);
}

@mixin transform($opt) {
  -webkit-transform: unquote($opt);
  -moz-transform: unquote($opt);
  -ms-transform: unquote($opt);
  -o-transform: unquote($opt);
  transform: unquote($opt);
}

@mixin opacity($opt) {
  $alpha: $opt * 100;
  opacity: $opt;
  filter: alpha(opacity=$alpha);
}

.border-brush-horizontal {
  background-repeat: repeat;
  width: 100%;
  height: 2px;
  background-image: url('../img/pattern/border_brush_horizontal.svg');
  background-size: 110px 2px;
}

.border-brush-vertical {
  background-repeat: repeat;
  min-width: 2px;
  background-image: url('../img/pattern/border_brush_vertical.svg');
  background-size: 2px 110px;
}

.border-brush-blue-color-vertical {
  background-repeat: repeat;
  min-width: 2px;
  background-image: url('../img/pattern/border-brush_blue_vertical.svg');
  background-size: 2px 44px;
}
.border-brush-purple-color-vertical {
  background-repeat: repeat;
  min-width: 2px;
  background-image: url('../img/pattern/border-brush_purple_vertical.svg');
  background-size: 2px 44px;
}

.border-brush-darkBrown-color-vertical {
  background-repeat: repeat;
  min-width: 2px;
  background-image: url('../img/pattern/border-brush_darkBrown_vertical.svg');
  background-size: 2px 44px;
}


.border-brush-blue-color {
  background-repeat: repeat;
  width: 100%;
  height: 2px;
  background-image: url('../img/pattern/border_brush_blue.svg');
  background-size: 114px 2px;
}

.border-brush-purple-color {
  background-repeat: repeat;
  width: 100%;
  height: 2px;
  background-image: url('../img/pattern/border_brush_purple.svg');
  background-size: 114px 2px;
}

.border-brush-brown-color {
  background-repeat: repeat;
  width: 100%;
  height: 2px;
  background-image: url('../img/pattern/border_brush_brown.svg');
  background-size: 114px 2px;
}

.border-brush-dark-brown-color {
  background-repeat: repeat;
  width: 100%;
  height: 2px;
  background-image: url('../img/pattern/border_brush_darkBrown.svg');
  background-size: 114px 2px;
}

$bounce: cubic-bezier(0.17, 0.67, 0.3, 1.33);
$Power1EaseOut: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$Power2EaseOut: cubic-bezier(0.215, 0.61, 0.355, 1);
$Power3EaseOut: cubic-bezier(0.165, 0.84, 0.44, 1);
$Power4EaseOut: cubic-bezier(0.23, 1, 0.32, 1);
$Power1EaseIn: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$Power2EaseIn: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$Power3EaseIn: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$Power4EaseIn: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ExpoEaseOut: cubic-bezier(0.19, 1, 0.22, 1);
$ExpoEaseIn: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ExpoEaseInOut: cubic-bezier(1, 0, 0, 1);
$SineEaseOut: cubic-bezier(0.39, 0.575, 0.565, 1);
$SineEaseIn: cubic-bezier(0.47, 0, 0.745, 0.715);
$Power1EaseInOut: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$Power2EaseInOut: cubic-bezier(0.645, 0.045, 0.355, 1);
$Power3EaseInOut: cubic-bezier(0.77, 0, 0.175, 1);
$Power4EaseInOut: cubic-bezier(0.86, 0, 0.07, 1);
$SlowEaseOut: cubic-bezier(0.04, 1.15, 0.4, 0.99);
$easing: $Power2EaseOut;
