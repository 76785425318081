@import '../../../../../css/variable.scss';

@keyframes scroll-top-down {
  0% {
    top: -30px;
  }
  50% {
    top: -22px;
  }
  100% {
    top: -30px;
  }
}

.full-video-section {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 1;
  border-bottom: 2px solid #27282a;
  transition: height 0.5s ease-out;

  video {
    z-index: 2;
    width: 100%;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    display: block;
    overflow: hidden;
    object-fit: cover;
    //background: transparent url('../../../../../img/homeRenewal/collins_main_30s_9x16-low_000.jpg') no-repeat center center;
    //background-size: cover;
  }

  .video-background {
    background: transparent url('../../../../../img/homeRenewal/MoodSoap/web_home_top_mobile_clean_fast2160.jpg') no-repeat center center;
    background-size: cover;
    width: 100%;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .poster-img-wrapper {
    width: 100%;
    position: fixed;
    z-index: 1;
    height: calc(var(--vh, 1vh) * 100);
    object-fit: cover;
    display: flex;
  }

  .textWrapper {
    color: #FFFFF5;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    //width: 70%;
    transform: translate(-50%, -50%);
  }


  .mainTitle {
    @include font-dillan(32px, normal, 1.19);
    white-space: nowrap;

    // .textLine {
    //   display: none;
    // }
    img {
      width: 100%
    }

    .textLine {
      opacity: 0;
      transform-origin: center top;
      transform-style: preserve-3d;
      transform: translateY(100%) rotateX(-80deg);
      transition: opacity 0s $Power2EaseOut, transform 0s $Power2EaseOut;
      text-align: center;

      .slogan-wrapper {
        width: 200px;
        margin: auto;
        display: flex;
      }


      .event-img-wrapper {
        width: 180px;
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        transform: translate(-50%, -50%);

        picture {
          width: 100%;
          display: flex;

          img {
            width: 100%;
            display: flex;
          }
        }
      }

      .event-img-hover-wrapper {
        width: 180px;
        position: absolute;
        top: 50%;
        opacity: 0;
        z-index: 2;
        left: 50%;
        cursor: pointer;
        transform: translate(-50%, -50%);

        picture {
          width: 100%;
          display: flex;

          img {
            width: 100%;
            display: flex;
          }
        }
      }

      .event-img-hover-wrapper:hover {
        opacity: 1;
      }

      .mood-water-wrapper {
        margin-top: 24px;
        @include font-dillan(72px, 400, 60px);
        text-align: center;
        color: #ECE0CE;
      }

      .mood-water-description-wrapper {
        .mood-water-description {
          @include font-sans(14px, 500);
          line-height: 20px;
          color: #ECE0CE;
          text-align: center;
          margin-top: 16px;
        }

        .mood-water-description:first-child {
          margin-top: 24px;
        }
      }


      //div{
      //  font-size: 16px;
      //  color: black;
      //}

      //&.scroll {
      //  transform: none;
      //  opacity: 1;
      //  transition-duration: 0.8s;
      //
      //  @for $i from 1 through 4 {
      //    &:nth-child(#{$i}) {
      //      transition-delay: $i * 0.3s;
      //    }
      //  }
      //}
    }

    .numberLine {
      display: flex;
      justify-content: center;
      font-size: 56px;
      opacity: 0;
      transform-origin: center top;
      transform-style: preserve-3d;
      transform: translateY(100%) rotateX(-80deg);
      transition: opacity 0s $Power2EaseOut, transform 0s $Power2EaseOut;
      text-align: center;

      &.scroll {
        transform: none;
        opacity: 1;
        transition-duration: 0.8s;

        @for $i from 1 through 4 {
          &:nth-child(#{$i}) {
            transition-delay: $i * 0.3s;
          }
        }
      }
    }


    // span {
    //   // position: relative;
    //   // bottom: -1em;
    //   opacity: 0;
    //   animation: move-text 0.75s forwards;
    // }
  }

  .mainSubTitle {
    //margin-top: 8px;
    margin-top: 20px;
    @include font-sans(16px, 500);
    line-height: 1.38;
    white-space: nowrap;
    opacity: 0;
    color: black;
    transform-origin: center top;
    transform-style: preserve-3d;
    transform: translateY(100%);
    transition: opacity 0.3s $Power2EaseOut, transform 0.3s $Power2EaseOut;
    text-align: center;

    &.scroll {
      transform: none;
      opacity: 1;
      transition-duration: 1s;

      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          transition-delay: $i * 0.3s;
        }
      }
    }
  }

  .orange-text {
    @include font-sans(15px, bold);
    line-height: 1.38;
    text-align: center;
    color: black;
    margin-top: 20px;
    white-space: nowrap;
    opacity: 0;
    transform-origin: center top;
    transform-style: preserve-3d;
    transform: translateY(100%);
    transition: opacity 0.3s $Power2EaseOut, transform 0.3s $Power2EaseOut;

    &.scroll {
      transform: none;
      opacity: 1;
      transition-duration: 1s;

      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          transition-delay: $i * 0.3s;
        }
      }
    }
  }

  .mood-water-button-wrapper {
    cursor: pointer;
    width: 100%;
    max-width: 326px;
    background-color: #ff5416;
    display: flex;
    position: absolute;
    bottom: 50px;
    z-index: 2;
    padding: 18px;
    align-content: center;
    justify-content: center;
    left: 50%;
    border: 1px solid #27282a;
    transform: translateX(-50%);

    .mood-water-button-lottie-wrapper {
      width: 38px;
      position: relative;

      img {
        width: 100%;
        display: flex;
      }
    }

    .mood-water-button-text {
      @include font-seif(14px, 500);
      line-height: 20px;
      color: #27282a;
      align-content: center;
      display: block;
    }
  }

  .event-signup-button-wrapper {
    transform-origin: center top;
    transform-style: preserve-3d;
    transition: opacity 0.3s;
    opacity: 0;
    height: 56px;
    max-width: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin: 24px 24px 0 24px;
    background-color: #ff5316;
    color: #27282a;
    border: solid 2px #27282a;

    &:hover {
      background-color: #27282a;
      color: #dfd8c7;
      border: 0;


    }

    &:hover > .arrow-img {
      content: url('../../../../../img/collinsday1969/arrow.svg');
    }


    .event-signup-text {
      @include font-sans(16px, 500);
      text-align: center;
      line-height: normal;
      letter-spacing: normal;
      color: inherit;

    }

    .arrow-img {
      color: #27282a;
      width: 48px;
      height: 48px;
    }
  }

  .logo-img-wrapper {
    width: 55px;
    margin: 16px auto;
    opacity: 0;
    //height: 21px;
    transform: translateY(100%) rotateX(-80deg);
    transition: opacity 0s $Power2EaseOut, transform 0s $Power2EaseOut;
    transform-style: preserve-3d;

    //&.scroll {
    //  transform: none;
    //  opacity: 1;
    //  transition-duration: 0.8s;}

    img {
      width: 100%;
    }
  }

  .scroll-icon-wrapper {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;


    .scroll-icon-text {
      @include f-bnc(14px, bold);
      color: #fffff5;
      line-height: 1;
      margin-top: 6px;
    }

    img {
      width: 16px;
      top: -30px;
      left: 0;
      right: 0;
      display: block;
      margin: auto;
      position: absolute;
      will-change: top;
      animation: scroll-top-down 1.5s ease-in infinite;

    }
  }
}

@media(min-width: $breakpoint-desktop-mid) {

  .full-video-section {
    .video-background {
      background: transparent url('../../../../../img/homeRenewal/MoodSoap/web_home_top_desktop_clean_fast1080.jpg') no-repeat center center;
      background-size: cover;
    }
  }

}

@media (min-width: $breakpoint-desktop) {

  .full-video-section {
    .textWrapper {
      //width: 40%;
    }

    .mainTitle {
      @include font-dillan(80px, normal, 1);
      // .textLine {
      //   display: none;
      // }
      .textLine {
        .slogan-wrapper {
          width: 32vh;
          max-width: 300px;
          margin: auto;
          display: flex;
        }

        .event-img-wrapper {
          width: 270px;
          position: absolute;
          top: 50%;
          left: 50%;
          cursor: pointer;
          transform: translate(-50%, -50%);

          picture {
            width: 100%;
            display: flex;

            img {
              width: 100%;
              display: flex;
            }
          }
        }

        .event-img-hover-wrapper {
          width: 270px;
          position: absolute;
          top: 50%;
          opacity: 0;
          z-index: 2;
          left: 50%;
          cursor: pointer;
          transform: translate(-50%, -50%);

          picture {
            width: 100%;
            display: flex;

            img {
              width: 100%;
              display: flex;
            }
          }
        }

        .mood-water-wrapper {
          margin-top: 40px;
          @include font-dillan(13.7vh, 400, 11.5vh);
          //@include font-dillan(120px, 400, 100px);
          text-align: center;
          color: #ECE0CE;
        }

        .mood-water-description-wrapper {
          .mood-water-description {
            //@include font-sans(14px, 500);
            @include font-sans(1.6vh, 500);
            //line-height: 20px;
            line-height: 2.3vh;
            color: #ECE0CE;
            text-align: center;
            margin-top: 16px;
          }

          .mood-water-description:first-child {
            margin-top: 40px;
          }

        }


      }

      .numberLine {
        display: flex;
        justify-content: center;
        font-size: 80px;
        opacity: 0;
        transform-origin: center top;
        transform-style: preserve-3d;
        transform: translateY(100%) rotateX(-80deg);
        transition: opacity 0s $Power2EaseOut, transform 0s $Power2EaseOut;
        text-align: center;

        &.scroll {
          transform: none;
          opacity: 1;
          transition-duration: 0.8s;

          @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
              transition-delay: $i * 0.3s;
            }
          }
        }
      }


      // span {
      //   // position: relative;
      //   // bottom: -1em;
      //   opacity: 0;
      //   animation: move-text 0.75s forwards;
      // }
    }

    .mainSubTitle {
      //margin-top: 8px;
      margin-top: 30px;
      @include font-sans(25px, 500);
      line-height: 1.38;
      white-space: nowrap;
      opacity: 0;
      color: black;
      transform-origin: center top;
      transform-style: preserve-3d;
      transform: translateY(100%);
      transition: opacity 0.3s $Power2EaseOut, transform 0.3s $Power2EaseOut;
      text-align: center;

      &.scroll {
        transform: none;
        opacity: 1;
        transition-duration: 1s;

        @for $i from 1 through 4 {
          &:nth-child(#{$i}) {
            transition-delay: $i * 0.3s;
          }
        }
      }
    }

    .orange-text {
      @include font-sans(20px, bold);
      line-height: 1.38;
      text-align: center;
      color: black;
      margin-top: 20px;
      white-space: nowrap;
      opacity: 0;
      transform-origin: center top;
      transform-style: preserve-3d;
      transform: translateY(100%);
      transition: opacity 0.3s $Power2EaseOut, transform 0.3s $Power2EaseOut;

      &.scroll {
        transform: none;
        opacity: 1;
        transition-duration: 1s;

        @for $i from 1 through 4 {
          &:nth-child(#{$i}) {
            transition-delay: $i * 0.3s;
          }
        }
      }
    }

    .mood-water-button-wrapper {
      cursor: pointer;
      margin: auto;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 326px;
      background-color: #ff5416;
      display: flex;
      position: absolute;
      bottom: 80px;
      z-index: 2;
      padding: 18px;
      align-content: center;
      justify-content: center;

      .mood-water-button-lottie-wrapper {
        width: 38px;
        position: relative;

        img {
          width: 100%;
          display: flex;
        }
      }


      .mood-water-button-text {
        @include font-seif(14px, 500);
        line-height: 20px;
        color: #27282a;
        align-content: center;
        display: block;
      }
    }


    .event-signup-button-wrapper {
      transform-origin: center top;
      transform-style: preserve-3d;
      transition: opacity 0.3s;
      opacity: 0;
      height: 56px;
      max-width: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin: 40px 24px 0 24px;
      background-color: #ff5316;
      color: #27282a;
      border: solid 2px #27282a;

      &:hover {
        background-color: #27282a;
        color: #dfd8c7;
        border: 0;


      }

      &:hover > .arrow-img {
        content: url('../../../../../img/collinsday1969/arrow.svg');
      }


      .event-signup-text {
        @include font-sans(16px, 500);
        text-align: center;
        line-height: normal;
        letter-spacing: normal;
        color: inherit;

      }

      .arrow-img {
        color: #27282a;
        width: 48px;
        height: 48px;
      }
    }

    .logo-img-wrapper {
      width: 104px;
      margin: 32px auto;
      opacity: 0;
      //height: 21px;
      transform: translateY(100%) rotateX(-80deg);
      transition: opacity 0s $Power2EaseOut, transform 0s $Power2EaseOut;
      transform-style: preserve-3d;

      //&.scroll {
      //  transform: none;
      //  opacity: 1;
      //  transition-duration: 0.8s;}

      img {
        width: 100%;
      }
    }

    .scroll-icon-wrapper {
      position: absolute;
      bottom: 40px;
      left: 0;
      right: 0;
      text-align: center;

      .scroll-icon-text {
        @include f-bnc(20px, bold);
        color: #fffff5;
        line-height: 1;
        margin-top: 8px;
      }

      img {
        width: 24px;
      }
    }
  }

}
