@import '/src/css/variable.scss';


.imgSlider {

  .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0px;
    margin-bottom: 24px;
  }

  .swiper-lazy-preloader-collins {
    --swiper-theme-color: #cbb6a3;
  }

}

.pagination-main-detail-images {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 9;

}


.swiper-pagination-bullet {
  background-color: transparent !important;
  border: solid 1px $main_black !important;
}

.swiper-pagination-bullet-active {

  background-color: $main_black !important;

}


.imgSliderContent {
  background-size: contain;

  &.cover {
    background-size: cover;
  }
}


.imageThumbnail.swiper-pagination-bullet-active {
  // background-color: transparent !important;
  // border: solid 1px $main_black !important;
}

.pagination-dt {
  display: none;
}


@media(min-width: $breakpoint-desktop) {


  .imageThumbnail {
    width: auto !important;
    height: auto !important;
    border-radius: 0 !important;
    background-color: #efebe2 !important;
    border: none !important;
    opacity: 1 !important;
  }


  .imageThumbnail.swiper-pagination-bullet-active {
    background-color: transparent !important;
    border: solid 1px $main_black !important;
  }


  .pagination-dt {
    display: block;
  }


  .imgSliderContent {
    background-size: min(100%, 720px);

    &.cover {
      background-size: cover;
    }

  }


  .hoverCursorPrev {
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 5;
  }


  .hoverCursorNext {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 5;


  }


}