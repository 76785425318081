
@import '/src/css/variable.scss';

.broWrapper{
   
    margin-bottom: 32px;
   .broTitle {
    @include f13-sa(400);
    color: $main_black;
    margin-bottom: 17px;
   }

   .broList {
       display: flex;
       flex-direction: row;
       .broItem {
           display: flex;
           flex-direction: column;
           margin-right: 12px;
           cursor: pointer;
           .image {
            border: solid $main_brown 1px;
            margin-bottom: 5px;
            width: 94px;
            height: 94px;
           }
           .title {
            @include f12-sa(300);
            color: $main_brown;
           }

           &.selected {

            cursor:default;
           
            .image {
                border: solid $main_black 1px;
            }

            .title {
                color: $main_black;
            }

           }
       }

 
   }

}