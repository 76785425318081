@import '/src/css/variable.scss';

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.deskTopOnly {
  display: none !important;
}

.mobileOnly {
  display: block !important;
}

.npayBtn {
  display: flex;
  justify-content: center;
  // display: none;

  &.show {
    display: flex;
  }
}

.pathArea {
  margin-bottom: 32px;
  display: flex;
  align-items: center;

  .pathName {
    // font-family: NotoSansCJKkr;
    @include f12-sa(300);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $main-brown;
    margin-right: 4px;
  }

  .pathArrow {
    width: 8px;
    height: 8px;
    margin-right: 4px;
  }
}

.upsell-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  .upsell-title {
    @include f13-sa(400);
    color: $main_black;
    margin-bottom: 16px;
  }
}

.upsell-option-padding {
  padding: 24px 48px 32px 48px;
}

.upsell-only-one-wrapper {
  display: flex;
  flex-direction: column;


  .upsell-title {
    @include f13-sa(400);
    color: $main_black;
    margin-bottom: 8px;
  }

  .upsell-only-one-item-wrapper {
    border: 1px solid #EFEBE2;
    padding: 8px;

    .upsell-only-one-item-info-wrapper {
      display: flex;

      .upsell-only-one-item-img-wrapper {
        display: flex;
        background-color: #efebe2;
        width: 48px;
        height: 48px;

        img {
          width: 48px;
          height: 48px;
          display: flex;
          object-fit: cover;
        }
      }

      .upsell-only-one-item-description-wrapper {
        display: block;
        margin-left: 8px;
        width: 100%;

        .upsell-only-one-item-title {
          @include font-sans(13px, 350);
          color: #CBB6A3;
          font-style: normal;
          line-height: 18px;
          margin-bottom: 4px;
        }

        .upsell-only-one-item-description {
          display: flex;

          .upsell-only-one-item-description-regular-price {
            color: #C0A691;
            @include font-sans(13px, 400);
            font-style: normal;
            font-weight: 400;
            margin-right: 2px;
            line-height: 18px;
          }

          .line-through {
            text-decoration: line-through;
          }

          .upsell-only-one-item-description-sale-price {
            color: #FF5316;
            @include font-sans(13px, 400);
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
          }

          .upsell-only-one-item-description-discount-rate {
            color: #FF5316;
            @include font-sans(13px, 400);
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            margin-left: 6px;
          }
        }

        .upsell-only-one-item-button {
          padding: 4px 8px;
          border-radius: 100px;
          background-color: #604448;
          color: #FFFFF5;
          @include font-sans(11px, 400);
          font-style: normal;
          line-height: 13px;
          display: flex;
          width: fit-content;
          float: right;
          margin-top: 4px;
          cursor: pointer;
        }
      }

      .upsell-only-one-restocking-button {
        display: flex;
        float: right;

        .alarm-img-wrapper {
          display: flex;
          width: 12px;

          img {
            width: 100%;
            display: flex;
          }
        }

        .alarm-text {
          margin-left: 2px;
          color: #3A5244;
          @include font-sans(11px, 400);
          font-style: normal;
          line-height: 13px;
        }
      }
    }
  }
}


.thunder {
  margin-right: 4px;

  &.dt {
    display: none;
  }

  &.mo {
    display: inline;
  }
}

.npay-custom-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #000000;

  height: 56px;
  cursor: pointer;

  .npay-img {
    width: 112px;
    height: 21px;
  }
}

.npay-button {
  display: flex;
  justify-content: center;
}

.npay-button-dt .npay_storebtn_bx {
  margin-top: 16px !important;
}

.npay-button-cart .npay_storebtn_bx {
  margin-bottom: 32px !important;
}

.mobile-active {
  max-height: calc(calc(var(--vh, 1vh) * 100) - 258px);
}

.purchaseMenu-wrapper {
  padding: 40px 48px;
  // padding-right:  0;
  //overflow: visible;
  // animation: fade-in 500ms;
  animation-fill-mode: forwards;
  overflow: auto;
  background-color: #FFFFF5;

  .npay_button_box {
    animation: fade-in 500ms;
    animation-fill-mode: forwards;
  }

  .purchaseMenu-category {
    @include f13-sa(300);
    margin-bottom: 30vh;
    color: $main_black;
    letter-spacing: 0;
  }

  .purchaseMenu-mainItem {
    position: relative;
    z-index: 2;
  }

  .purchaseMenu-name {
    @include f13-sa(100);
    color: $main_brown;
    letter-spacing: 0;
    line-height: 16px;

    margin: 0;
    padding: 0;
    margin-bottom: 4px;
  }

  .purchaseMenu-mainText {
    // @include font-sans(20px, 500);
    @include font-seif(20px, 200);
    margin: 0;
    margin-bottom: 10px;
    color: $main_black;
  }

  .purchaseMenu-priceAndReview {
    display: flex;
    margin-bottom: 40px;
    position: relative;

    .price-no-sale {
      @include f12-sa(300);
      color: $main_black;
    }

    .price-before {
      @include f12-sa(300);
      text-decoration: line-through;
      color: $main_brown;
      letter-spacing: 0;
      margin-left: 2px;
    }

    .price-after,
    .discount-rate {
      @include f12-sa(300);
      color: $main-point;
      margin-left: 2px;
      margin-right: 4px;
    }

    .number-of-rveiws {
      a {
        all: unset;
      }

      @include f11-sa(300);
      color: $main_black;
      line-height: 16px;
      margin-left: 16px;
      position: absolute;
      right: 0;
    }
  }

  .buyNow-text {
    @include f12-sa(300);
    color: $main_green_deep;
    margin-top: 40px;
    margin-bottom: 24px;
  }

  .restocking-button-wrapper {
    display: none;
  }

  .kakaoGift-button-wrapper {
    display: none;
  }

  .purchaseMenu-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .purchaseMenu-button {
      width: 50%;
    }

    & .purchaseMenu-button:first-of-type {
      margin-right: 8px;
    }
  }
}

.purchaseMenu-featured-info {
  display: flex;
  flex-direction: column;
}

.purchaseMenu-draggable {
  position: relative;
  z-index: 2;
  margin-top: 48px;
}

.purchaseMenu-list {
  display: flex;
}

.backWordArrow {
  margin-left: 16px;
}

.cartIconWidthNumberMo {
  margin-right: 10px;
}

@media (min-width: $breakpoint-desktop-mid) {
  .mobile-active {
    max-height: calc(calc(var(--vh, 1vh) * 100) - 258px);
  }

  .mobile-button-wrapper {
    display: none;
  }
}

@media (min-width: 900px) {
  .mobile-active {
    max-height: none;
  }
}

@media (min-width: $breakpoint-desktop) {

  .deskTopOnly {
    display: block !important;
  }

  .mobileOnly {
    display: none !important;
  }
  .pathArea {
    margin-bottom: 32px;
    display: flex;
    align-items: center;

    .pathName {
      // font-family: NotoSansCJKkr;
      @include f13-sa(300);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: $main-brown;
      margin-right: 4px;
      cursor: pointer;
    }

    .pathArrow {
      width: 8px;
      height: 8px;
      margin-right: 4px;
    }
  }

  .thunder {
    &.mo {
      display: none;
    }

    &.dt {
      display: inline;
    }
  }


  .purchaseMenu-wrapper {
    padding: 0;
    // max-width: 1024px;
    // div를 하나 더씌우고 아래 것들은 그아래에 넣어야 한다.
    display: flex;
    // justify-content: center;
    flex-direction: column;
    // align-items: center;
    // min-height: 720px;
    position: relative;
    width: 400px;
    margin-top: 88px;
    left: 20%;
    max-height: none;
    overflow: visible;
    background-color: inherit;
    //transform: translate(-50%, -50%);


    &.fixed {
      position: sticky;
      left: 60%;
      top: 88px;
    }

    .purchaseMenu-mainItem {
      width: 40%;
    }

    .size-control-wrapper {
      padding: 0 48px;
      padding-top: 3%;
      width: 100%;
      max-width: $breakpoint-desktop;
    }

    .purchaseMenu-category {
      @include f18-sa(300);
      margin-bottom: 10vh;
    }

    .purchaseMenu-name {
      @include f14-sa(300);
      margin-bottom: 0;
    }

    .purchaseMenu-mainText {
      // @include font-sans(30px, 500);
      @include font-seif(30px, 200);
      line-height: 1.47;
      margin-bottom: 16px;
    }

    .purchaseMenu-priceAndReview {
      margin-bottom: 40px;

      .price-no-sale {
        @include f14-sa(300);
        color: $main_black;
      }

      .price-before {
        @include f14-sa(300);
      }

      .price-after,
      .discount-rate {
        @include f14-sa(300);
      }

      .number-of-rveiws {
        @include f13-sa(300);
        // position: relative;
        margin-left: 24px;
      }
    }

    .buyNow-text {
      @include f13-sa(300);
      color: $main_green_deep;
      margin-top: 24px;
      margin-bottom: 30px;
    }

    .purchaseMenu-buttons {
    }

    .restocking-button-wrapper {
      width: 100%;
      display: block;

      .temporarily-out-of-stock {
        opacity: 0.3;
        background-color: #604448;
        padding: 17px 31px;
        display: flex;
        align-content: center;
        justify-content: center;
      }

      .temporarily-out-of-stock-text {
        @include font-seif(15px, normal);
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }

      .restocking-button {
        background-color: #ff5316;
        border: solid 1px #000;
        padding: 17px 0;
        display: flex;
        justify-content: center;

        .alarm-img-wrapper {
          width: 16px;
          margin-right: 4px;
          align-items: center;
          display: flex;

          img {
            width: 100%;
          }
        }

        .alarm-text {
          @include font-seif(15px, normal);
          line-height: 1.47;
          color: #000;
        }
      }
    }

    .kakaoGift-button-wrapper {
      margin-top: 10px;
      width: 100%;
      display: block;

      .kakaoGift-button {
        background-color: #ffd902;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        border: solid 1px #000;
        height: 56px;
        align-content: center;
        cursor: pointer;

        .gift-icon {
          width: 24px;

          img {
            width: 100%
          }
        }

        .title-wrapper {
          display: flex;

          .text {
            @include font-seif(15px, normal);
            line-height: 1.47;
            color: #000;
          }

          .link-icon {
            width: 16px;
            margin-left: 2px;
            align-items: center;
            display: flex;

            img {
              width: 100%
            }
          }
        }
      }

      .kakaoGift-subText {
        text-align: center;
        @include font-sans(12px, normal);
        color: #ff5e26;
        line-height: 1.33;
        margin-top: 4px;
      }
    }
  }

  .purchaseMenu-draggable {
    margin-top: 10%;
  }

  .desktop-button-wrapper {
    width: 100%;
    display: flex;
    // margin-bottom: 24px;

    .desktop-button:first-of-type {
      margin-right: 8px;
    }

    .desktop-button {
      width: 50%;
    }
  }

  .mobile-button-wrapper {
    display: none;
  }

  @media (min-height: 900px) {
    .purchaseMenu-priceAndReview {
      // margin-bottom: 13%;
    }
    .purchaseMenu-draggable {
      margin-top: 11%;
    }
  }
}
