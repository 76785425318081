@import '/src/css/variable.scss';

.holiday-delivery-modal-wrapper {
  position: fixed;
  z-index: 999999;

  .dimmer {
    background-color: rgba(96, 68, 72, 0.6);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 60;
    top: 0;
    left: 0;
  }

  .holiday-delivery-modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 354px;
    z-index: 61;


    .holiday-delivery-modal-img {
      width: 100%;
      display: flex;
    }

    .holiday-delivery-confirm-button-wrapper {
      margin: auto;
      width: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
      //grid-template-columns: 1fr 1fr;
      height: 48px;


      .holiday-delivery-confirm-button {
        @include font-seif(14px, bold);
        cursor: pointer;
        background-color: #222222;
        color: #ffffff;
        justify-content: center;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
}

@media (min-width: $breakpoint-desktop-mid) {
  .holiday-delivery-modal-wrapper {
    .holiday-delivery-modal {
      width: 500px;


      .holiday-delivery-modal-img {
      }


      .holiday-delivery-confirm-button-wrapper {
        height: 48px;

        .holiday-delivery-confirm-button {
          @include font-seif(16px, bold);
        }
      }
    }
  }
}