@import '/src/css/variable.scss';

html {
  scroll-behavior: auto;
}

.dtBanner {
  display: none;
}


#NavigationTab {
  margin-bottom: 56px;
  transition: transform 500ms ease 0s;
  padding-top: 62px;
}

#navigationPosition {
  margin-bottom: 56px;
  display: none;
  color: transparent;
  opacity: 0;
  visibility: hidden;

  &.visible {
    display: block;
  }
}

.evnetBanner {
  margin-top: 48px;
  width: 100vw;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  text-align: center;
  overflow: hidden;
  position: relative;
  height: 72px;

  img {
    width: 1200px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

// #ch-plugin-launcher {
//   display: none !important;
// }
.menu-area {
  z-index: 9;
  display: none;
}

#related {
  overflow: hidden;
}

.product-name {
  @include f14-se;
  color: $main-black;
}

.widthSetter {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.product-page-banner-mo {
  cursor: pointer;
  width: 100%;
  height: 48px;
  background-color: $main-brown;
  color: $main_bg;
  @include flex_center;
  @include f13-sa(300);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  position: relative;

  .downloadIcon {
    position: absolute;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.product-page-banner-dt {
  display: none;
}

.upSellingPopUp {
  // transition: $transition_default;

  &.popup-bg {
    background-color: $dim_bg;
    display: flex;
    align-items: flex-end;

    .popup-box {
      position: relative;
      height: 440px;
      width: 100%;
      top: unset;
      left: unset;
      right: unset;
      // bottom: 0;

      background-color: $main_bg;
      transition: transform 500ms;
      // transition-delay: 1s;

      transform: translateY(100%);
      // overflow: hidden;
    }

    overflow: hidden;
  }

  .upSellButton {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  &.visible {
    opacity: 1;
    z-index: 40;

    &.popup-bg {
      .popup-box {
        transform: translateY(0);
      }
    }
  }

  &.invisible {
    opacity: 0;
    z-index: -100;
  }

  .upSellContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;

    .discountBadge {
      padding: 5px 12px;
      background-color: $main-point;
      @include f12-sa(500);
      color: $main-bg;
    }

    .upSellText1 {
      margin-top: 16px;
      @include f18-se;
      color: $main-black;
    }

    .upSellText2 {
      margin-top: 8px;
      @include f13-sa(300);
      color: $main-black;
      text-align: center;
    }

    .upSellImage {
      margin-top: 24px;
      width: 264px;
      height: auto;
    }

    .upSellClose {
      position: absolute;
      right: 16px;
    }

    .upSellClose.mo {
      display: inline;
      top: -46px;
    }

    .upSellClose.dt {
      display: none;
      top: 16px;
    }
  }
}

#NavigationTab {
  // position: sticky;
  z-index: 10;
  // top: 61.8px;
  width: 100vw;
  background-color: #fffff5;
}

#NavigationTab.fixed-mo {
  // position: sticky;
  // top: 56px;
  background-color: #fffff5;
  // opacity: 0.9;
  width: 100%;
  z-index: 9;
  left: 0;
  display: flex;
  justify-content: center;

  .navigation-wrapper {
    margin-top: 0;
    // padding-left: 0;
    // padding-right: 0;
    width: 100%;
  }
}

.cartIconWidthNumberDt,
.cartIconWidthNumberMo {
  &.hidden {
    display: none;
  }
}

.product-page-wrapper {
  // overflow-x: hidden;
  .navigation-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .content-area {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 100%;

    .widthSetter {
      width: 500px;
    }
  }

  .purchaseMenu-wrapper {
    padding-right: 48px;
    padding-left: 48px;
  }

  #NavigationTab {
    .widthSetter {
      width: 100%;
    }
  }

  .product-page-wrapper {
    .navigation-wrapper {
      padding-left: 48px;
      padding-right: 48px;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .dtBanner {
    display: block;
    // margin-top: 30px;
  }
  .evnetBannerUnerSlider {
    margin-top: 30px;
    width: 100%;
    // animation: fade-in 500ms;
    // animation-fill-mode: forwards;

    text-align: center;
    overflow: hidden;
    position: relative;
    padding-left: 64px;
    padding-right: 64px;
    height: 72px;

    img {
      width: 1200px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .eventImgContent {
      position: relative;
      height: 72px;

      div {
        background-position: center center !important;
        background-repeat: no-repeat !important;
        background-size: 1200px !important;
      }
    }
  }

  .product-page-banner-mo {
    display: none;
  }

  .product-page-banner-dt {
    display: block;
    cursor: pointer;
    width: 100%;
    height: 48px;
    background-color: $main-brown;
    color: $main_bg;
    @include flex_center;
    @include f13-sa(300);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    position: relative;
    margin-bottom: 32px;

    .downloadIcon {
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .product-page-wrapper {
    display: flex;

    .coupon-item-wrapper .circle {
      background-color: $main-bg;
    }

    &.scroll {
      height: 100vh;
      overflow: hidden;
      -ms-overflow-style: none;
      //scrollbar-width: 0px;
      ::-webkit-scrollbar {
        width: 16px;
      }

      ::-webkit-scrollbar-track {
        background-color: $product_back;
        //background: transparent;
      }
    }

    &.scroll:root {
      scrollbar-width: none !important;
    }

    &.scroll:hover {
      -ms-overflow-style: auto;

      ::-webkit-scrollbar {
        transition: $transition_default;
        display: unset;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
        border: 4px solid #fff;
      }
    }
  }
  .mobile-menu {
    display: none;
  }

  .mobile-button {
    position: fixed;
    bottom: 0;
    left: 0;
    display: none;
  }

  .content-area {
    width: 50%;

    &.scroll {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .menu-area {
    width: 50%;
    display: block;
    background-color: $product_back;
    // height: 100%;
    //min-height: 100vh;
    .menu-area-inner {
      padding-bottom: 100px;

      &.fixed {
        position: sticky;
        top: 130px;
      }

      .purchaseMenu-wrapper {
        z-index: 8;
        // transition: $transition_default;

        &.atTheBottom {
          transform: translate(-50%, 0);
          top: auto;
          // bottom: 380px;
          min-height: auto;
        }
      }
    }

    &.scroll {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .widthSetter {
    width: 100%;
  }

  .navigation-wrapper {
    margin-top: 0;
    padding-left: 64px;
    padding-right: 64px;
  }

  #NavigationTab {
    position: relative;
    // top: 86px;
    top: 86px;
  }

  #NavigationTab.fixed-dt {
    background-color: $main-bg;
    // opacity: 0.9;
    // width: 50%;
    padding-top: 84px;
    top: 0;
    left: 0;
    display: flex;
    z-index: 9;
    left: -16px;
    // justify-content: center;
    .widthSetter {
      width: 100%;
    }

    .navigation-wrapper {
      margin-top: 0;

      width: 100%;
    }
  }

  .upSellingPopUp {
    // transition: $transition_default;

    &.popup-bg {
      background-color: $dim_bg;
      display: flex;

      .popup-box {
        height: 606px;
        width: 460px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // transition: opacity 1000ms;
        background-color: $main_bg;
        transition: unset;
        opacity: 0;
      }
    }

    .upSellButton {
      margin-top: 40px;
      width: 332px;
      position: relative;
      bottom: 0;
    }

    &.visible {
      opacity: 1;
      z-index: 40;

      &.popup-bg {
        .popup-box {
          opacity: 1;
          transform: translate(-50%, -50%);
        }
      }
    }

    &.invisible {
      opacity: 0;
      z-index: -20;
    }

    .upSellContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 64px;

      .discountBadge {
        padding: 5px 14px;
        background-color: $main-point;
        @include f14-sa(500);
        color: $main-bg;
      }

      .upSellText1 {
        margin-top: 30px;
        @include f20-se;
        color: $main-black;
      }

      .upSellText2 {
        margin-top: 8px;
        @include f14-sa(300);
        color: $main-black;
        text-align: center;
      }

      .upSellImage {
        margin-top: 32px;
        width: 332px;
        height: auto;
      }

      .upSellClose.mo {
        display: none;
      }

      .upSellClose.dt {
        display: inline;
      }
    }
  }

  #NavigationTab {
    z-index: 8;
    max-width: 50vw;
  }
}
