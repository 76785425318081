@import '/src/css/variable.scss';

.navigation-wrapper {
  // margin-top: 56px;
  padding-left: 48px;
  padding-right: 48px;
  width: 100%;

  &.visible-dt {
    display: block;
    // padding-left: 0;
    // padding-right: 0
  }

  .control-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 16px;

    &.incensePage {
      margin-bottom: 0;
      .swiper-button-prev-content,
      .swiper-button-next-content {
        display: none;
      }

      // .bar {
      //   margin-top: -1px;
      // }
    }

    .swiper {
      // padding-left: 24px;
      padding-bottom: 1px;

      .swiper-slide {
        padding-left: 24px;
        // &:first-child {
        //   padding-left: 24px;
        // }

        &:last-child {
          padding-right: 24px;
        }
      }
    }

    .navigation-control {
      cursor: pointer;
      width: 300px;
      @include font-seif(14px, 300);
      line-height: normal;
      color: #c0a691;
      text-align: center;

      &.incenseTab {
        width: unset;
        &.active {
          padding-bottom: 15px;
          border-bottom: 2px solid #604448;
          margin-bottom: -2px;
        }
      }

      &.active {
        color: $main_black;
        @include font-seif(14px, 300);
      }
    }
  }

  .bar {
    width: 100%;
    overflow: visible;
    height: 1px;
    background-color: $main_line;
    position: relative;

    .bar-current {
      position: absolute;
      transition: all 0.5s;
      height: 2px;
      background-color: $main_black;
      // background-color: #ff5316;
      // width: 33.33333%;
    }
  }
}

@media (min-width: $breakpoint-desktop-mid) {
  .navigation-wrapper {
    .control-wrapper {
      .swiper {
        width: 100%;

        .swiper-wrapper {
          justify-content: space-around;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .navigation-wrapper {
    display: none;

    &.visible-dt {
      display: block;
      // padding-left: 64px;
      // padding-right: 64px;
      padding-left: 48px;
      padding-right: 48px;
    }

    .control-wrapper {
      &.incensePage {
        position: relative;

        .swiper {
          .swiper-slide {
            padding-left: 56px;

            &:last-child {
              padding-right: 56px;
            }
          }
        }
        .swiper-button-prev-content,
        .swiper-button-next-content {
          width: 32px;
          height: 64px;

          &.showHandle {
            display: block;
          }

          img {
            &:hover {
              cursor: pointer;
            }
          }
        }

        .swiper-button-prev-content {
          position: absolute;
          left: -30px;
          top: -15px;
        }

        .swiper-button-next-content {
          position: absolute;
          right: -30px;
          top: -15px;
        }
      }

      .swiper{
        width: 100%;

        .swiper-wrapper {
          justify-content: unset;
        }
      }

      .navigation-control {
        // cursor: pointer;
        @include font-seif(18px, 300);

        &.active {
          @include font-seif(18px, normal);
        }
      }
    }
  }
}
