@import '/src/css/variable.scss';

.npay-button {
  display: flex;
  justify-content: center;
}

.mobile-cartPopup-wrapper {
  position: absolute;
  bottom: 210px;
  width: 100%;
}

.mobile-cartPopup-restock-wrapper {
  position: absolute;
  bottom: 80px;
  width: 100%;

}

.cartPopup-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  max-height: 100vh;
  background-color: $dim_bg;

  display: flex;
  justify-content: center;
  align-items: center;

  .nPayPlace.dt {
    display: none;

    .npay_storebtn_bx {
      margin-top: 16px !important;
    }
  }

  .nPayPlace.mo {
    // margin-top: 16px;
    display: flex;
    justify-content: center;
    // display: none;

    &.show {
      display: flex;
    }

    .npay_storebtn_bx {
      margin-bottom: 32px !important;
    }
  }

  .cartPopup-box {
    #iamport-naverpay-product-button3 {
      display: none;
    }

    background-color: $main_bg;
    width: 100%;
    position: fixed;
    padding-top: 32px;
    padding-bottom: 12px;
    bottom: 0;

    .cartPopup-images {
      display: none;
    }

    .purchaseMenu-wrapper {
      width: 100%;
      margin-top: unset;
      padding: 24px 0 74px 0;

      .purchaseMenu-name {
        display: none;
      }

      .purchaseMenu-mainText {
        display: none;
      }

      .purchaseMenu-priceAndReview {
        display: none;
      }

      .optionArea,
      .upsell-wrapper {
        padding-right: 48px;
        padding-left: 48px;
      }

      .dropdown-wrapper {
        // margin-right: 48px;
        // margin-left: 48px;
      }


      .total-price {
        // position: fixed;
        // width: 100%;
        // bottom: 90px;
      }
    }

    .restocking-button-wrapper {
      width: 100%;
      display: block;
      padding: 0 12px;

      .temporarily-out-of-stock {
        opacity: 0.3;
        background-color: #604448;
        padding: 17px 31px;
        display: flex;
        align-content: center;
        justify-content: center;
      }

      .temporarily-out-of-stock-text {
        @include font-seif(15px, normal);
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }

      .restocking-button {
        background-color: #ff5316;
        border: solid 1px #000;
        padding: 17px 0;
        display: flex;
        justify-content: center;

        .alarm-img-wrapper {
          width: 16px;
          margin-right: 4px;
          align-items: center;
          display: flex;

          img {
            width: 100%;
          }
        }

        .alarm-text {
          @include font-seif(15px, normal);
          line-height: 1.47;
          color: #000;
        }
      }
    }

    .desktop-button-wrapper {
      //position: fixed;
      bottom: 0;
      width: 100%;
      display: block;
      background-color: $main_bg;
      padding: 12px;
      // padding: calc(constant(safe-area-inset-top) + 12px) calc(constant(safe-area-inset-right) + 12px) calc(constant(safe-area-inset-bottom) + 12px) calc(constant(safe-area-inset-left) + 12px);
      // padding: calc(env(safe-area-inset-top) + 12px) calc(env(safe-area-inset-right) + 12px) calc(env(safe-area-inset-bottom) + 12px) calc(env(safe-area-inset-left) + 12px);
      transition: all 400ms;

      .first-row-btns {
        display: flex;
      }

      &.bottomCta {
        margin-top: 20px;
        position: relative;

        .second-row-btns {
        }
      }

      .desktop-button {
        width: calc(50% - 4px);
        margin-right: 8px;
        display: inline-block;

        .cta-btn {
          height: 56px !important;
        }
      }

      .desktop-button:last-of-type {
        margin-right: unset;
      }
    }

    .closeIcon {
      position: absolute;
      right: 8px;
      top: -40px;

      .dt-close {
        display: none;
      }
    }
  }
}

@media (min-width: $breakpoint-desktop-home) {
  // 1200으로 되어 있는 것들 900으로 임시로 맞춰 주기 위해서 css를 900으로 옯겨 넣는다.
  .mobile-cartPopup-wrapper {
    position: relative;
    bottom: 0;
  }
  .mobile-cartPopup-restock-wrapper {
    position: relative;
    bottom: 0;
  }
  .cartPopup-bg {
    .cartPopup-box {
      .imageThumbnail {
        width: auto !important;
        height: auto !important;
        border-radius: 0 !important;
        background-color: #efebe2 !important;
        border: none !important;
        opacity: 1 !important;
      }

      .imageThumbnail.swiper-pagination-bullet-active {
        background-color: transparent !important;
        border: solid 1px $main_black !important;
      }

      .pagination-dt {
        display: block;
      }

      .imgSliderContent {
        background-size: 720px;
      }

      .purchaseMenu-wrapper {
        padding: 0;
        // max-width: 1024px;
        // div를 하나 더씌우고 아래 것들은 그아래에 넣어야 한다.
        display: flex;
        // justify-content: center;
        flex-direction: column;
        // align-items: center;
        // min-height: 720px;
        position: fixed;
        width: 400px;
        top: 50%;
        left: 75%;
        transform: translate(-50%, -50%);

        .purchaseMenu-mainItem {
          width: 40%;
        }

        .size-control-wrapper {
          padding: 0 48px;
          padding-top: 3%;
          width: 100%;
          max-width: $breakpoint-desktop;
        }

        .purchaseMenu-category {
          @include f18-sa(300);
          margin-bottom: 10vh;
        }

        .purchaseMenu-name {
          @include f14-sa(300);
        }

        .purchaseMenu-mainText {
          @include f36-se;
          margin-bottom: 24px;
        }

        .purchaseMenu-priceAndReview {
          // margin-bottom: 5.4%;
          .price-before {
            @include f14-sa(300);
          }

          .price-after,
          .discount-rate {
            @include f14-sa(300);
          }

          .number-of-rveiws {
            @include f13-sa(300);
            // position: relative;
            margin-left: 24px;
          }
        }

        .optionArea,
        .upsell-wrapper {
          padding-right: unset;
          padding-left: unset;
        }

        .buyNow-text {
          @include f13-sa(300);
          color: $main_green_deep;
          margin-top: 24px;
          margin-bottom: 30px;
        }
      }
      .restocking-button-wrapper {
        padding: 0;
      }

      .desktop-button-wrapper {
        width: 100%;
        display: flex;
        // margin-bottom: 24px;

        .desktop-button:first-of-type {
          margin-right: 8px;
        }

        .desktop-button {
          width: 50%;
        }
      }

      .purchaseMenu-draggable {
        margin-top: 10%;
      }


      .dropdown-wrapper {
        .dropdown-menu {
          max-height: unset;
          overflow-y: hidden;
          background-color: $product_back_rgb;
        }
      }
    }
  }

  .cartPopup-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: $dim_bg;

    display: flex;
    justify-content: center;
    align-items: center;

    .nPayPlace.dt {
      display: flex;
      justify-content: center;
      visibility: visible;
      // display: none;

      &.show {
        display: flex;
      }
    }

    .nPayPlace.mo {
      display: none;

      &.show {
        display: none;
      }
    }

    .cartPopup-box {
      background-color: $main_bg;
      width: 680px;
      // height: 680px;

      position: relative;
      padding: 64px 48px;

      .cartPopup-contents {
        display: flex;
      }

      .cartPopup-images {
        display: block;
        width: 240px;
        height: 240px;
        overflow: hidden;
        margin-right: 48px;
        position: relative;

        .imgSlider .swiper-container-horizontal > .swiper-pagination-bullets {
          margin-bottom: 16px;
        }
      }

      .imgSliderContent {
        background-size: cover;
        background-color: $main_line;
      }

      .purchaseMenu-wrapper {
        position: relative;
        transform: unset;
        top: unset;
        left: unset;
        width: 320px;
        // max-height: 680px;
        // overflow: scroll;
        padding: 0;

        .dropdown-wrapper .dropdown-menu {
          background-color: $main_bg;
          max-height: 300px;
          overflow-y: scroll;
        }

        .chosen-options-and-total .minHeight-scroll {
          max-height: 150px;
          overflow-y: scroll;
        }

        .chosen-options-and-total .selected-option-wrapper.mo {
          display: block;
        }

        .chosen-options-and-total .selected-option-wrapper.dt {
          display: none;
        }

        .chosen-options-and-total .option-padding {
          padding: 0;
        }

        .purchaseMenu-name {
          display: block;
          @include f14-sa(300);
        }

        .purchaseMenu-mainText {
          display: block;
          @include f20-se;
        }

        .purchaseMenu-priceAndReview {
          display: block;
          @include f14-sa(300);
        }

        .dropdown-wrapper {
          margin-right: 0;
          margin-left: 0;
        }


        .desktop-button-wrapper {
          position: relative;
          bottom: 0;
          width: 100%;
          display: flex;

          .desktop-button {
            width: 50%;
          }
        }

        .total-price {
          position: relative;
          width: unset;
          bottom: unset;
        }
      }

      .chosen-options-and-total .total-price.add-padding {
        padding: 16px 0;
      }

      .closeIcon {
        cursor: pointer;
        position: absolute;
        right: -30px;

        .dt-close {
          display: block;
        }

        .mo-close {
          display: none;
        }
      }
    }
  }

  // .cartPopup-bg.inSearch{
  //     position : fixed;
  //     top: 0;
  //     bottom: 0;
  //     left: 0;
  //     right: 0;
  //     z-index: 1000;
  //     background-color: $dim_bg;

  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     .nPayPlace.dt{
  //         display: block;
  //     }
  //     .nPayPlace.mo{
  //         display: none;
  //     }

  //     .cartPopup-box{
  //         background-color: $main_bg;
  //         width: 680px;
  //         // height: 680px;

  //         position: relative;
  //         padding: 64px 48px;
  //         .cartPopup-contents{
  //             display: flex;
  //         }

  //         .cartPopup-images{
  //             display: block;
  //             width: 240px;
  //             height: 240px;
  //             overflow: hidden;
  //             margin-right: 48px;

  //             .imgSlider .swiper-container-horizontal > .swiper-pagination-bullets{
  //                 margin-bottom: 16px;
  //             }
  //         }

  //         .imgSliderContent {
  //             background-size: cover;
  //             background-color: $main_line;
  //         }

  //         .purchaseMenu-wrapper{

  //             position: relative;
  //             transform: unset;
  //             top: unset;
  //             left: unset;
  //             width: 320px;
  //             // max-height: 680px;
  //             // overflow: scroll;
  //             padding: 0;
  //             .dropdown-wrapper .dropdown-menu {
  //                 background-color: $main_bg;
  //                 max-height: 300px;
  //                 overflow-y: scroll;

  //             }

  //             .chosen-options-and-total .minHeight-scroll {
  //                 max-height: 150px;
  //                 overflow-y: scroll;
  //             }

  //             .chosen-options-and-total .selected-option-wrapper.mo{
  //                 display: block;
  //             }

  //             .chosen-options-and-total .selected-option-wrapper.dt{
  //                 display: none;
  //             }
  //             .chosen-options-and-total .option-padding {
  //                 padding: 0;
  //             }

  //             .purchaseMenu-name{
  //                 display: block;
  //                 @include f14-sa(300);

  //             }
  //             .purchaseMenu-mainText{
  //                 display: block;
  //                 @include f20-se;

  //             }
  //             .purchaseMenu-priceAndReview{
  //                 display: block;
  //                 @include f14-sa(300);
  //             }
  //             .desktop-button-wrapper{

  //             }
  //             .dropdown-wrapper{
  //                 margin-right: 0;
  //                 margin-left: 0;
  //             }
  //             .desktop-button-wrapper{
  //                 position: relative;
  //                 bottom:  0;
  //                 width: 100%;
  //                 display: flex;
  //                 .desktop-button{
  //                     width: 50%;
  //                 }

  //             }

  //             .total-price{
  //                 position: relative;
  //                 width: unset;
  //                 bottom: unset;
  //             }
  //         }

  //         .chosen-options-and-total .total-price.add-padding{
  //             padding: 16px 0;
  //         }

  //         .closeIcon{
  //             cursor: pointer;
  //             position: absolute;
  //             right: 16px;
  //             top: 16px;
  //             .dt-close{
  //                 display: block;
  //             }
  //             .mo-close{
  //                 display: none;
  //             }

  //         }

  //     }
  // }
}
