@import '/src/css/variable.scss';

.detail-image-wrapper {


  width: 100%;
  padding-top: min(100%, 500px);
  position: relative;
  // height: 100%;

}

.detail-inner-position {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-height: 500px;
  min-height: inherit;

  .outOfStockArea {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    border-radius: 50%;
    background-color: rgba(96, 68, 72, 0.8);
    justify-content: center;
    align-items: center;
    z-index: 3;

    .outOfStock-text {
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fffff5;
      justify-content: center;
      align-items: center;
      display: flex;
      @include font-seif(17px, 500)
    }
  }
}


@media (min-width: $breakpoint-desktop) {

  .detail-image-wrapper {

    min-height: 900px;
    width: 100%;
    padding-top: min(100%, 500px);
    position: relative;
    // height: 100%;
  }

  .detail-inner-position {
    min-height: 900px;
    max-height: inherit;

    .outOfStockArea {
      width: 150px;
      height: 150px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      border-radius: 50%;
      background-color: rgba(96, 68, 72, 0.8);
      justify-content: center;
      align-items: center;
      z-index: 3;

      .outOfStock-text {
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fffff5;
        justify-content: center;
        align-items: center;
        display: flex;
        @include font-seif(27px, 500)
      }
    }
  }


}


