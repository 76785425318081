@import '/src/css/variable.scss';

a {
  all: unset;
}

.mobilefloatingbutton-container {
  // padding: constant(safe-area-inset-top) constant(safe-area-inset-right)
  //   constant(safe-area-inset-bottom) constant(safe-area-inset-left);
  // padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
  //   env(safe-area-inset-left);

  position: fixed;
  bottom:0;
  left: 0;
  transition: padding 400ms;

  z-index: 40;
  width: 100%;
  background-color: $main_bg;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  -webkit-overflow-scrolling: touch;

  .npay-button-cart {
    padding-top: 16px;
    display: flex;
    justify-content: center;

    &.hidden {
      display: none;
    }
  }

  &.visible {
    display: flex;
  }

  // position: relative;
  &.fullheight {
    height: 100%;
  }

  .dimarea {
    height: 100vh;
    width: 100vw;
    z-index: -1;
    background-color: $dim_bg;
    position: fixed;
  }

  .chosen-options-and-total.simple {
    padding-top: 30px;
  }

  .chosen-options-and-total .selected-option-wrapper {
  }

  .restocking-button-wrapper {
    width: 100%;
    padding: 12px;
    z-index: 10;
    border-top: 1px solid #efebe2;

    .temporarily-out-of-stock {
      opacity: 0.3;
      background-color: #604448;
      padding: 17px 31px;
      display: flex;
      align-content: center;
      justify-content: center;
    }

    .temporarily-out-of-stock-text {
      @include font-seif(15px, normal);
      font-stretch: normal;
      font-style: normal;
      line-height: 1.47;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }

    .restocking-button {
      background-color: #ff5316;
      border: solid 1px #000;
      padding: 17px 0;
      display: flex;
      justify-content: center;

      .alarm-img-wrapper {
        width: 16px;
        margin-right: 4px;
        align-items: center;
        display: flex;

        img {
          width: 100%;
        }
      }

      .alarm-text {
        @include font-seif(15px, normal);
        line-height: 1.47;
        color: #000;
      }
    }
  }

  .kakaoGift-button-wrapper {
    width: 100%;
    display: block;
    z-index: 10;
    padding: 12px 14px;

    .kakaoGift-button {
      background-color: #ffd902;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      border: solid 1px #000;
      height: 56px;
      align-content: center;
      cursor: pointer;

      .gift-icon {
        width: 24px;

        img {
          width: 100%
        }
      }

      .title-wrapper {
        display: flex;

        .text {
          @include font-seif(15px, normal);
          line-height: 1.47;
          color: #000;
        }

        .link-icon {
          width: 16px;
          margin-left: 2px;
          align-items: center;
          display: flex;

          img {
            width: 100%
          }
        }
      }
    }

    .kakaoGift-subText {
      text-align: center;
      @include font-sans(12px, normal);
      color: #ff5e26;
      line-height: 1.33;
      margin-top: 4px;
    }
  }

  .mobilefloatingbutton-wrapper {
    width: 100%;
    display: flex;
    z-index: 10;
    // height: 80px;
    // max-height: 80px;
    padding: 12px;
    justify-content: space-between;
    background-color: $main_bg;
    border-top: solid 1px $main_line;


    &.no-border {
      border: none;
      // position: fixed;
      // bottom: 0;
    }

    .mobilefloatingbutton-button {
      width: calc(50% - 4px);
      // width: 100%;
      @include font-seif(15px, normal);
      line-height: 1.47;
      color: #000;
      text-align: center;

      margin-right: 8px;

      &.setWidth {
        width: 100%;
      }

      &:last-child {
        margin-right: unset;
      }

      .mobileFloating {
        height: 56px;
      }

      img {
        width: 112px;
      }
    }

    &.hidden {
      display: none;
    }
  }

  .menu-info {
    background-color: $main_bg;
    // max-height: calc(100vh - 100px);
    // position: relative;
    // padding-top: 24px;
    // padding-left: 48px;
    // padding-right: 48px;
    // min-height: 240px;
    // 높이 조절 문제,
    // 드롭다운이 펼쳐지면, mi

    .option-padding {
      padding: 0 48px;
      // max-height: 55vh;
      // overflow: scroll;

      .option-description {
        margin-top: 24px;
        margin-bottom: 24px;
        @include f13-sa(300);
        color: $main_brown;
      }
    }
  }

  .contentWrapper {
    max-height: calc(100vh - 80px);
    // position: fixed;
    // bottom: 80px;
    // width: 100vw;
    // user-select: unset !important;
    // overflow-y: hidden;
  }
}
