@import '/src/css/variable.scss';

.bubblePosition {
  margin-top: 12px;
}

.optionAndTotalBubbleTarget {
  @include f13-sa(400);
  color: $main_brown;
  display: flex;
  align-items: center;

  img {
    // 모바일 수평을 위하여..
    transform: translateY(1px);
    width: 14px;
    height: 14px;
    margin-left: 4px;
  }
}

.optionAndTotalBubble {
  @include f12-sa(300);
  color: $main_brown;

  a {
    cursor: pointer;
    margin-top: 12px;
    display: block;
    color: $main_black;
    text-decoration: underline;
  }
}

.chosen-options-and-total {
  // padding-top: 32px;
  margin-bottom: 0;

  &.simple {
    padding-top: 16px;
  }

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  .minHeight-scroll {
    max-height: none;
    overflow-y: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    // margin-bottom: 32px;
  }

  // crome
  .minHeight-scroll::-webkit-scrollbar {
    display: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .quantityInput {
    // all: unset;
    text-align: center;
    width: min-content;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: -internal-light-dark(black, white);
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    appearance: unset;
    background-color: unset;
    -webkit-rtl-ordering: unset;
    cursor: text;
    margin: 0em;
    font: unset;
    padding: unset;
    border-width: unset;
    border-style: unset;
    border-color: unset;
    border-image: unset;
  }

  .quantityInput:hover {
    // all: unset;
    text-align: center;
    width: min-content;
    outline: none;
    border-bottom: none;
  }

  .quantityInput:focus {
    text-align: center;
    width: min-content;
    outline: none;
    border-bottom: none;
  }

  #iamport-naverpay-product-button3 {
    &.hidden {
      display: none;
    }

    margin-top: 24px;
  }

  .selected-option-wrapper:first-of-type {
    margin-top: 16px;
  }

  .selected-option-wrapper:last-of-type {
    margin-bottom: 0px;
  }

  .selected-option-wrapper.simple:first-of-type {
    margin-top: 0px;

    .selected-option-bottom {
      margin-top: 16px;
    }
  }

  .selected-option-wrapper.simple.floating {
    margin-bottom: 0px;
  }

  // .simple{

  // }

  .selected-option-wrapper {
    margin-bottom: 19px;

    // &.dt{
    //     display: none;
    // }
    .selecte-option-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      .selecte-option-img-and-name {
        display: flex;
        align-items: center;

        .option-img {
          width: 16px;
          height: 16px;
          object-fit: contain;
          margin-right: 8px;
        }

        .option-name {
          @include font-sans(13px, normal);
          line-height: 1.38;
          // @include f13-sa(normal);
          color: $main_brown;
        }
      }
    }

    .selected-option-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .quantity-control {
        @include f13-sa(300);
        color: $main_black;
        display: flex;
        align-items: center;
        justify-content: center;

        .minus {
          color: $main_brown;
          font-size: 29px;
          font-weight: 100;
          margin-right: 2px;
          cursor: pointer;
        }

        .plus {
          color: $main_brown;
          font-size: 25px;
          font-weight: 100;
          margin-left: 2px;
          cursor: pointer;
        }
      }

      .price-info {
        .regular-price {
          @include f13-sa(300);
          color: $main_black;
          margin-right: 2px;
        }

        .crosslined {
          text-decoration: line-through;
          color: $main_brown;
        }

        .price {
          @include f13-sa(300);
          color: $main-point;
          margin-right: 8px;
        }

        .discount-rate {
          @include f13-sa(300);
          color: $main-point;
        }
      }
    }
  }

  .option-padding {
    padding: 0 48px;
  }

  .total-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include f13-sa(300);
    line-height: 1.38;
    color: $main_black;
    border-top: 1px solid $main_line;
    padding-top: 16px;
    padding-bottom: 16px;
    &.mobile-position {
      position: absolute;

    }

    &.add-padding-margin {
      margin-top: 32px;
      padding-right: 48px;
      padding-left: 48px;
    }
  }

  .salePriceText {
    color: #ff5316;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include f13-sa(300);
    line-height: 1.38;
    padding-top: 5px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .chosen-options-and-total {
    // padding-top: 32px;
    margin-bottom: 32px;

    &.simple {
      padding-top: 24px;
    }

    .minHeight-scroll {
      max-height: 30vh;
      overflow: auto;
    }

    .selected-option-wrapper {
      margin-bottom: 26px;

      // display: flex;
      // align-items: center;
      &.dt {
        display: block;
      }

      // &.mo{
      //     display: none;
      // }
      .close-btn {
        margin-left: 16px;
      }

      .selecte-option-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        .selecte-option-img-and-name {
          display: flex;
          align-items: center;

          .option-img {
            margin-right: 8px;
          }

          .option-name {
            @include f13-sa(400);
            color: $main_brown;
            // width: 100px;
          }
        }
      }

      .quantity-control {
        @include f13-sa(300);
        color: $main_black;
        display: flex;
        align-items: center;
        justify-content: center;

        .minus {
          color: $main_brown;
          font-size: 29px;
          font-weight: 100;
          margin-right: 15px;
          cursor: pointer;
        }

        .plus {
          color: $main_brown;
          font-size: 25px;
          font-weight: 100;
          margin-left: 15px;
          cursor: pointer;
        }
      }

      .price-info {
        text-align: right;
        margin-right: 8px;

        .regular-price {
          @include f13-sa(300);
          color: $main_black;

          margin-right: 2px;
        }

        .crosslined {
          text-decoration: line-through;
          color: $main_brown;
        }

        .price {
          @include f13-sa(300);
          color: $main-point;
          margin-right: 8px;
        }

        .discount-rate {
          @include f13-sa(300);
          color: $main-point;
        }
      }
    }

    .option-padding {
      padding: 0 48px;
    }

    .total-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include f13-sa(300);
      color: $main_black;
      border-top: 1px solid $main_line;
      padding-top: 16px;

      &.mobile-position {
        position: relative;
      }

      &.add-padding-margin {
        padding-right: 0px;
        padding-left: 0px;
      }
    }
  }

  .optionAndTotalBubbleTarget {
    @include f13-sa(400);
    color: $main_brown;
    display: flex;
    align-items: center;

    img {
      width: 14px;
      height: 14px;
      margin-left: 4px;
    }
  }

}

@media (min-width: $breakpoint-desktop-home) {
  .cartPopup-box {
    .chosen-options-and-total {
      .total-price {

        &.mobile-position {
          position: relative;
        }

        &.add-padding-margin {
          padding-right: 0px !important;
          padding-left: 0px !important;
        }
      }
    }
  }
}
