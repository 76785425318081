@import '/src/css/variable.scss';

.collectionSelectorWrapper {
  // position: relative;
  user-select: none;
  cursor: pointer;

  .curCollection {
    @include f16-se;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $main-black;
    display: flex;
    align-items: center;

    img {
      margin-left: 4px;
    }
  }

  .collectionSelectWrapper {
    position: absolute;
    // top : 100%;
    background-color: $main-bg;
    padding-top: 16px;
    width: 100vw;
    left: 0;
    text-align: center;
    transition: all 300ms ease-out;
    // transition: border 300ms ease-in 300ms;
    opacity: 1;
    z-index: 9;
    max-height: 318px;
    height: auto;
    // overflow: hidden;
    overflow-y: auto;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);

    &.sub {
      display: flex;
      // max-height: calc(100vh - 72px - 140px);
      // max-height: 440px;
    }

    &.opened {
      .menusSelectArea,
      .subSelectArea {
        .collectionSelect {
          justify-content: flex-start;
        }
      }
    }

    .menusSelectArea {
      border-top: 1px solid $main_line;
      width: 100%;
      overflow: auto;
    }

    .subSelectArea {
      border-top: 1px solid $main_line;
      border-left: 1px solid $main_line;
      width: 100%;
      overflow: auto;
    }

    .menusSelectArea,
    .subSelectArea {
      .collectionSelect {
        display: flex !important;
        // justify-content: end !important;
        padding-left: 16px !important;

        &.sub {
          @include font-sans(14px, normal);
          line-height: 1.43;
        }
      }
    }

    .collectionSelect {
      height: 40px;
      @include flex-center;
      @include f14-se;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: $main_brown;

      &.focus {
        color: $main_black;
        background-color: $main_line;

        &.sub {
          background-color: unset;
        }
      }
    }

    &.hidden {
      max-height: 0;
      overflow: hidden;
      // opacity: 0;
      visibility: hidden;
      // border: 0px;
      // z-index: -1;
      // transition: all 300ms;
      // display: none;
    }
  }
}

.selectorOnSticky {
  .collectionSelectorWrapper {
    // position: relative;
    user-select: none;
    cursor: pointer;

    .curCollection {
      @include f13-sa(300);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $main-black;
      display: flex;
      align-items: center;

      img {
        margin-left: 2px;
      }

      .stickyHeader {
        .paths {
          display: flex;
          align-items: center;

          .path {
            margin-right: 4px;
          }

          .arrow {
            margin-right: 4px;
            width: 10px;
            height: 10px;
            background-size: contain;
            background-image: url('../../../img/icon/arrow_10.png');
          }
        }
      }
    }

    .collectionSelectWrapper {
      position: absolute;
      // top : 100%;
      background-color: $main-bg;
      padding-top: 16px;
      width: 100vw;
      left: 0;
      text-align: center;
      // transition: all 300ms;
      // opacity: 1;
      z-index: 7;
      transition: all 300ms linear;
      opacity: 1;
      z-index: 9;
      // max-height: 200px;
      height: auto;
      // overflow: hidden;

      .collectionSelect {
        height: 40px;
        @include flex-center;
        @include f14-se;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: $main_brown;

        &.focus {
          color: $main_black;
          background-color: $main_line;

          &.sub {
            background-color: unset;
          }
        }
      }

      &.hidden {
        max-height: 0;
        overflow: hidden;
        // opacity: 0;
        visibility: hidden;
        // opacity: 0;
        // transition: all 300ms;
        // display: none;
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .collectionSelectorWrapper {
    // position: relative;
    user-select: none;
    cursor: pointer;

    .curCollection {
      @include f36-se;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $main-black;
      display: flex;
      align-items: center;

      img {
        margin-left: 6px;
        width: 28px;
        height: 28px;
      }
    }

    .collectionSelectWrapper {
      position: absolute;
      // top : 100%;
      background-color: $main-bg;
      margin-top: 16px;
      padding-top: 0px;
      width: 410px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      // transition: all 300ms;
      // opacity: 1;
      z-index: 9;
      border: solid 1px $main-black;

      .collectionSelect {
        height: 40px;
        @include flex-center;
        @include f18-se;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
        color: $main_brown;

        &.focus {
          color: $main_black;
          background-color: $main_line;
        }
      }

      &.hidden {
        // opacity: 0;
        // transition: all 300ms;
        // display: none;
      }
    }
  }

  .selectorOnSticky {
    .collectionSelectorWrapper {
      position: relative;
      user-select: none;
      cursor: pointer;

      .curCollection {
        @include f13-sa(300);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $main-black;
        display: flex;
        align-items: center;

        img {
          margin-left: 8px;
          width: 18px;
          height: 18px;
        }
      }

      .collectionSelectWrapper {
        position: absolute;
        top: 100%;
        background-color: $main-bg;
        padding-top: 0px;
        width: 310px;
        left: 0;
        transform: unset;
        // left: 205px;
        text-align: center;
        // transition: all 300ms;
        // opacity: 1;
        z-index: 7;
        transition: all 300ms linear;
        // transition: border 300ms ease-in 300ms;
        opacity: 1;
        z-index: 9;
        // max-height: 200px;
        height: auto;
        // overflow: hidden;

        &.sub {
          // width:600px;
          // height: 224px;

          &.expand {
            width: 600px;
          }
        }

        .menusSelectArea,
        .subSelectArea {
          .collectionSelect {
            display: flex !important;
            // justify-content: end !important;
            padding-left: 24px !important;
          }
        }

        .collectionSelect {
          height: 40px;
          @include flex-center;
          @include f14-se;
          line-height: 1.43;
          color: $main_brown;

          &.focus {
            color: $main_black;
            background-color: $main_line;

            &.sub {
              background-color: unset;
            }
          }
        }

        &.hidden {
          // opacity: 0;
          // transition: all 300ms;
          // display: none;
          max-height: 0;
          overflow: hidden;
          // opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}
