@import '/src/css/variable.scss';


.modal-page-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  background-color: $dim_bg;
}

.restocking-modal-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #fffff5;
  max-width: none;
  max-height: 900px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1002;
  padding: 20px;

  .restocking-modal-close-button {
    @include font-sans(20px, bold);
    float: right;
    cursor: pointer;

  }

  .restocking-modal-body {
    padding: 20px;


    .restocking-modal-header {
      @include font-sans(20px, bold);
      text-align: center;
      padding-top: 100px;
      margin-bottom: 60px;
    }

    input {
      text-align: center;
    }

    input::placeholder {
      text-align: center;
    }

    input:focus::placeholder {
      color: transparent;
    }


    .restocking-modal-button {
      margin-top: 70px;
      width: 100%;
      cursor: pointer;
      height: 56px;
      background-color: #C0A791;
      color: #FFFFF5;
      padding: 17px 0;
      border: 0;
      display: flex;
      justify-content: center;
      @include font-sans(15px, bold);
    }

    .active {
      background-color: #ff5316;
      color: #000000;
      border: solid 1px #000;
    }
  }
}


@media (min-width: $breakpoint-desktop-mid) {
  .restocking-modal-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #fffff5;
    max-width: 400px;
    max-height: 700px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1002;
    padding: 20px;
  }
}