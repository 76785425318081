@import '/src/css/variable.scss';


.collins-home-renewal-wrapper {
  position: relative;


  .collins-home-position-wrapper {
    position: relative;
    height: 100vh;
  }
}

@media (min-width: $breakpoint-desktop-mid) {
  .collins-home-renewal-wrapper {
    position: relative;

  }
}


@media (min-width: $breakpoint-desktop) {
  .collins-home-renewal-wrapper {
    position: relative;
  }
}
