@import '/src/css/variable.scss';

.sectionNavigation {
  // position: sticky;
  // top: 0;
}

.slider-wrapper {
  overflow: hidden;

}

// #ch-plugin-launcher{
//     z-index: ;
// }

// body {
//     min-height: 100vh;
//     /* mobile viewport bug fix */
//     min-height: -webkit-fill-available;
//   }

//   html {
//     height: -webkit-fill-available;
//   }
.dt {
  display: none;
}

.commingSoonWrapper {
  background-color: #eee0cd;
}

.tmpCommingSoon {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-top: 88px;
  padding-bottom: 80px;

  .content1 {
  }
}

.moonIcon {
  width: 32px;
}

.commingSoonTextTypeText {
  margin-top: 36px;
  @include f13-sa(400);
  color: #111111;
}

.commingSoonTextdesc {
  @include f13-se;
  margin-top: 32px;
  line-height: 1.73;
  color: #111111;
}

.commingSoonText {
  font-family: $BN_cringe_sans;
  font-weight: 900;
  font-size: 36px;
  margin-top: 13px;
  line-height: 1.13;
  color: #111111;
}

.goodNightImage {
  margin-top: 40px;
  width: 100%;
}

#homePage {
  overflow: hidden;

  .swiper-pagination {
    text-align: center !important;
  }

  .hamburger {
    width: 32px;
    height: 32px;
  }

  .mainHeaderContainer .mainHeader {
    .leftEl {
      animation-fill-mode: none;
    }

    .centerEl {
      animation-fill-mode: none;
    }

    .rightEl {
      .incenseBtn {
        display: none;
      }

      animation-fill-mode: none;
    }
  }

  .header_icon_dark {
    svg {
      fill: #604448;
    }
  }

  .header-logo {
    @include transition('all 0.5s linear');

    &.transparent {
      opacity: 0;

    }
  }

  .rightEl {
    .searchIcon {
      display: none;
    }
  }
}

@media (min-width: $breakpoint-desktop-mid) {
  .commingSoonWrapper {
    .content1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .moonIcon {
    width: 52px;
  }
  .tmpCommingSoon {
    padding-right: 40px;
    padding-top: 77px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .commingSoonTextTypeText {
    margin-top: 56px;
    text-align: center;
    @include f18-sa(400);
  }
  .commingSoonTextdesc {
    @include f15-se;
    text-align: center;
    margin-top: 48px;
  }
  .commingSoonText {
    font-family: $BN_cringe_sans;
    font-weight: 900;
    font-size: 60px;
    line-height: 1;
    margin-top: 16px;
  }

  .goodNightImage {
    margin-top: 40px;
    max-width: 540px;
  }

  // 헤더 조작
  #homePage {
    .mo {
      display: none;
    }

    .dt {
      display: inherit;
      visibility: visible;
    }

    .hamburger {
      width: 40px;
      height: 40px;
    }

    .mainHeaderContainer {
      position: fixed;
      width: 100%;
      top: 0;
      z-index: 9;

      .mainHeader {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding-top: 40px;
        padding: 15px 0;

        .leftEl {
          padding-left: 48px;
        }

        .rightEl {
          padding-right: 48px;
          height: 40px;

          .searchIcon {
            display: block;
            margin-right: 8px;
            cursor: pointer;
            width: 40px;
            height: 40px;
          }
        }

        &.bg-color {
          background-color: unset;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .commingSoonWrapper {
    padding-left: 96px;
    padding-right: 96px;
  }

  #homePage {
    .mainHeaderContainer {
      .mainHeader {
        padding-top: 24px;
      }
    }
  }

  .tmpCommingSoon {
    flex-direction: row;
    padding: 180px 0;
    padding-right: 0;
    // justify-content: space-between;
    .content1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-right: 96px;
      width: 504px;
    }

    .content2 {
      width: 48%;
      display: flex;
      justify-content: flex-start;
    }
  }

  .commingSoonTextTypeText {
    margin-top: 72px;
    text-align: left;
    @include f18-sa(400);
  }
  .commingSoonTextdesc {
    @include f15-se;
    margin-top: 64px;
    text-align: left;
  }
  .commingSoonText {
    font-family: $BN_cringe_sans;
    font-weight: 900;
    text-align: left;
    font-size: 60px;
    margin-top: 25px;
  }

  .goodNightImage {
    margin-top: 40px;

    max-width: 600px;
  }

  .foooterClassForHome {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  .wrapperClassForHome {
    // margin-bottom: 600px;
    // background-color: $main_bg;
    // overflow: hidden;
  }

  .sectionNavOnHeader {
    width: 100%;

    .positionSetter {
      // width: 100vw;
      max-width: 1200px;
      display: flex;

      .sectionNavTextSticky {
        cursor: pointer;
        margin-left: 40px;
      }
    }
  }
  .dt {
    display: inherit;
  }
}
