@import '/src/css/variable.scss';

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.mo {
  display: inherit;
}

.flex {
  display: flex;
}

.dt {
  display: none;
}

#topBanner {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.centerEl {
  color: $main_black;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  animation: fade-in 500ms;
  animation-fill-mode: forwards;
}

.mainHeaderContainer {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 39;
  animation: fade-in 500ms;
  animation-fill-mode: forwards;

  .mainHeader {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    pointer-events: none;

    .leftEl {
      padding-left: 16px;
      display: flex;
      align-items: center;
      animation: fade-in 500ms;
      animation-fill-mode: forwards;
      pointer-events: all;
    }

    .rightEl {
      .searchIcon {
        cursor: pointer;
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }

      .incenseBtn {
        margin-right: 7px;
        padding: 5px 10px 4px 10px;
        background-color: #ff5316;
        color: #ffffff;
        border-radius: 14px;
        @include font-sans(11px, normal);
        line-height: normal;

        &:hover {
          cursor: pointer;
        }
      }

      padding-right: 16px;
      display: inline-block;
      display: flex;
      align-items: center;
      animation: fade-in 500ms;
      animation-fill-mode: forwards;
      pointer-events: all;
    }

    &.bg-color {
      background-color: $main_bg;
    }

    &.bg-color-black {
      background-color: black;
    }

    &.bg-gradient {
      background-image: linear-gradient(to bottom, rgba(255, 81, 20, 0.7), rgba(255, 79, 17, 0));
    }
  }

  .searchBar {
    // height: 500px;
    background-color: $main_bg;
    position: fixed;
    top: 0;
    // bottom: 0;
    // top:100%;
    z-index: 11;
    width: 100%;
    // transition: opacity 250ms;
    opacity: 1;
    max-height: 0;
    overflow: hidden;

    &.hidden {
      // visibility: hidden;
      // opacity: 0;
      // display: none;
      // max-height: unset;
      overflow: hidden;
      animation: fade-out 500ms;
      animation-fill-mode: forwards;
      // z-index: -9999999999;
    }

    &.visible {
      animation: fade-in 500ms;
      animation-fill-mode: forwards;
      max-height: unset;
    }
  }

  .searchResults {
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    padding-bottom: 160px;

    background-color: $main_bg;
    position: relative;
    z-index: 9;

    &.hidden {
      display: none;
    }

    &.visible {
      // animation: fade-out 1s;
      animation-fill-mode: forwards;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .mo {
    display: none;
  }
  .dt {
    display: inherit;
  }
  .mainHeaderContainer {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9;

    .mainHeader {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 40px;
      z-index: 10;

      .leftEl {
        padding-left: 48px;
      }

      .rightEl {
        padding-right: 48px;
        height: 40px;

        .searchIcon {
          display: block;
          margin-right: 16px;
          cursor: pointer;
          width: 40px;
          height: 40px;
        }

        .incenseBtn {
          margin-right: 32px;
          padding: 5px 12px 4px 12px;
          border-radius: 20px;
          @include font-sans(14px, normal);
          line-height: 1.43;
        }
      }

      &.bg-color {
        background-color: unset;
      }

      &.bg-color-black {
        background-color: unset;
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    //   max-height: 100px;
    max-height: unset;
  }
  to {
    opacity: 0;
    //   display: none;
    // max-height: 0;
    max-height: 0;
    display: none;
  }
}
