@import '/src/css/variable.scss';

.green-cta {
  background-color: $main_button;
  border: none;
}

.black-cta {
  background-color: $main_black;
  border: none;
}

.brown-cta {
  background-color: $main_etc;
  border: 1px solid;
  color: #000000;
}

.shadow-cta {
  background-color: $main_line;
  border: none;
}

.disable-cta {
  background-color: #60444833;
  border: none;
  color: #FFFFFF;
}

.orange-cta {
  color: #000000;
  background-color: $main_point;
  border: 1px solid;
}

.real-black-cta {
  background-color: #000;
  border: none;
}

.darkBrown-cta {
  background-color: $main_brown;
  color: $main_bg;
  @include f12-sa(300);
  border: none;
}

.incenseVerOrange {
  border: 1px solid #000 !important;
  background-color: #ff5316 !important;
  color: #000000 !important;
}
