@import '/src/css/variable.scss';

.kakaoGift-modal-modal-wrapper {
  background-color: rgba(96, 68, 72, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;

  .kakaoGift-modal-button-wrapper {
    padding: 32px 24px;
    background-color: #fffff5;
    display: block;
    margin: auto;
    align-items: center;
    position: relative;

    .close-button {
      width: 16px;
      position: absolute;
      right: 0;
      top: -25px;
      cursor: pointer;

      .img {
        width: 100%
      }
    }

    .kakaoGift-modal-header {
      @include font-seif(16px, 500);
      line-height: 1.5;
      letter-spacing: normal;
      color: #4f262c;
      margin-bottom: 8px;
      text-align: center;
    }

    .kakaoGift-modal-description {
      @include font-sans(12px, normal);
      color: #815841;
      opacity: 0.82;
      line-height: normal;
      margin-bottom: 24px;
      text-align: center;
    }

    .kakaoGift-button-wrapper {
      width: 100%;
      display: block;
      padding: 0 8px;

      .kakaoGift-button {
        background-color: #ffd902;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        border: solid 1px #000;
        height: 56px;
        align-content: center;
        cursor: pointer;
        min-width: 0;

        .gift-icon {
          width: 24px;

          img {
            width: 100%
          }
        }

        .title-wrapper {
          display: flex;

          .text {
            @include font-seif(15px, normal);
            line-height: 1.47;
            color: #000;
          }

          .link-icon {
            width: 16px;
            margin-left: 2px;
            align-items: center;
            display: flex;

            img {
              width: 100%
            }
          }
        }
      }

      .kakaoGift-subText {
        text-align: center;
        @include font-sans(12px, normal);
        color: #ff5e26;
        line-height: 1.33;
        margin-top: 4px;
      }
    }

  }

}

@media (min-width: $breakpoint-desktop) {
  .kakaoGift-modal-modal-wrapper {
    .kakaoGift-modal-button-wrapper {
      padding: 40px 48px;
      background-color: #fffff5;
      display: block;
      margin: auto;
      align-items: center;
      position: relative;

      .close-button {
        width: 16px;
        position: absolute;
        right: 0;
        top: -25px;
        cursor: pointer;

        .img {
          width: 100%
        }
      }

      .kakaoGift-modal-header {
        @include font-seif(16px, 500);
        line-height: 1.5;
        letter-spacing: normal;
        color: #4f262c;
        margin-bottom: 8px;
        text-align: center;
      }

      .kakaoGift-modal-description {
        @include font-sans(12px, normal);
        color: #815841;
        opacity: 0.82;
        margin-bottom: 24px;
        text-align: center;
      }

      .kakaoGift-button-wrapper {
        width: 100%;
        display: block;
        padding: 0;

        .kakaoGift-button {
          background-color: #ffd902;
          padding: 16px;
          display: flex;
          justify-content: space-between;
          border: solid 1px #000;
          height: 56px;
          align-content: center;
          cursor: pointer;
          min-width: 364px;

          .gift-icon {
            width: 24px;

            img {
              width: 100%
            }
          }

          .title-wrapper {
            display: flex;

            .text {
              @include font-seif(15px, normal);
              line-height: 1.47;
              color: #000;
            }

            .link-icon {
              width: 16px;
              margin-left: 2px;
              align-items: center;
              display: flex;

              img {
                width: 100%
              }
            }
          }
        }

        .kakaoGift-subText {
          text-align: center;
          @include font-sans(12px, normal);
          color: #ff5e26;
          line-height: 1.33;
          margin-top: 4px;
        }
      }

    }

  }
}
