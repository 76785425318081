@import "variable";

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
  --satc: env(safe-area-inset-top);
  --sarc: env(safe-area-inset-right);
  --sabc: env(safe-area-inset-bottom);
  --salc: env(safe-area-inset-left);
}

.deskTopOnly {
  display: none;
}

.mobileOnly {
  display: block;
}

@font-face {
  font-family: BNCringeSans;
  src: url("../fonts/BN_Cringe_Sans/bncringesans-regular-webfont.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: BNCringeSans;
  src: url("../fonts/BN_Cringe_Sans/bncringesans-bold-webfont.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: AntonRegular;
  src: url("../fonts/Anton/Anton-Regular.ttf.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: BergenSansSemi;
  src: url("../fonts/BergenSans/BergenSans-SemiBold.otf") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: BNCringeSans;
  src: url("../fonts/BN_Cringe_Sans/bncringesans-bold-webfont.woff") format("woff");
  font-weight: 900;
}

@font-face {
  font-family: BNCringeSans;
  src: url("../fonts/BN_Cringe_Sans/bncringesans-light-webfont.woff") format("woff");
  font-weight: 200;
}


* {
  box-sizing: border-box;
  // -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
}

html,
body {
  height: 100%;
  //overflow-x: hidden;
  // -ms-overflow-style: none;
}

// body::-webkit-scrollbar { display: none; }

html {
  scroll-behavior: smooth;
}

body {
  margin: 0px;
  background-color: $main_bg;

  @include f13-se;
}

a {
  color: inherit;
}

div {
  border: 0px;
}

input[type="search"],
input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"],
input[type="tel"] {
  padding: 0px 0px;
  border: none;
  border-bottom: 1px solid $main_line;
  background-color: $main_bg;
  width: 100%;
  height: 48px;
  color: $main_black;
  border-radius: 0px;
  @include f13-sa(300);
  margin-bottom: 8px;
}

input[type="search"] {
  border-radius: 0;
  -webkit-appearance: none;
}

input[type="search"].error,
input[type="text"].error,
input[type="password"].error,
input[type="number"].error,
input[type="email"].error,
input[type="tel"].error {
  outline: none;
  border-bottom: 1px solid $main_point;
}

input[type="search"]:focus,
input[type="search"]:hover,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="text"]:hover,
input[type="password"]:hover,
input[type="number"]:hover,
input[type="email"]:hover,
input[type="tel"]:hover {
  outline: none;
  border-bottom: 1px solid $main_black;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.input-error {
  @include f12-sa(200);
  color: $main_point;
  display: none;
  margin-bottom: 8px;
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $main_placeholder;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $main_placeholder;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $main_placeholder;
}

.black {
  color: $main_black;
}

.brown {
  color: $main_brown;
}

.blue {
  color: $blue_font;
}

.subblue {
  color: $blue_subfont;
}

.lightblue {
  color: $light_blue_font;
}

.sublightblue {
  color: $light_blue_subfont;
}

.red {
  color: $red_font;
}

.subred {
  color: $red_subfont;
}

.main {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  min-width: 320px;
  overflow: auto;
}

* {
  word-break: keep-all;
}

// header-inner에 헤더 컨텐츠를 넣어야 함 베너를 동적으로 넣고 빼기 위함
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 56px;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

  &.header-block {
    display: block;
  }

  .header-inside {
    display: flex;
    width: 100%;
    min-height: 56px;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &.common {
    align-items: stretch;
  }
}

.bg-color {
  background-color: $main_bg;
  opacity: 1;
}

.header-product-mo {
  .product-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.menu-popup-control-mo {
  display: flex;
}

.backWordArrow {
  margin-left: 16px;
  margin-top: 12px;
}

.header-cart-mo {
  .cart-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.header-account-mo {
  .account-name {
    color: $main_black;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.header-cart-title,
.header-account-title {
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.header-cart-title.visible,
.header-account-title.visible {
  opacity: 1;
}

.header.popup {
  // background-color: $menu_back;
}

.header.fixed {
  position: fixed;
  z-index: 1000;
}

.header .header-logo {
  margin-left: 20px;
  cursor: pointer;
}

.header .header-menu {
  margin-right: 16px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.visibleInMo {
  display: inherit;
}

.visibleInDt {
  display: none;
}

#header_dt,
#header_menu_dt,
#header_common_dt,
#header_product_dt,
#header_cart_dt,
#header_account_dt {
  visibility: hidden;
  opacity: 0;
}

#header_menu_dt,
#header_common_dt,
#header_product_dt,
#header_cart_dt,
#header_account_dt {
  position: fixed;
}

#header_common_mo,
#header_product_mo,
#header_cart_mo,
#header_account_mo {
  position: fixed;
  // z-index: 9;
  z-index: 22;
}

#header_product_mo {
  display: block;
}

#header_account_mo {
  display: block;
}

#header_account_dt {
  display: block;
}

#header_cart_mo {
  display: block;
}

#header_cart_dt {
  display: block;
}

.content {
  padding-top: 40px;
  padding-bottom: 64px;
  padding-right: 48px;
  padding-left: 48px;
}

.back-blur {
  // -webkit-transition: all .4s cubic-bezier(.25, .8, .25, 1);
  // transition: all .4s cubic-bezier(.25, .8, .25, 1);
  // -webkit-filter: blur(4px);
  // filter: blur(4px);
}

.menu {
  position: fixed;
  z-index: 2000;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100vh;
  background-color: $main_bg;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  visibility: hidden;
  opacity: 0;
  display: table;
  overflow: scroll;
  -ms-overflow-style: none;
}

.menu::-webkit-scrollbar {
  display: none;
}

.menu.toggle {
  visibility: visible;
  opacity: 1;
}

.menu .menu-content .menu-content-top {
  padding: 40px 48px;
  padding-top: 96px;
}

.menu .menu-content .menu-content-bottom {
  padding: 0px 48px;
  // overflow: scroll;
  // height: 55vh;
}

.menu .menu-msg {
  @include f16-se;
  color: $main_black;
}

.menu .menu-sub {
  display: flex;
  flex-flow: row;
  @include f12-sa(300);
  color: $main_brown;
  margin-top: 16px;
}

.menu .menu-sub .sub-item {
  margin-right: 16px;
  @include f12-sa(300);
  cursor: pointer;
}

.menu .menu-list {
  display: flex;
  flex-flow: column;
  @include f16-se;
  color: $main_black;
  margin-top: 48px;
}

.menu .menu-list .menu-item {
  margin-bottom: 40px;
  cursor: pointer;
}

.menu .line {
  height: 1px;
  border-bottom: 1px solid $main_line;
  margin-top: 40px;
}

.menu .menu-sns {
  display: flex;
  // position: absolute;
  bottom: 0;
  margin-bottom: 40px;
  align-items: center;
  padding-left: 48px;
  padding-right: 48px;
}

.menu .menu-sns .sns-item {
  margin-right: 15px;
  cursor: pointer;
}

.cta-btn {
  @include cta_button;
}

.kakao-login .cta-btn .icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  background-image: url(https://fly.gitt.co/collins/img/button_kakao.svg);
  -webkit-background-size: unset;
  -moz-background-size: unset;
  -o-background-size: unset;
  background-size: unset;
  background-clip: padding-box;
  background-position: center center;
  background-repeat: no-repeat;
}

.reg-content {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
}

.reg-content .reg-msgs {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.reg-content .reg-msgs {
  .reg-msg1 {
    @include f16-se;
    color: $main_black;
    margin-bottom: 10px;
  }

  .reg-msg-s {
    @include f16-se;
    color: $main_black;
    margin-bottom: 40px;
  }

  .reg-msg2 {
    @include f12-sa(300);
    color: $main_brown;
    margin-bottom: 48px;
  }

  .reg-msg3 {
    @include f16-se;
    color: $main_black;
    margin-bottom: 40px;
  }
}

.reg-content .reg-btns .kakao-login {
  margin-bottom: 8px;
}

.reg-content .reg-btns .email-login {
  margin-bottom: 16px;
}

.reg-content .reg-sub {
  @include f13-sa(300);
  display: flex;
  color: $main_brown;
}

.reg-content .reg-sub .sub-item {
  margin-right: 8px;
  cursor: pointer;
}

.reg-content .login-sub {
  @include f13-sa(300);
  display: flex;
  color: $main_black;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.reg-content .login-sub .sub-item {
  margin-right: 8px;
  cursor: pointer;
}

.reg-content .login-sub .sub-item.vline {
  height: 10px;
  width: 1px;
  background-color: $main_line;
}

.reg-content {
  .reg-msg4 {
    @include f13-sa(300);
    color: $main_brown;
    margin-bottom: 6px;
  }

  .reg-msg5 {
    cursor: pointer;
    @include f13-sa(300);
    color: $main_black;
    text-decoration: underline;
  }
}

.reg-info {
  @include f12-sa(300);
  background-color: rgba(231, 225, 211, 0.3);
  width: 264px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.5;
  color: #ff5316;
  margin-top: 40px;
}

.form-content {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  &.err {
    margin-top: 56px;
  }
}

.errImgWrapper {
  width: 264px;
  height: 264px;
  @include flex-center;

  .errImg {
    width: 140%;
    height: 140%;
  }
}

.reg-btns.err {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .other-actions {
    margin-top: 16px;
    @include f13-sa(300);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: $main_black;
    @include flex-center;

    .other-action {
      cursor: pointer;
      margin-right: 12px;
      margin-left: 12px;
    }
  }

  .action-vertical-bar {
    width: 1px;
    height: 12px;
    background-color: #efebe2;
  }
}

.form-content .form-msgs {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.form-content .form-msgs .form-msg1 {
  @include f16-se;
  color: $main_black;
  margin-bottom: 10px;
}

.form-content .form-msgs .form-msg2 {
  @include f12-sa(300);
  color: $main_brown;
  margin-bottom: 16px;

  .dark-text {
    display: inline-block;
    margin-top: 6px;
    color: $main_black;
  }
}

.form-content .form-inputs {
  width: 100%;
  max-width: 264px;
}

.form-content .form-terms {
  display: flex;
  flex-flow: row;
  width: 100%;
  max-width: 264px;
  @include f12-sa(400);
  color: $main_brown;
  margin-top: 24px;

}

.form-content .form-terms.privacy {
  margin-top: 8px;
}

.form-content .form-terms .agree-btn {
  display: flex;
}

.form-content .form-terms .checked{
  color: #604448;
}

.form-content .form-terms .agree-btn .sub-form-terms {
  color: #C0A691;
  @include font-sans(12px, 400);
  font-style: normal;
  line-height: 18px; /* 150% */
  margin-left: 2px;
}

.form-content .form-terms .agree-icon {
  margin-right: 4px;
  float: left;
  width: 18px;
  height: 18px;
  cursor: pointer;

  img {
    width: 100%;
    display: flex;
  }
}

.form-content .form-terms .agree-text {
  line-height: 18px;
}

.form-content .form-terms .agree-msg {
  line-height: 18px;
  width: 10px;
  margin-left: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    display: flex;
    width: 100%;
    height: 10px;
  }
}

.email-reg {
  margin-top: 40px;
}

.email-reg .cta-btn {
  @include cta_brown_button;
}

.brown-btn .cta-btn {
  @include cta_brown_button;
  margin-top: 40px;
}

.footer {
  padding: 56px 48px;
  padding-bottom: 136px;
  background-color: $main_line;
  color: $main_black;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 600px;
  //height: 100%;
}

.footer .footer-logo {
  opacity: 0.5;
  margin-bottom: 40px;
}

.footer .footer-logo-img {
  width: 49px;
  height: 17px;
  margin-right: 16px;
}

.footer .footer-company {
  @include f12-sa(300);
}

.footer .footer-menu {
  @include f13-se;
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  min-width: 260px;
  margin-top: 24px;
}

.footer .footer-menu .footer-menu-item {
  color: rgba(96, 68, 72, 0.85);
  margin-right: 24px;
  width: 104px;
  margin-bottom: 12px;
  // width: 100px;
  // opacity: 0.5;
  cursor: pointer;
}

.footer .footer-submenu {
  @include f12-sa(300);
  display: flex;
  margin-top: 32px;
}

.footer .footer-submenu .footer-menu-item {
  margin-right: 24px;
  opacity: 0.5;
  cursor: pointer;
}

.footer .footer-address {
  @include f11-sa(300);
  margin-top: 8px;
  opacity: 0.5;
}

.footer .footer-subtitle {
  @include f11-sa(300);
  margin-top: 24px;
  opacity: 0.5;
}

.footer .footer-acc {
  @include f13-sa(300);
  margin-top: 26px;
  opacity: 0.5;
  // display: flex;
  // justify-content: space-between;
}

.footer .footer-acc .footer-menu-sns {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.footer .footer-acc .footer-menu-sns .sns-item {
  margin-right: 16px;
  cursor: pointer;
}

.footer .footer-acc .footer-menu-action {
  display: flex;
  align-items: center;
}

.footer .footer-acc .footer-menu-action .action-item {
  margin-right: 16px;
  cursor: pointer;
}

.green-btn {
  margin-top: 40px;
}

.green-btn .cta-btn {
  @include cta_green_button;
}

.green-cta-btn {
  @include cta_green_button;
}

.gray-cta-btn {
  @include cta_gray_button;
}

.brown-cta-btn {
  @include cta_brown_button;
}

.address-btn {
  @include cta_gray_button_sm;
  margin-left: 16px;
  min-width: 96px;
}

.form-content .form-inputs .address {
  display: flex;
  align-items: flex-end;
}

.password-btn {
  @include cta_gray_button_sm;
}

.logout-btn {
  @include cta_gray_button_sm;
}

.content .content-container {
  display: flex;
  justify-content: center;
}

.content-container.hidden {
  display: none;
}

.account-content {
  width: 100%;
  max-width: 264px;
}

.account-content-dt {
  width: 350px;
  margin-right: 160px;
  display: none;
}

.account-msgs .account-msg1 {
  @include f16-se;
  color: $main_black;
}

.account-info-menu {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
}

.account-content .account-info-menu .menu-item {
  @include f14-se;
  color: $main_black;
}

.account-content .account-info-menu .menu-item-edit {
  @include f13-sa(300);
  text-decoration: underline;
  color: $main_black;
  cursor: pointer;
}

.account-content .account-info {
  margin-top: 24px;
}

.account-content .account-info .info-item {
  margin-bottom: 8px;
  display: flex;
}

.account-content .account-info .info-item .info-item-label {
  @include f13-sa(300);
  color: $main_brown;
  min-width: 56px;
  margin-right: 24px;
}

.account-content .account-info .info-item .info-item-value {
  @include f13-sa(300);
  color: $main_black;
}

.account-btns {
  display: flex;
  margin-top: 48px;
}

.account-btns .password-btn {
  min-width: 120px;
  margin-right: 8px;
}

.account-btns .logout-btn {
  min-width: 120px;
}

.home-main {
  width: 100%;
  height: 100%;

  .bg-dim {
    // z-index: -99;
    visibility: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background-color: $dim_bg;
    // transition: all 500ms;
  }

  &.back-blur {
    .bg-dim {
      visibility: visible;
      z-index: 100;
      opacity: 1;
    }
  }
}

.home {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  .bg-dim {
    // z-index: -99;
    visibility: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background-color: $dim_bg;
    // transition: all 500ms;
  }

  &.back-blur {
    .bg-dim {
      visibility: visible;
      z-index: 100;
      opacity: 1;
    }
  }
}

.cartAndMenu {
  display: flex;
  align-items: center;
}

.intro .front1-msg1 {
  @include f24-se;
  color: $main_black;
}

.intro .front1-msg2 {
  @include f13-se;
  color: $main_black;
  margin-top: 8px;
}

.home-cta-btn {
  @include cta_green_button;
  width: 100%;
  position: fixed;
  bottom: 0px;
}

.story {
  padding: 88px 48px;
  background-color: $main_line;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow-x: hidden;
  justify-content: center;
}

.story .story-content {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.story .front2-img {
  width: 360px;
  height: 148px;
  // margin-left: 96px;
  // margin-top: 58px;
}

.front2-msgs {
  @include f13-se;
  color: $main_black;
  min-width: 264px;
}

.front2-msgs .msg-header {
  @include f14b-se;
  margin-top: 48px;
}

.front2-msgs .msg-sub1 {
  margin-top: 16px;
}

.front2-msgs .msg-sub2 {
  margin-top: 16px;
  text-decoration: underline;
}

.m0 {
  margin-top: 0px;
}

.m8 {
  margin-top: 8px;
}

.m16 {
  margin-top: 16px;
}

.m30 {
  margin-top: 30px;
}

.m32 {
  margin-top: 16px;
}

.m24 {
  margin-top: 24px;
}

.m40 {
  margin-top: 40px;
}

.m48 {
  margin-top: 48px;
}

.m64 {
  margin-top: 64px;
}

.intro {
  padding: 0px 48px;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}

@mixin content-common {
  padding: 64px 48px;
  padding-bottom: 120px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.content3 {
  @include content-common;
  background-color: $main_bg;
}

.content3-1 {
  @include content-common;
  background-color: $blue_bg;
}

.content3-2 {
  @include content-common;
  background-color: $light_blue_bg;
}

.content3-3 {
  @include content-common;
  background-color: $red_bg;
}

.product {
  @include content-common;
  background-color: $red_bg;
  padding-bottom: 88px;
  padding-top: 0px;
  overflow-x: hidden;
}

.storyimg {
  width: 264px;
  height: 264px;
}

.swiper-pagination-bullet {
  background: none;
  opacity: 1;
  border: 1px solid $main_black;
}

.swiper-pagination-bullet-active {
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}

.swiper-pagination {
  margin-bottom: 67px;
  text-align: left;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0px;
  left: calc(50% - 140px);
  width: 100%;
}

.image-wrapper {
  width: 100%;
  height: 100%;
  background-color: $red_bg;
}

.image-wrapper .product_image_mo {
  width: 100%;
}

.intro-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.intro-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.story-msg {
  width: 280px;
  min-height: 278px;
}

.story-msg .msg-title {
  @include f14b-se;
  margin-top: 32px;
}

.story-msg .msg-body {
  @include f13-se;
  margin-top: 24px;
}

.story-msg .msg-acc {
  @include f12-sa(300);
  margin-top: 32px;
  cursor: pointer;
  text-decoration: underline;
}

.msg-acc-welcome {
  @include f12-sa(300);
  color: $main_black;
  margin-top: 16px;
  cursor: pointer;
  text-decoration: underline;
}

.product-msgs .msg-sub1 {
  @include f13-sa(300);
  color: $main_black;
  margin-top: 16px;
}

.product-msgs .msg-title {
  @include f24-se;
  color: $main_black;
  margin-top: 8px;
}

.product-msgs .msg-body1 {
  @include f13-se;
  color: $main_black;
  margin-top: 24px;
}

.product-msgs .msg-body2 {
  @include f13-se;
  color: $main_black;
  margin-top: 16px;
}

.product-msgs .msg-body3 {
  @include f13-se;
  color: $main_black;
  margin-top: 16px;
}

.product-msgs .msg-bottom {
  @include f12-sa(300);
  color: $main_brown;
  margin-top: 8px;
}

.thankyou-img {
  width: 264px;
  height: 264px;
}

.swiper-button-next,
.swiper-button-prev {
  width: 70px;
  height: 70px;
  visibility: hidden;
}

#content3 {
  .swiper-button-next {
    right: calc(50% - 494px);
  }

  .swiper-button-prev {
    left: calc(50% - 494px);
  }
}

.show {
  display: block;
}

.login-form {
  width: 100%;
  max-width: 264px;
}

.password-btn {
  cursor: pointer;
}

.terms {
  position: fixed;
  z-index: 1999;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: $main_bg;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  visibility: hidden;
  opacity: 0;
  overflow: scroll;
}

.overlay .header {
  justify-content: flex-end;
  min-height: 56px;
  padding: 0px 0px;
}

.overlay .header .header-close {
  margin-right: 16px;
  cursor: pointer;
}

.terms .terms-content {
  padding: 0px 48px;
  color: $main_black;
}

.terms .terms-content .privacy-title {
  @include f16b-se;
  margin-bottom: 40px;
  width: 100%;
  text-align: center;

  &.off {
    color: $main_brown;
  }
}

.terms .terms-titles {
  padding: 0px 48px;
  display: flex;
}

.terms .terms-title {
  @include f16b-se;
  margin-bottom: 40px;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid;
  padding-bottom: 16px;
  cursor: pointer;
  color: $main_black;

  &.off {
    color: $main_brown;
  }
}

.terms .terms-content .terms-subtitle {
  @include f14-se;
  margin-bottom: 10px;
}

.terms .terms-content .terms-body {
  @include f13-sa(300);
  margin-bottom: 24px;
}

.overlay {
  position: fixed;
  z-index: 1100;
  top: 100%;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  visibility: hidden;
  opacity: 0;
}

.overlay.overlay-toggle {
  visibility: visible;
  opacity: 1;
  top: 0px;
}

.overlay-back {
  visibility: hidden;
  opacity: 0;
  z-index: 1099;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  /*not in ie */
  filter: progid:DXImageTransform.Microsoft.Gradient(startColorstr='#20000000', endColorstr='#20000000');
  /* ie */
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.overlay-back.overlay-toggle {
  visibility: visible;
  opacity: 1;
}

.overlay-content {
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding-bottom: 100px;
}

.overlay-header {
  width: 100%;
  height: 48px;
  overflow: hidden;
}

.overlay-notice-header {
  width: 100%;
  height: 64px;
  overflow: hidden;
  background: #f8f8f8;
}

.overlay-notice-header .overlay-notice-title {
  font-size: 13px;
  color: #999999;
  letter-spacing: 0;
  text-align: center;
  text-align: left;
  padding: 14px 16px;
}

.overlay-header .overlay-acc {
}

.overlay-header .overlay-title {
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  line-height: 48px;
}

.overlay-close {
  //background-image: url("https://dev-collins.gitt.co/wp-content/themes/shopkeeper-child/images/common/btn/close_30.svg");
  width: 30px;
  height: 30px;
  margin-right: 16px;
  -webkit-background-size: unset;
  -moz-background-size: unset;
  -o-background-size: unset;
  background-size: unset;
  background-clip: padding-box;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  float: right;
  position: relative;
  top: -39px;
}

.product .product-msgs {
  overflow-x: hidden;
}

.product .product-msgs {
  overflow-x: hidden;
}

#content4 .product .product-msgs {
  overflow-x: inherit;
}

//#ch-plugin {
//  opacity: 0;
//}

// @mixin transition($opt) {

// 	-webkit-transition: unquote($opt);
// 	-moz-transition: unquote($opt);
// 	-ms-transition: unquote($opt);
// 	-o-transition: unquote($opt);
// 	transition: unquote($opt);
// }

// @mixin transform-transition($opt) {

// 	-webkit-transition: -webkit-transform unquote($opt);
// 	-moz-transition: -moz-transform unquote($opt);
// 	-ms-transition: -ms-transform unquote($opt);
// 	-o-transition: -o-transform unquote($opt);
// 	transition: transform unquote($opt);

// }

// @mixin transform($opt) {

// 	-webkit-transform: unquote($opt);
// 	-moz-transform: unquote($opt);
// 	-ms-transform: unquote($opt);
// 	-o-transform: unquote($opt);
// 	transform: unquote($opt);
// }

// @mixin opacity($opt) {

// 	$alpha: $opt * 100;
// 	opacity: $opt;
// 	filter: alpha(opacity=$alpha);
// }

$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

.intro-video {
  @include opacity(0);

  &.onShow {
    @include transition("0.75s 2.2s ease-out, opacity 0.75s 2.2s  linear");
    @include opacity(1);
  }

  &.showEnd {
    @include transition(none);
  }
}

.intro-text1 {
  span {
    @include opacity(0);
  }

  &.onShow {
    span {
      @for $i from 1 through 20 {
        $ranNum: random(6) / 10;

        &:nth-child(#{$i}) {
          @include transition(
                          "opacity "+ (1.2 + $ranNum)+"s "+ (0.25 + $ranNum)+"s"+" cubic-bezier(0.215, 0.610, 0.355, 1.000)"
          );
          @include opacity(1);
        }
      }
    }
  }

  &.showEnd {
    span {
      @include transition(none);
    }
  }
}

.intro-text2 {
  @include opacity(0);
  @include transform(translateY(40px));

  &.onShow {
    @include transition("0.75s 1s ease-out, opacity 0.75s 1s linear");
    @include opacity(1);
    @include transform(translateY(0px));
  }

  &.showEnd {
    @include transition(none);
  }
}

.intro-cta {
  @include opacity(0);

  &.onShow {
    @include transition("0.75s 1.75s ease-out, opacity 0.75s 1.75s  linear");
    @include opacity(1);
  }

  &.showEnd {
    @include transition(none);
  }
}

.story-image {
  @include opacity(0);
  //@include transform(scale(0.95));

  &.onShow {
    @include transition("1s 0.25s ease-out, opacity 1s 0.25s linear");
    @include opacity(1);
    //@include transform(scale(1));
  }

  &.showEnd {
    @include transition(none);
  }
}

.swiper-pagination.story-image.swiper-pagination-bullets {
  transform: translate(-105px, 0);
}

.story-text1 {
  @include opacity(0);
  @include transform(translateY(40px));

  &.onShow {
    @include transition("0.75s 0.25s ease-out, opacity 0.75s 0.25s linear");
    @include opacity(1);
    @include transform(translateY(0px));
  }

  &.showEnd {
    @include transition(none);
  }
}

.story-text2 {
  @include opacity(0);
  @include transform(translateY(40px));
  overflow-x: hidden;

  &.onShow {
    @include transition("1s 0.5s ease-out, opacity 1s 0.5s linear");
    @include opacity(1);
    @include transform(translateY(0px));
  }

  &.showEnd {
    @include transition(none);
  }
}

.story-text3 {
  @include opacity(0);
  @include transform(translateY(40px));

  &.onShow {
    @include transition("1.25s 0.75s ease-out, opacity 1.25s 0.75s linear");
    @include opacity(1);
    @include transform(translateY(0px));
  }

  &.showEnd {
    @include transition(none);
  }
}

.story-detail-text1 {
  @include opacity(0);
  @include transform(translateX(40px));
  overflow-x: hidden;

  &.onShow {
    @include transition("0.75s 0s ease-out, opacity 0.75s 0s linear");
    @include opacity(1);
    @include transform(translateX(0px));
  }

  &.showEnd {
    @include transition(none);
  }
}

.story-detail-text2 {
  @include opacity(0);
  @include transform(translateX(40px));
  overflow-x: hidden;

  &.onShow {
    @include transition("1s 0.5s ease-out, opacity 1s 0.5s linear");
    @include opacity(1);
    @include transform(translateX(0px));
  }

  &.showEnd {
    @include transition(none);
  }
}

.story-detail-btn {
  @include opacity(0);

  &.onShow {
    @include transition("1s 0.75s ease-out, opacity 1s 0.75s linear");
    @include opacity(1);
  }

  &.showEnd {
    @include transition(none);
  }
}

.product-text1 {
  @include opacity(0);
  @include transform(translateX(-80px));
  overflow-x: hidden;

  &.onShow {
    @include transition("0.75s 0s ease-out, opacity 0.75s 0s linear");
    @include opacity(1);
    @include transform(translateY(0px));
  }

  &.showEnd {
    @include transition(none);
  }
}

.product-text2 {
  @include opacity(0);
  @include transform(translateX(80px));
  overflow-x: hidden;

  &.onShow {
    @include transition("0.75s 0s ease-out, opacity 0.75s 0s linear");
    @include opacity(1);
    @include transform(translateX(0px));
  }

  &.showEnd {
    @include transition(none);
  }
}

// .product-text2 {

// 	span {
// 		@include opacity(0);
// 	}

// 	&.onShow {

// 		span {
// 			@for $i from 1 through 20 {
// 				$ranNum: random(6) / 10;

// 				&:nth-child(#{$i}) {
// 					@include transition('opacity '+(1.2 + $ranNum)+'s '+(0.5+$ranNum)+'s'+' cubic-bezier(0.215, 0.610, 0.355, 1.000)');
// 					@include opacity(1);
// 				}
// 			}
// 		}

// 	}
// }

.product-text3 {
  @include opacity(0);
  @include transform(translateY(40px));

  &.onShow {
    @include transition("0.75s 1s ease-out, opacity 0.75s 1s linear");
    @include opacity(1);
    @include transform(translateY(0px));
  }

  &.showEnd {
    @include transition(none);
  }
}

.product-text4 {
  @include opacity(0);
  @include transform(translateY(40px));

  &.onShow {
    @include transition("1s 1.25s ease-out, opacity 1s 1.25s linear");
    @include opacity(1);
    @include transform(translateY(0px));
  }

  &.showEnd {
    @include transition(none);
  }
}

.product-text5 {
  @include opacity(0);
  @include transform(translateY(40px));

  &.onShow {
    @include transition("1.25s 1.5s ease-out, opacity 1.25s 1.5s linear");
    @include opacity(1);
    @include transform(translateY(0px));
  }

  &.showEnd {
    @include transition(none);
  }
}

.product-btn {
  @include opacity(0);

  &.onShow {
    @include transition("0.75s 2s ease-out, opacity 0.75s 2s  linear");
    @include opacity(1);
  }

  &.showEnd {
    @include transition(none);
  }
}

.product-text6 {
  @include opacity(0);

  &.onShow {
    @include transition("0.75s 2s ease-out, opacity 0.75s 2s  linear");
    @include opacity(1);
  }

  &.showEnd {
    @include transition(none);
  }
}

.login_confirm {
  @include f13-sa(200);
  color: $main_black;
  width: 312px;
  min-height: 150px;

  .login_confirm_msg {
    padding: 24px;
    min-height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cta_buttons {
    @include f13-se;
    display: flex;
    height: 48px;
    line-height: 48px;
    width: 100%;
    cursor: pointer;

    .cta_cancel {
      width: 50%;
      text-align: center;
      background-color: $main_etc;
    }

    .cta_accept {
      width: 50%;
      text-align: center;
      background-color: $main_green;
    }

    .cta_confirm {
      width: 100%;
      text-align: center;
      background-color: $main_etc;
    }
  }
}

#products_dt {
  display: none;
}

@media only screen and (max-height: 640px) {
  .home-main {
    height: 640px;
  }

  .intro {
    height: 640px;
  }

  .intro-video {
    height: 640px;
  }
}

.form-order-no {
  @include f13-sa(400);
  color: $main_black;
  display: flex;
  margin-bottom: 16px;

  .ml1 {
    margin-right: 8px;
  }
}

.form-items {
  display: flex;
  justify-content: center;
  flex-flow: column;

  .form-item {
    display: flex;
    justify-content: center;

    .title {
      @include f13-sa(400);
      color: $main_brown;
    }

    .value {
      @include f13-sa(300);
      color: $main_black;
    }

    .ml1 {
      margin-right: 8px;
    }
  }

  .mb1 {
    margin-bottom: 8px;
  }
}

.nm {
  margin-top: 0px;
}

.order-btns {
  display: flex;
  margin-top: 48px;
  flex-wrap: wrap;
  justify-content: center;

  .gray-cta-btn {
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: 8px;
  }
}

#home_cta_btn_dt {
  visibility: hidden;
}

.cartIconWidthNumberDt.hidden {
  display: none;
}

.cartIconWidthNumberMo.hidden {
  display: none;
}

.menuCartIconWidthNumberDt.hidden {
  display: none;
}

.menuCartIconWidthNumberMo.hidden {
  display: none;
}

.menuCartIconWidthNumberMo {
  margin-right: 10px;
}

.cartIconWidthNumberMo {
  margin-right: 10px;
}

@media only screen and (min-width: $breakpoint-desktop-mid) {
  #homePage {
    .story {
      padding: 80px 40px;
    }

    .story .story-content {
      flex-flow: row;
      width: 100%;
      max-width: 1024px;
    }

    .story .front2-img {
      width: 600px;
      height: 209px;
      margin-right: 88px;
      margin-left: 0px;
      margin-top: 0px;
    }

    .front2-msgs {
      font-family: "Noto Serif KR", serif;
      font-weight: 200;
      // font-size: 15px;
      line-height: 22px;
      line-height: 26px;
    }

    // .content3 {
    // 	padding: 180px 360px;
    // 	flex-flow: row;
    // }

    // .storyimg {
    // 	width: 300px;
    // 	height: 300px;
    // }

    // .story-msg {
    // 	min-width: 350px;
    // }

    // .story-msg .msg-title {
    // 	font-family: "Noto Serif KR", serif;
    // 	font-weight: 500;
    // 	font-size: 16px;
    // 	line-height: 24px;
    // 	margin-top: 32px;
    // 	margin-top: 0px;
    // }

    // .story-msg .msg-body {
    // 	font-family: "Noto Serif KR", serif;
    // 	font-weight: 200;
    // 	font-size: 15px;
    // 	line-height: 22px;
    // 	margin-top: 40px;
    // }

    // .msg-acc {
    // 	font-family: "Noto Sans KR", sans-serif;
    // 	font-weight: 300;
    // 	font-size: 13px;
    // 	line-height: 18px;
    // 	margin-top: 48px;
    // }

    // .swiper-pagination.story-image.swiper-pagination-bullets {
    // 	transform: translate(0, 0);
    // }
    // .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    // 	bottom: 0px;
    // 	left: 0px;
    // 	width: 100%;
    // }
  }
}

@media only screen and (min-width: $breakpoint-desktop) {

  .deskTopOnly {
    display: block;
  }

  .mobileOnly {
    display: none;
  }

  .visibleInMo {
    display: none;
  }

  .visibleInDt {
    display: inherit;
  }

  .swiper-pagination.story-image.swiper-pagination-bullets {
    transform: translate(0, 0);
  }
  #header_product_dt {
    // padding: 40px 48px 0 48px;
    display: block;

    .header-inside {
      padding: 40px 48px 0 48px;
    }

    .header-menu {
      margin: 0;
    }
  }

  #header_cart_dt {
    padding: 0;

    .header-inside {
      padding: 40px 56px 0 48px;
    }

    .header-menu {
      margin: 0;
    }
  }

  #header_account_dt {
    padding: 0px;

    .header-inside {
      padding: 40px 56px 0 48px;
    }

    .header-menu {
      margin: 0;
    }
  }

  #header_dt,
  #header_menu_dt,
  #header_common_dt,
  #header_product_dt,
  #header_cart_dt,
  #header_account_dt {
    visibility: visible;
    z-index: 22;
    opacity: 1;
  }

  #header_menu_dt,
  #header_common_dt {
    position: relative;
  }

  #header_mo,
  #header_menu_mo,
  #header_common_mo,
  #header_product_mo,
  #header_cart_mo,
  #header_account_mo {
    visibility: hidden;
    opacity: 0;
  }

  #header_menu_mo,
  #header_common_mo,
  #header_product_mo,
  #header_cart_mo,
  #header_cart_mo {
    position: fixed;
  }

  .header-inside {
    padding: 40px 0px;
    min-height: 40px;
  }

  .header .header-menu {
    width: 40px;
    height: 40px;
    margin-right: 0px;
    margin-left: 40px;
  }

  .header .header-logo {
    margin-left: 0px;
  }
  .header.popup .header-menu {
    margin-left: 24px;
    margin-top: 24px;
  }

  .header.popup .header-logo {
    margin-top: 22px;
  }

  .header .header-acc {
    width: 40px;
    height: 40px;
    margin-right: 40px;
  }

  .intro {
    align-items: center;
    justify-content: center;
  }

  .intro .intro-content {
    width: 100%;
    max-width: 1024px;
  }

  .story .story-content {
    flex-flow: row;
    width: 100%;
    max-width: 1024px;
  }

  .content3 {
    padding: 180px 360px;
    flex-flow: row;
  }

  .content3-1 {
    padding: 180px 360px;
    flex-flow: row;
  }

  .content3-2 {
    padding: 180px 360px;
    flex-flow: row;
  }

  .content3-3 {
    padding: 180px 360px;
    flex-flow: row;
  }

  .story .front2-img {
    width: 600px;
    height: 209px;
    margin-right: 88px;
    margin-left: 0px;
    margin-top: 0px;
  }

  .storyimg {
    width: 500px;
    height: 500px;
  }

  .menu .menu-content .menu-content-top {
    padding: 40px 96px;
    padding-top: 160px;
  }

  .menu .menu-content .menu-content-bottom {
    padding: 0px 96px;
  }

  .intro .front1-msg1 {
    @include f36-se;
    //margin-top: 375px;
  }

  .intro .front1-msg2 {
    @include f15-se;
    margin-top: 16px;
  }

  #home_cta_btn {
    visibility: hidden;
  }

  #home_cta_btn_dt {
    visibility: visible;
  }

  #homePage .story {
    padding: 240px 0px;
  }

  .front2-msgs {
    @include f15-se;
    line-height: 26px;
  }

  .front2-msgs .msg-header {
    @include f16b-se;
    margin-bottom: 4px;
    margin-top: 0px;
  }

  .front2-msgs .msg-sub1 {
    margin-top: 24px;
  }

  .front2-msgs .msg-sub2 {
    margin-top: 24px;
  }

  .story-msg {
    min-width: 350px;
  }

  .story-msg .msg-title {
    @include f16b-se;
    margin-top: 32px;
    margin-top: 0px;
  }

  .story-msg .msg-body {
    @include f15-se;
    margin-top: 40px;
  }

  .msg-acc {
    @include f13-sa(300);
    margin-top: 48px;
  }

  #products_mo {
    display: none;
  }

  #products_dt {
    display: block;
  }

  .menu {
    left: -500px;
    width: 500px;
    // min-height: 768px;
    //opacity: 1.0;
  }

  .menu.toggle {
    left: 0px;
  }

  .product {
    padding: 180px 64px;
    background-image: url(https://fly.gitt.co/collins/img/products.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-clip: padding-box;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .product .product-content {
    max-width: 1024px;
    width: 100%;
  }

  .product-msgs .msg-sub1 {
    @include f14-sa(400);
    color: $main_black;
    margin-top: 16px;
  }

  .product-msgs .msg-title {
    @include f36-se();
    color: $main_black;
    margin-top: 8px;
  }

  .product-msgs .msg-body1 {
    @include f15-se;
    color: $main_black;
    margin-top: 48px;
    line-height: 26px;
  }

  .product-msgs .msg-body2 {
    @include f15-se;
    color: $main_black;
    margin-top: 16px;
    line-height: 26px;
  }

  .product-msgs .msg-body3 {
    @include f15-se;
    color: $main_black;
    margin-top: 16px;
    line-height: 26px;
  }

  .product-msgs .msg-bottom {
    @include f13-sa(300);
    margin-top: 12px;
  }

  .product-msgs {
    min-width: 300px;
  }

  .reg-content {
    margin-top: 47px;
    margin-bottom: 60px;
  }

  .reg-content .reg-msgs {
    .reg-msg1 {
      @include f20-se;
      margin-bottom: 16px;
    }

    .reg-msg2 {
      @include f14-sa(300);
      margin-bottom: 48px;
    }

    .reg-msg3 {
      @include f20-se;
      margin-bottom: 48px;
    }
  }

  .cta-btn {
    @include cta_button_dt;
  }

  .reg-content .reg-sub {
    @include f14-sa(300);
  }
  .reg-content {
    padding-top: 0;

    .reg-msg4 {
      @include f14-sa(300);
      color: $main_brown;
      margin-bottom: 8px;
    }

    .reg-msg5 {
      @include f14-sa(300);
      color: $main_black;
      text-decoration: underline;
      // margin-bottom: 120px;
    }
  }

  .green-btn .cta-btn {
    @include cta_green_button_dt;
  }

  .product .green-btn .cta-btn {
    @include cta_green_button;
  }

  .intro .green-btn .cta-btn {
    @include cta_green_button;
  }

  .reg-content .reg-msgs .reg-msg-s {
    @include f20-se;
    margin-bottom: 48px;
  }

  .reg-info {
    @include f13-sa(300);
    width: 380px;
  }

  .account-msgs .account-msg1 {
    @include f20-se;
  }

  .account-info-menu {
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
  }

  .account-content {
    max-width: 676px;
  }

  .account-content .line {
    height: 1px;
    border-bottom: 1px solid $main_line;
    margin-top: 48px;
  }

  .account-content .account-info-menu .menu-item {
    @include f18-se;
  }

  .account-content .account-info-menu .menu-item-edit {
    @include f14-sa(300);
  }

  .account-content .account-info {
    margin-top: 24px;
  }

  .account-content .account-info .info-item {
    margin-bottom: 8px;
  }

  .account-content .account-info .info-item .info-item-label {
    @include f14-sa(300);
    min-width: 56px;
  }

  .account-content .account-info .info-item .info-item-value {
    @include f14-sa(300);
  }

  .account-content-dt {
    display: block;
  }

  .account-content .account-msgs {
    display: none;
  }

  .account-content .account-btns {
    display: none;
  }

  #basicinfo_menu {
    margin-top: 0px;
  }

  .thankyou-img {
    width: 380px;
    height: 380px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    visibility: visible;
  }

  .email-reg .cta-btn {
    @include cta_brown_button_dt;
  }

  .brown-btn .cta-btn {
    @include cta_brown_button_dt;
  }

  .menu .menu-msg {
    @include f20-se;
    color: $main_black;
  }

  .menu .menu-sub .sub-item {
    margin-right: 16px;
    @include f14-sa(300);
    cursor: pointer;
  }

  .login-form {
    max-width: 380px;
  }
  .form-content {
    &.err {
      margin-top: 100px;
    }
  }
  .reg-btns.err {
    .other-actions {
      font-size: 14px;

      .other-action {
      }
    }
  }

  .form-content .form-inputs {
    max-width: 380px;
  }

  .form-content .form-terms {
    max-width: 380px;
  }

  .form-content .form-msgs .form-msg1 {
    @include f20-se;
  }

  .form-content .form-msgs .form-msg2 {
    @include f14-sa(300);
  }

  .footer .footer-content {
    width: 100%;
    max-width: 1024px;
  }

  .footer .footer-logo-img {
    width: 80px;
    height: 26px;
  }

  .footer .footer-company {
    @include f14-sa(300);
  }

  .footer .footer-menu {
    @include f15-se;
    flex-wrap: wrap;
    width: 70%;
  }

  .footer .footer-menu .footer-menu-item {
    margin-right: 40px;
    width: 140px;
    margin-bottom: 16px;

    // width: auto;
  }

  .footer .footer-submenu {
    @include f14-sa(300);
    display: flex;
    margin-top: 32px;
  }

  .footer .footer-submenu .footer-menu-item {
    margin-right: 24px;
  }

  .footer .footer-address {
    @include f12-sa(300);
    margin-top: 14px;
  }

  .footer .footer-subtitle {
    @include f12-sa(300);
    margin-top: 24px;
    opacity: 0.5;
  }

  .footer .footer-acc {
    @include f13-sa(300);
    margin-top: 26px;
    float: right;
    display: flex;
    align-items: center;
    margin-top: -22px;

    .footer-menu-sns {
      margin-top: 0;
    }
  }

  .menu .menu-list {
    @include f18-se;
  }

  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-prev:after {
    display: none;
  }

  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-next:after {
    display: none;
  }

  .overlay {
    max-width: 500px;
    max-height: 600px;
    left: 50%;
    top: 50%;
    margin-left: -250px;
    margin-top: -300px;
    opacity: 0;
    visibility: hidden;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
  }

  .overlay .overlay-content {
    max-height: 550px;
  }

  .overlay.overlay-toggle {
    visibility: visible;
    opacity: 1;
    top: 50%;
  }

  .swiper-pagination {
    margin-bottom: 67px;
    text-align: center;
  }

  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0px;
    left: 0px;
    width: 100%;
  }

  .footer {
    padding: 72px 48px;
    min-height: 600px;
    //height: 100%;
  }
}

// 스피너

@keyframes ldio-gv9lev5i3zo-o {
  0% {
    opacity: 1;
    transform: translate(0 0);
  }

  49.99% {
    opacity: 1;
    transform: translate(48px, 0);
  }

  50% {
    opacity: 0;
    transform: translate(48px, 0);
  }

  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}

@keyframes ldio-gv9lev5i3zo {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(48px, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

.loadingio-spinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(96, 68, 72, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
}

.ldio-gv9lev5i3zo div {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: 36px;
  left: 25px;
}

.ldio-gv9lev5i3zo div:nth-child(1) {
  background: #604448;
  animation: ldio-gv9lev5i3zo 1s linear infinite;
  animation-delay: -0.5s;
}

.ldio-gv9lev5i3zo div:nth-child(2) {
  background: #ecd7ae;
  animation: ldio-gv9lev5i3zo 1s linear infinite;
  animation-delay: 0s;
}

.ldio-gv9lev5i3zo div:nth-child(3) {
  background: #604448;
  animation: ldio-gv9lev5i3zo-o 1s linear infinite;
  animation-delay: -0.5s;
}

.loadingio-spinner-dual-ball-hqgr8n5kmog {
  width: 120px;
  height: 120px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.ldio-gv9lev5i3zo {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-gv9lev5i3zo div {
  box-sizing: content-box;
}

/* generated by https://loading.io/ */

.cartIconWidthNumberDt.hidden {
  display: none;
}

.cartIconWidthNumberMo.hidden {
  display: none;
}

.backWordArrow.hidden {
  display: none;
}

.menu #header_menu_mo {
  position: fixed;
}

.menu #header_menu_dt {
  position: fixed;
  width: 500px;
}

//   .header-menu {
//   display: block;
//   cursor: pointer;
//   border-radius: 0px;
//   margin: 15% auto;
//   -webkit-transition: all .3s ease, -webkit-transform .2s ease;
//           transition: all .3s ease, transform .2s ease;
//   background: -webkit-linear-gradient(top, transparent 0%, transparent 20%, #2E313C 20%, #2E313C 23%, transparent 23%, transparent 48%, #2E313C 48%, #2E313C 50%, transparent 47%, transparent 76%, #2E313C 76%, #2E313C 78%, transparent 78%), -webkit-linear-gradient(transparent, transparent);
//   background: linear-gradient(to bottom, transparent 0%, transparent 20%, #2E313C 20%, #2E313C 23%, transparent 23%, transparent 48%, #2E313C 48%, #2E313C 50%, transparent 47%, transparent 76%, #2E313C 76%, #2E313C 78%, transparent 78%), linear-gradient(transparent, transparent);
// }

// .header-menu.clicked {
//   background: -webkit-linear-gradient(135deg, transparent 0%, transparent 48%, #2E313C 49%, #2E313C 51%, transparent 51%, transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 48%, #2E313C 49%, #2E313C 51%, transparent 51%, transparent 100%);
//   background: linear-gradient(-45deg, transparent 0%, transparent 48%, #2E313C 49%, #2E313C 51%, transparent 51%, transparent 100%), linear-gradient(45deg, transparent 0%, transparent 48%, #2E313C 49%, #2E313C 51%, transparent 51%, transparent 100%);
//   -webkit-transform: rotate(180deg);
//       -ms-transform: rotate(180deg);
//           transform: rotate(180deg);
// }

body {
  .npay_button_box {
    background-color: transparent !important;
  }
}

.hoverCursorNext:hover {
  cursor: url("../img/arrow_mouse_next_b_72.svg") 30 30, pointer;
}

.hoverCursorPrev:hover {
  cursor: url("../img/arrow_mouse_prev_b_72.svg") 30 30, pointer;
}
