@import '/src/css/variable.scss';

.collectionMoodwaterItemWrapper {
  width: 100%;

  .border-brush-horizontal {
    background-color: #ECE0CE;
    background-repeat: repeat;
    width: 100%;
    height: 2px;
    background-image: url('../../../img/pattern/border_brush_horizontal.svg');
    background-size: 110px 2px;
  }

  .border-brush-vertical {
    background-color: #ECE0CE;
    background-repeat: repeat;
    min-width: 2px;
    background-image: url('../../../img/pattern/border_brush_vertical.svg');
    background-size: 2px 110px;
  }

  .thumbnailArea {
    width: 100%;
    padding-top: 100%;
    position: relative;
    cursor: pointer;


    .outOfStockArea {
      width: 48px;
      height: 48px;
      position: absolute;
      left: calc(50% - 24px);
      top: calc(50% - 24px);
      display: flex;
      border-radius: 24px;
      background-color: rgba(96, 68, 72, 0.8);
      justify-content: center;
      align-items: center;
      z-index: 3;

      .outOfStock-text {
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fffff5;
        justify-content: center;
        align-items: center;
        display: flex;
        @include font-seif(10px, 500)
      }
    }

    .thumbnailPlace {
      width: 100%;
      visibility: hidden;
    }

    .thumbnail1 {
      width: 100%;
      background-color: $main-line;
      opacity: 1;
      transition: all 500ms;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      object-position: center;
      object-fit: cover;
      z-index: 2;
    }

    .thumbnail2 {
      display: none;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      height: 100%;
      object-position: center;
      object-fit: cover;
      background-color: #efebe2;
    }

    .discountBadge {
      width: 32px;
      height: 32px;
      position: absolute;
      z-index: 4;
      top: 0;
      left: 0;
      @include flex-center;
      background-color: $main-point;
      @include f12-se;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      // line-height: 1.27;
      letter-spacing: normal;
      text-align: center;
      color: $main_bg;
    }

    @keyframes rotate {
      from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    .discountFreeBadge {
      width: 42px;
      height: 42px;
      position: absolute;
      z-index: 4;
      top: -6px;
      left: -6px;
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: center;

      img {
        position: absolute;
        display: flex;
        animation: rotate 20s linear infinite;
        width: 100%;
      }

      .discountFreeText {
        color: #FFFFF5;
        @include f-bnc(12px, 700);
        text-align: center;
        font-style: normal;
        z-index: 2;
        line-height: 13px; /* 108.333% */
      }
    }


    .eventBadge {
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 22px;
      background-color: rgba(96, 68, 72, 0.65);
      @include flex-center;
      @include f10-sa(300);

      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: center;
      color: $main-bg;
    }
  }


  .infoArea {
    position: relative;

    .subName {
      @include f12-sa(normal);
      font-size: 12px;
      color: $main-brown;
      margin-top: 11px;
    }

    .name {
      @include f13-sa(400);
      font-size: 12px;
      color: $main-black;
      margin-top: 4px;
    }

    .priceArea {
      display: flex;
      margin-top: 8px;

      .regularPrice {
        @include f12-sa(normal);
        color: $main_black;

        &.crosslined {
          @include f12-sa(500);
          color: $main_brown;
          text-decoration: line-through;
        }
      }

      .discountPrice {
        @include f12-sa(500);
        color: $main_point;
        margin-left: 2px;
      }
    }

    .bottomArea {
      display: flex;
      margin-top: 8px;
      user-select: none;

      .reviewInfo {
        width: 100%;
        display: flex;
        // justify-content: space-between;
        align-items: center;

        .ratingStars {
          display: none;
        }

        .reviewCount {
          @include f11-sa(normal);
          color: $main-black;
          cursor: pointer;
        }
      }

      .cartPopUpIcon1 {
        position: absolute;
        right: 0;
        // bottom: 0;
        top: 65px;
        cursor: pointer;
        z-index: 2;
        background-color: #ECE0CE;
        background-repeat: repeat;
        background-image: url('../../../img/pattern/natural-paper.png');
        background-size: 523px 384px;
        transition: all 500ms;

      }

      .cartPopUpIcon1:hover {
        opacity: 0;
      }

      .cartPopUpIcon2 {
        width: 32px;
        height: 32px;
        position: absolute;
        right: 0;
        // bottom: 0;
        top: 65px;
        cursor: pointer;
        // display: none;
      }

      .cartPopUpIcon2:hover {
        display: block;
      }
    }
  }
}

@media(hover: hover) and (pointer: fine) {
  .collectionMoodwaterItemWrapper {
    .thumbnailArea:hover {
      .thumbnail1 {
        opacity: 0;
      }

      .thumbnail2 {
        display: flex;
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .collectionMoodwaterItemWrapper {
    width: 100%;

    .thumbnailArea {
      width: 100%;
      padding-top: 100%;
      position: relative;
      cursor: pointer;

      .outOfStockArea {
        width: 80px;
        height: 80px;
        left: calc(50% - 40px);
        top: calc(50% - 40px);
        border-radius: 156px;
        background-color: rgba(96, 68, 72, 0.8);

        .outOfStock-text {
          @include font-seif(16px, 500)
        }
      }

      .thumbnailPlace {
        width: 100%;
        visibility: hidden;
      }

      .thumbnail1 {
        width: 100%;
        background-color: $main-line;
        opacity: 1;
        transition: all 500ms;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }


      .thumbnail2 {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        background-color: $main-line;
        top: 0;
        left: 0;
      }

      .discountBadge {
        width: 56px;
        height: 56px;
        position: absolute;
        top: 0;
        left: 0;
        @include flex-center;
        background-color: $main-point;
        @include f16-se;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        // line-height: 1.27;
        letter-spacing: normal;
        text-align: center;
        color: $main_bg;
      }

      .discountFreeBadge {
        width: 72px;
        height: 72px;
        position: absolute;
        z-index: 4;
        top: -10px;
        left: -10px;
        text-align: center;

        img {
          position: absolute;
          display: flex;
          width: 100%;
        }

        .discountFreeText {
          color: #FFFFF5;
          @include f-bnc(20px, 700);
          text-align: center;
          font-style: normal;
          z-index: 2;
          line-height: 20px; /* 108.333% */
        }
      }


      .eventBadge {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        width: unset;
        min-width: unset;
        padding-left: 16px;
        padding-right: 16px;

        height: 28px;
        background-color: rgba(96, 68, 72, 0.65);
        @include flex-center;
        @include f12-sa(300);

        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: center;
        color: $main-bg;
      }
    }

    .infoArea {
      .subName {
        @include f13-sa(normal);
        font-size: 13px;
        color: $main-brown;
        margin-top: 22px;
      }

      .name {
        @include f13-sa(400);
        font-size: 16px;
        color: $main-black;
        margin-top: 2px;
      }

      .priceArea {
        display: flex;
        margin-top: 12px;

        .regularPrice {
          @include f14-sa(500);
          color: $main_black;

          &.crosslined {
            @include f14-sa(normal);
            color: $main_brown;
            text-decoration: line-through;
          }
        }

        .discountPrice {
          @include f14-sa(500);
          color: $main_point;
          margin-left: 2px;
        }
      }

      .bottomArea {
        display: flex;
        margin-top: 28px;

        .reviewInfo {
          width: 100%;
          display: flex;
          // justify-content: space-between;
          align-items: center;

          .ratingStars {
            display: inline;
          }

          .reviewCount {
            @include f12-sa(normal);
            color: $main-black;
            cursor: pointer;
          }
        }

        .cartPopUpIcon1 {
          position: absolute;
          right: 0;
          top: 87px;
          cursor: pointer;
          z-index: 2;
          transition: all 500ms;
          width: 40px;
          height: 40px;
        }

        .cartPopUpIcon1:hover {
          opacity: 0;
        }

        .cartPopUpIcon2 {
          width: 40px;
          height: 40px;
          position: absolute;
          right: 0;
          bottom: 0;
          top: 87px;
          cursor: pointer;
          // display: none;
        }

        .cartPopUpIcon2:hover {
          display: block;
        }
      }
    }
  }
}
