@import '/src/css/variable.scss';

.topBannerMo {
    width: 100%;
    // background-color: $main_strong_brown;
    height: 40px;
    
    
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;


    .topBanner-text{
        @include f13-sa(normal);
        // color: $main_bg;
        text-align:  center;
    }

    .close-btn{
        position: absolute;
        right:3%;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

}

.topBannerDt {
    width: 100%;
    // background-color: $main_strong_brown;
    height: 48px;
    // display: none !important;
    
    
    
    align-items: center;
    justify-content: center;
    position: relative;
    // color: $main_bg;

    &.flex{
        display: none;
    }

    &.none{
        display: none;
    }


    .topBanner-text{
        @include f14-sa(normal);
        
    }

    .close-btn{
        position: absolute;
        right:3%;
        width: 23px;
        height: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

}

@media(min-width: $breakpoint-desktop){


.topBannerMo {
    display: none !important;
    position: fixed;
    top:0;
    left: 0;

}

.topBannerDt {
 
    

    &.flex{
        display: flex;
    }

    &.none{
        display: none;
    }

}

}