
@import '/src/css/variable.scss';

.childOption{
   
    margin-top: 8px;

}

.dropdowns {

    .dropdown-wrapper-simple {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: unset;
        }
    }
    
    
}