
@import '/src/css/variable.scss';

.collectionSortWrapper{
    // position: relative;
    user-select: none;
    position: relative;
    cursor: pointer;

    .curSort{
        @include f13-sa(300);
        color: $main-black;
        display: flex;
        align-items: center;
        img{ 
            
        }


    }

    .collectionSortWrapper{


        position: absolute;
        top : 100%;
        margin-top: 0;
        right : 0;

        background-color: $main-bg;
        border: solid 1px $main-black;
        width: 142px;

        // padding-top: 32px;
        // width: 100vw;
        // left: 0;
        text-align: center;
        transition: all 500ms;
        max-height: 300px;
        overflow: hidden;
        opacity: 1;
        z-index: 7;
        



        .collectionSort{
            height: 40px;
            @include flex-center;
            @include f13-sa(300);
            
            text-align: center;
            color: $main_brown;
        &.focus{
            color: $main_black;
            background-color: $main_line;
            
        }

      

        }

        &.hidden{
            // opacity: 0;
            max-height: 0;
            overflow: hidden;
            visibility: hidden;
            // transition: all 500ms;
            // display: none;
            z-index: -1;
        }
    }



}