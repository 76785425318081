@charset "UTF-8";
:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
    --satc: env(safe-area-inset-top);
    --sarc: env(safe-area-inset-right);
    --sabc: env(safe-area-inset-bottom);
    --salc: env(safe-area-inset-left);
}

@font-face {
    font-family: BNCringeSans;
    src: url("../fonts/BN_Cringe_Sans/bncringesans-regular-webfont.woff") format("woff");
    font-weight: 300;
}

@font-face {
    font-family: BNCringeSans;
    src: url("../fonts/BN_Cringe_Sans/bncringesans-bold-webfont.woff") format("woff");
    font-weight: 600;
}

@font-face {
    font-family: BergenSansSemi;
    src: url("../fonts/BergenSans/BergenSans-SemiBold.otf") format("woff");
    font-weight: 600;
}

@font-face {
    font-family: BNCringeSans;
    src: url("../fonts/BN_Cringe_Sans/bncringesans-bold-webfont.woff") format("woff");
    font-weight: 900;
}

@font-face {
    font-family: BNCringeSans;
    src: url("../fonts/BN_Cringe_Sans/bncringesans-light-webfont.woff") format("woff");
    font-weight: 200;
}


@font-face {
    font-family: AntonRegular;
    src: url("../fonts/Anton/Anton-Regular.ttf.woff") format("woff");
    font-weight: 300;
}


@font-face {
    font-family: "MadeDillan";
    src: url("../fonts/dillan/made_dillan-webfont.woff") format("woff"),
    url("../fonts/dillan/made_dillan-webfont.ttf") format("truetype");
}


* {
    box-sizing: border-box;
    /* -webkit-overflow-scrolling: touch;
    touch-action: pan-y; */
}

html {
    scroll-behavior: smooth;
    height: 100vh;
}

body {
    margin: 0px;
    background-color: #fffff5;
    font-family: "Noto Serif KR", serif;
    font-weight: 200;
    font-size: 13px;
    line-height: 22px;
    height: 100%;
}

a {
    color: inherit;
}

div {
    border: 0px;
}

input[type="search"],
input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"],
input[type="tel"] {
    padding: 0px 0px;
    border: none;
    border-bottom: 1px solid #efebe2;
    background-color: #fffff5;
    width: 100%;
    height: 48px;
    color: #604448;
    border-radius: 0px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 8px;
}

input[type="search"] {
    border-radius: 0;
    -webkit-appearance: none;
}

input[type="search"].error,
input[type="text"].error,
input[type="password"].error,
input[type="number"].error,
input[type="email"].error,
input[type="tel"].error {
    outline: none;
    border-bottom: 1px solid #ff5316;
}

input[type="search"]:focus,
input[type="search"]:hover,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="text"]:hover,
input[type="password"]:hover,
input[type="number"]:hover,
input[type="email"]:hover,
input[type="tel"]:hover {
    outline: none;
    border-bottom: 1px solid #604448;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

.input-error {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 200;
    font-size: 12px;
    line-height: 18px;
    color: #ff5316;
    display: none;
    margin-bottom: 8px;
}

input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #cbb6a3;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #cbb6a3;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #cbb6a3;
}

.black {
    color: #604448;
}

.brown {
    color: #c0a691;
}

.blue {
    color: #2f53a5;
}

.subblue {
    color: #97bfd9;
}

.lightblue {
    color: #182916;
}

.sublightblue {
    color: #698d78;
}

.red {
    color: #9f5151;
}

.subred {
    color: #dfa590;
}

.main {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    min-width: 320px;
    overflow: auto;
}

* {
    word-break: keep-all;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 56px;
    -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.header.header-block {
    display: block;
}

.header .header-inside {
    display: flex;
    width: 100%;
    min-height: 56px;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.header.common {
    align-items: stretch;
}

.welcome-only-mb {
    display: block;
    height: 56px;
}

.bg-color {
    background-color: #fffff5;
    opacity: 1;
}

.header-product-mo .product-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.menu-popup-control-mo {
    display: flex;
}

.backWordArrow {
    margin-left: 16px;
    margin-top: 12px;
}

.header-cart-mo .cart-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.header-account-mo .account-name {
    color: #604448;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.header-cart-title,
.header-account-title {
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.header-cart-title.visible,
.header-account-title.visible {
    opacity: 1;
}

.header.fixed {
    position: fixed;
    z-index: 1000;
}

.header .header-logo {
    margin-left: 20px;
    cursor: pointer;
}

.header .header-menu {
    margin-right: 16px;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.visibleInMo {
    display: inherit;
}

.visibleInDt {
    display: none;
}

#header_dt,
#header_menu_dt,
#header_common_dt,
#header_product_dt,
#header_cart_dt,
#header_account_dt {
    visibility: hidden;
    opacity: 0;
}

#header_menu_dt,
#header_common_dt,
#header_product_dt,
#header_cart_dt,
#header_account_dt {
    position: fixed;
}

#header_common_mo,
#header_product_mo,
#header_cart_mo,
#header_account_mo {
    position: fixed;
    z-index: 22;
}

#header_product_mo {
    display: block;
}

#header_account_mo {
    display: block;
}

#header_account_dt {
    display: block;
}

#header_cart_mo {
    display: block;
}

#header_cart_dt {
    display: block;
}

.content {
    padding-top: 40px;
    padding-bottom: 64px;
    padding-right: 48px;
    padding-left: 48px;
}

.menu {
    position: fixed;
    z-index: 2000;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100vh;
    background-color: #fffff5;
    -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 0.6s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    visibility: hidden;
    /* opacity: 0; */
    /* overflow-y: scroll; */
    -ms-overflow-style: none;
    transform: translateX(120%) skewX(10deg);
}

.menu::-webkit-scrollbar {
    display: none;
}

.menu.toggle {
    visibility: visible;
    /* opacity: 1; */
    transform: translateX(0) skewX(0);
}

.menu .menu-content .menu-content-top {
    padding: 40px 24px 24px;
    padding-top: 96px;
}

.menu .menu-content .menu-content-bottom {
    padding: 0px 48px;
}

.menu .menu-msg {
    font-family: "Noto Serif KR", serif;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.5;
    color: #604448;
}

.menu .menu-sub {
    display: flex;
    align-items: center;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.33;
    color: #ff5316;
    margin-top: 16px;
}

.menu .menu-sub .sub-item {
    margin-right: 16px;
    /* font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px; */
    cursor: pointer;
}

.menu .menu-list {
    display: flex;
    flex-flow: column;
    font-family: "Noto Serif KR", serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #604448;
    margin-top: 48px;
}

.menu .menu-list .menu-item {
    margin-bottom: 40px;
    cursor: pointer;
}

.menu .line {
    height: 1px;
    border-bottom: 1px solid #efebe2;
    margin-top: 40px;
}

.menu .menu-sns {
    display: flex;
    bottom: 0;
    margin-bottom: 40px;
    align-items: center;
    padding-left: 48px;
    padding-right: 48px;
}

.menu .menu-sns .sns-item {
    margin-right: 15px;
    cursor: pointer;
}

.cta-btn {
    width: 264px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #604448;
    font-family: "Noto Serif KR", serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid #c0a691;
}

.kakao-login .cta-btn .icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    background-image: url(https://fly.gitt.co/collins/img/button_kakao.svg);
    -webkit-background-size: unset;
    -moz-background-size: unset;
    -o-background-size: unset;
    background-size: unset;
    background-clip: padding-box;
    background-position: center center;
    background-repeat: no-repeat;
}

.reg-content {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
}

.reg-content .reg-msgs {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.reg-content .reg-msgs .reg-msg1 {
    font-family: "Noto Serif KR", serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #604448;
    margin-bottom: 10px;
}

.reg-content .reg-msgs .reg-msg-s {
    font-family: "Noto Serif KR", serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #604448;
    margin-bottom: 40px;
}

.reg-content .reg-msgs .reg-msg2 {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #c0a691;
    margin-bottom: 48px;
}

.reg-content .reg-msgs .reg-msg3 {
    font-family: "Noto Serif KR", serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #604448;
    margin-bottom: 40px;
}

.reg-content .reg-btns .kakao-login {
    margin-bottom: 8px;
}

.reg-content .reg-btns .email-login {
    margin-bottom: 16px;
}

.reg-content .reg-sub {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    color: #c0a691;
}

.reg-content .reg-sub .sub-item {
    margin-right: 8px;
    cursor: pointer;
}

.reg-content .login-sub {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    color: #604448;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}

.reg-content .login-sub .sub-item {
    margin-right: 8px;
    cursor: pointer;
}

.reg-content .login-sub .sub-item.vline {
    height: 10px;
    width: 1px;
    background-color: #efebe2;
}

.reg-content .reg-msg4 {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #c0a691;
    margin-bottom: 6px;
}

.reg-content .reg-msg5 {
    cursor: pointer;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #604448;
    text-decoration: underline;
}

.reg-info {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    background-color: rgba(231, 225, 211, 0.3);
    width: 264px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.5;
    color: #ff5316;
    margin-top: 40px;
}

.form-content {
    width: 100%;
    margin-top: 58px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.form-content.err {
    margin-top: 56px;
    padding: 40px 0;
}

.errImgWrapper {
    width: 264px;
    height: 264px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.errImgWrapper .errImg {
    width: 140%;
    height: 140%;
}

.reg-btns.err {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.reg-btns.err .other-actions {
    margin-top: 16px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #604448;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reg-btns.err .other-actions .other-action {
    cursor: pointer;
    margin-right: 12px;
    margin-left: 12px;
}

.reg-btns.err .action-vertical-bar {
    width: 1px;
    height: 12px;
    background-color: #efebe2;
}

.form-content .form-msgs {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.form-content .promotion-msgs {
    border-top: 1px solid #604448;
    margin-top: 16px;
    padding: 24px 0;
}


.form-content .promotion-msgs .coupon-msgs {
    display: block;
    text-align: center;
}

.form-content .promotion-msgs .coupon-msgs .orange-text-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
    align-items: center;
}

.form-content .promotion-msgs .coupon-msgs .orange-text-wrapper .check-img {
    width: 12px;
    height: 12px;
    object-fit: contain;
    margin-right: 2px;
}

.form-content .promotion-msgs .coupon-msgs .orange-text-wrapper .orange-text {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.38;
    color: #ff5316;
}

.form-content .promotion-msgs .coupon-msgs .sub-text {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.38;
    color: #c0a691;
    margin-bottom: 16px;
}

.form-content .form-msgs .form-msg1 {
    font-family: "Noto Serif KR", serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #604448;
    margin-bottom: 10px;
}

.form-content .form-msgs .form-msg2 {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #c0a691;
    margin-bottom: 16px;
}

.form-content .form-msgs .form-msg2 .dark-text {
    display: inline-block;
    margin-top: 6px;
    color: #604448;
}

.form-content .form-inputs {
    width: 100%;
    max-width: 264px;
}

.form-content .form-terms {
    display: flex;
    flex-flow: row;
    width: 100%;
    max-width: 264px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #c0a691;
    margin-top: 24px;
}

.form-content .borderline {
    width: 100%;
    border-top: 1px solid #EFEBE2;
    margin-top: 16px;
    padding-bottom: 8px;
}

.form-content .form-terms.privacy {
    margin-top: 8px;
}

.form-content .form-terms .agree-btn {
    display: flex;
}

.form-content .form-terms .checked {
    color: #604448;
}

.form-content .form-terms .agree-btn .sub-form-terms {
    color: #C0A691;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
    line-height: 18px; /* 150% */
    margin-left: 2px;
}

.form-content .form-terms .agree-icon {
    margin-right: 4px;
    float: left;
    display: flex;
    align-items: center;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.form-content .form-terms .agree-icon img {
    width: 100%;
    display: flex;
}

.form-content .form-terms .agree-text {
    line-height: 18px;
}

.form-content .form-terms .agree-msg {
    line-height: 18px;
    width: 10px;
    margin-left: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.form-content .form-terms .agree-msg img {
    display: flex;
    width: 100%;
    height: 10px;
}

.email-reg {
    margin-top: 40px;
}

.email-reg .cta-btn {
    width: 264px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #604448;
    color: #ffffff;
    opacity: 0.2;
    font-family: "Noto Serif KR", serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    border: 0px;
}

.brown-btn .cta-btn {
    width: 264px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #604448;
    color: #ffffff;
    opacity: 0.2;
    font-family: "Noto Serif KR", serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    border: 0px;
    margin-top: 40px;
}

.footer {
    padding: 56px 48px;
    padding-bottom: 136px;
    background-color: #efebe2;
    color: #604448;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 600px;
}

.footer .footer-logo {
    opacity: 0.5;
    margin-bottom: 40px;
}

.footer .footer-logo-img {
    width: 49px;
    height: 17px;
    margin-right: 16px;
}

.footer .footer-company {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
}

.footer .footer-menu {
    font-family: "Noto Serif KR", serif;
    font-weight: 200;
    font-size: 13px;
    line-height: 22px;
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    min-width: 260px;
    margin-top: 24px;
}

.footer .footer-menu .footer-menu-item {
    color: rgba(96, 68, 72, 0.85);
    margin-right: 24px;
    width: 104px;
    margin-bottom: 12px;
    cursor: pointer;
}

.footer .footer-submenu {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    margin-top: 32px;
}

.footer .footer-submenu .footer-menu-item {
    margin-right: 24px;
    opacity: 0.5;
    cursor: pointer;
}

.footer .footer-address {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 11px;
    line-height: 16px;
    margin-top: 8px;
    opacity: 0.5;
}

.footer .footer-subtitle {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 11px;
    line-height: 16px;
    margin-top: 24px;
    opacity: 0.5;
}

.footer .footer-acc {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    margin-top: 26px;
    opacity: 0.5;
}

.footer .footer-acc .footer-menu-sns {
    margin-top: 16px;
    display: flex;
    align-items: center;
}

.footer .footer-acc .footer-menu-sns .sns-item {
    margin-right: 16px;
    cursor: pointer;
}

.footer .footer-acc .footer-menu-action {
    display: flex;
    align-items: center;
}

.footer .footer-acc .footer-menu-action .action-item {
    margin-right: 16px;
    cursor: pointer;
}

.green-btn {
    margin-top: 40px;
}

.green-btn .cta-btn {
    width: 264px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #e0ff43;
    color: #000000;
    font-family: "Noto Serif KR", serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    border: 0;
}

.green-cta-btn {
    width: 264px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #e0ff43;
    color: #000000;
    font-family: "Noto Serif KR", serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    border: 0;
}

.gray-cta-btn {
    width: 264px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #efebe2;
    color: #604448;
    font-family: "Noto Serif KR", serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    border: 0px;
}

.incenseCustomOrangeBtn {
    border: 1px solid #000 !important;
    background-color: #ff5316 !important;
    color: #000000 !important;
}

.disable-cta {
    background-color: #60444833 !important;
    border: none !important;
    color: #FFFFFF !important;
    cursor: unset !important;
}

.incenseCustomWhiteBtn {
    border: 1px solid #000 !important;
    background-color: #fffff5 !important;
    color: #000000 !important;
}

.brown-cta-btn {
    width: 264px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #604448;
    color: #ffffff;
    opacity: 0.2;
    font-family: "Noto Serif KR", serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    border: 0px;
}

.address-btn {
    width: 96px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #efebe2;
    color: #604448;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    margin-left: 16px;
    min-width: 96px;
}

.form-content .form-inputs .address {
    display: flex;
    align-items: flex-end;
}

.password-btn {
    width: 96px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #efebe2;
    color: #604448;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
}

.logout-btn {
    width: 96px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #efebe2;
    color: #604448;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
}

.content .content-container {
    display: flex;
    justify-content: center;
}

.content-container.hidden {
    display: none;
}

.account-content {
    width: 100%;
    max-width: 264px;
}

.account-content-dt {
    width: 350px;
    margin-right: 160px;
    display: none;
}

.account-msgs .account-msg1 {
    font-family: "Noto Serif KR", serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #604448;
}

.account-info-menu {
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
}

.account-content .account-info-menu .menu-item {
    font-family: "Noto Serif KR", serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #604448;
}

.account-content .account-info-menu .menu-item-edit {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    text-decoration: underline;
    color: #604448;
    cursor: pointer;
}

.account-content .account-info {
    margin-top: 24px;
}

.account-content .account-info .info-item {
    margin-bottom: 8px;
    display: flex;
}

.account-content .account-info .info-item .info-item-label {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #c0a691;
    min-width: 56px;
    margin-right: 24px;
}

.account-content .account-info .info-item .info-item-value {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #604448;
}

.account-btns {
    display: flex;
    margin-top: 48px;
}

.account-btns .password-btn {
    min-width: 120px;
    margin-right: 8px;
}

.account-btns .logout-btn {
    min-width: 120px;
}

.home-main {
    width: 100%;
    height: 100%;
}

.home-main .bg-dim {
    visibility: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background-color: rgba(96, 68, 72, 0.5);
}

.home-main.back-blur .bg-dim {
    visibility: visible;
    z-index: 100;
    opacity: 1;
}

.home {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.home .bg-dim {
    visibility: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background-color: rgba(96, 68, 72, 0.5);
}

.home.back-blur .bg-dim {
    visibility: visible;
    z-index: 100;
    opacity: 1;
}

.cartAndMenu {
    display: flex;
    align-items: center;
}

.intro .front1-msg1 {
    font-family: "Noto Serif KR", serif;
    font-weight: 300;
    font-size: 24px;
    line-height: 34px;
    color: #604448;
}

.intro .front1-msg2 {
    font-family: "Noto Serif KR", serif;
    font-weight: 200;
    font-size: 13px;
    line-height: 22px;
    color: #604448;
    margin-top: 8px;
}

.home-cta-btn {
    width: 264px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #e0ff43;
    color: #000000;
    font-family: "Noto Serif KR", serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    border: 0;
    width: 100%;
    position: fixed;
    bottom: 0px;
}

.story {
    padding: 88px 48px;
    background-color: #efebe2;
    display: flex;
    flex-flow: column;
    align-items: center;
    overflow-x: hidden;
    justify-content: center;
}

.story .story-content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.story .front2-img {
    width: 360px;
    height: 148px;
    /* margin-left: 96px;
    margin-top: 58px; */
}

.front2-msgs {
    font-family: "Noto Serif KR", serif;
    font-weight: 200;
    font-size: 13px;
    line-height: 22px;
    color: #604448;
    min-width: 264px;
}

.front2-msgs .msg-header {
    font-family: "Noto Serif KR", serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 48px;
}

.front2-msgs .msg-sub1 {
    margin-top: 16px;
}

.front2-msgs .msg-sub2 {
    margin-top: 16px;
    text-decoration: underline;
}

.m0 {
    margin-top: 0px;
}

.m8 {
    margin-top: 8px;
}

.m16 {
    margin-top: 16px;
}

.m30 {
    margin-top: 30px;
}

.m32 {
    margin-top: 16px;
}

.m24 {
    margin-top: 24px;
}

.m40 {
    margin-top: 40px;
}

.m48 {
    margin-top: 48px;
}

.m64 {
    margin-top: 64px;
}

.intro {
    padding: 0px 48px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
}

.content3 {
    padding: 64px 48px;
    padding-bottom: 120px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: #fffff5;
}

.content3-1 {
    padding: 64px 48px;
    padding-bottom: 120px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: #ecfbff;
}

.content3-2 {
    padding: 64px 48px;
    padding-bottom: 120px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: #f5fffc;
}

.content3-3 {
    padding: 64px 48px;
    padding-bottom: 120px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: #fef7f6;
}

.product {
    padding: 64px 48px;
    padding-bottom: 120px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: #fef7f6;
    padding-bottom: 88px;
    padding-top: 0px;
    overflow-x: hidden;
}

.storyimg {
    width: 264px;
    height: 264px;
}

.swiper-pagination-bullet {
    background: none;
    opacity: 1;
    border: 1px solid #604448;
}

.swiper-pagination-bullet-active {
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
}

.swiper-pagination {
    margin-bottom: 67px;
    text-align: left;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0px;
    left: calc(50% - 140px);
    width: 100%;
}

.image-wrapper {
    width: 100%;
    height: 100%;
    background-color: #fef7f6;
}

.image-wrapper .product_image_mo {
    width: 100%;
}

.intro-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.intro-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.story-msg {
    width: 280px;
    min-height: 278px;
}

.story-msg .msg-title {
    font-family: "Noto Serif KR", serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 32px;
}

.story-msg .msg-body {
    font-family: "Noto Serif KR", serif;
    font-weight: 200;
    font-size: 13px;
    line-height: 22px;
    margin-top: 24px;
}

.story-msg .msg-acc {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    margin-top: 32px;
    cursor: pointer;
    text-decoration: underline;
}

.msg-acc-welcome {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #604448;
    margin-top: 16px;
    cursor: pointer;
    text-decoration: underline;
}

.product-msgs .msg-sub1 {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #604448;
    margin-top: 16px;
}

.product-msgs .msg-title {
    font-family: "Noto Serif KR", serif;
    font-weight: 300;
    font-size: 24px;
    line-height: 34px;
    color: #604448;
    margin-top: 8px;
}

.product-msgs .msg-body1 {
    font-family: "Noto Serif KR", serif;
    font-weight: 200;
    font-size: 13px;
    line-height: 22px;
    color: #604448;
    margin-top: 24px;
}

.product-msgs .msg-body2 {
    font-family: "Noto Serif KR", serif;
    font-weight: 200;
    font-size: 13px;
    line-height: 22px;
    color: #604448;
    margin-top: 16px;
}

.product-msgs .msg-body3 {
    font-family: "Noto Serif KR", serif;
    font-weight: 200;
    font-size: 13px;
    line-height: 22px;
    color: #604448;
    margin-top: 16px;
}

.product-msgs .msg-bottom {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #c0a691;
    margin-top: 8px;
}

.thankyou-img {
    width: 264px;
    height: 264px;
}

.swiper-button-next,
.swiper-button-prev {
    width: 70px;
    height: 70px;
    visibility: hidden;
}

#content3 .swiper-button-next {
    right: calc(50% - 494px);
}

#content3 .swiper-button-prev {
    left: calc(50% - 494px);
}

.show {
    display: block;
}

.login-form {
    width: 100%;
    max-width: 264px;
}

.password-btn {
    cursor: pointer;
}

.terms {
    position: fixed;
    z-index: 1999;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background-color: #fffff5;
    -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    visibility: hidden;
    opacity: 0;
    overflow: scroll;
}

.overlay .header {
    justify-content: flex-end;
    min-height: 56px;
    padding: 0px 0px;
}

.overlay .header .header-close {
    margin-right: 16px;
    cursor: pointer;
}

.terms .terms-content {
    padding: 0px 48px;
    color: #604448;
}

.terms .terms-content .privacy-title {
    font-family: "Noto Serif KR", serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
    width: 100%;
    text-align: center;
}

.terms .terms-content .privacy-title.off {
    color: #c0a691;
}

.terms .terms-titles {
    padding: 0px 48px;
    display: flex;
}

.terms .terms-title {
    font-family: "Noto Serif KR", serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid;
    padding-bottom: 16px;
    cursor: pointer;
    color: #604448;
}

.terms .terms-title.off {
    color: #c0a691;
}

.terms .terms-content .terms-subtitle {
    font-family: "Noto Serif KR", serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
}

.terms .terms-content .terms-body {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 24px;
}

.overlay {
    position: fixed;
    z-index: 1100;
    top: 100%;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    visibility: hidden;
    opacity: 0;
}

.overlay.overlay-toggle {
    visibility: visible;
    opacity: 1;
    top: 0px;
}

.overlay-back {
    visibility: hidden;
    opacity: 0;
    z-index: 1099;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    /*not in ie */
    filter: progid:DXImageTransform.Microsoft.Gradient(startColorstr="#20000000", endColorstr="#20000000");
    /* ie */
    -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.overlay-back.overlay-toggle {
    visibility: visible;
    opacity: 1;
}

.overlay-content {
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding-bottom: 100px;
}

.overlay-header {
    width: 100%;
    height: 48px;
    overflow: hidden;
}

.overlay-notice-header {
    width: 100%;
    height: 64px;
    overflow: hidden;
    background: #f8f8f8;
}

.overlay-notice-header .overlay-notice-title {
    font-size: 13px;
    color: #999999;
    letter-spacing: 0;
    text-align: center;
    text-align: left;
    padding: 14px 16px;
}

.overlay-header .overlay-title {
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 48px;
}

.overlay-close {
    /*background-image: url(/wp-content/themes/shopkeeper-child/images/common/btn/close_30.svg);*/
    width: 30px;
    height: 30px;
    margin-right: 16px;
    -webkit-background-size: unset;
    -moz-background-size: unset;
    -o-background-size: unset;
    background-size: unset;
    background-clip: padding-box;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    float: right;
    position: relative;
    top: -39px;
}

.product .product-msgs {
    overflow-x: hidden;
}

.product .product-msgs {
    overflow-x: hidden;
}

#content4 .product .product-msgs {
    overflow-x: inherit;
}

#ch-plugin .hidden {
    opacity: 0 !important;
}

.intro-video {
    opacity: 0;
    filter: alpha(opacity=0);
}

.intro-video.onShow {
    -webkit-transition: 0.75s 2.2s ease-out, opacity 0.75s 2.2s linear;
    -moz-transition: 0.75s 2.2s ease-out, opacity 0.75s 2.2s linear;
    -ms-transition: 0.75s 2.2s ease-out, opacity 0.75s 2.2s linear;
    -o-transition: 0.75s 2.2s ease-out, opacity 0.75s 2.2s linear;
    transition: 0.75s 2.2s ease-out, opacity 0.75s 2.2s linear;
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-video.showEnd {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.intro-text1 span {
    opacity: 0;
    filter: alpha(opacity=0);
}

.intro-text1.onShow span:nth-child(1) {
    -webkit-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(2) {
    -webkit-transition: opacity 1.6s 0.65s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.6s 0.65s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.6s 0.65s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.6s 0.65s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.6s 0.65s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(3) {
    -webkit-transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(4) {
    -webkit-transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(5) {
    -webkit-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(6) {
    -webkit-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(7) {
    -webkit-transition: opacity 1.7s 0.75s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.7s 0.75s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.7s 0.75s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.7s 0.75s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.7s 0.75s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(8) {
    -webkit-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(9) {
    -webkit-transition: opacity 1.5s 0.55s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.5s 0.55s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.5s 0.55s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.5s 0.55s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.5s 0.55s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(10) {
    -webkit-transition: opacity 1.6s 0.65s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.6s 0.65s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.6s 0.65s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.6s 0.65s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.6s 0.65s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(11) {
    -webkit-transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(12) {
    -webkit-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(13) {
    -webkit-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(14) {
    -webkit-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(15) {
    -webkit-transition: opacity 1.5s 0.55s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.5s 0.55s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.5s 0.55s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.5s 0.55s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.5s 0.55s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(16) {
    -webkit-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(17) {
    -webkit-transition: opacity 1.6s 0.65s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.6s 0.65s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.6s 0.65s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.6s 0.65s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.6s 0.65s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(18) {
    -webkit-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.4s 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(19) {
    -webkit-transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.3s 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.onShow span:nth-child(20) {
    -webkit-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: opacity 1.8s 0.85s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-text1.showEnd span {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.intro-text2 {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
}

.intro-text2.onShow {
    -webkit-transition: 0.75s 1s ease-out, opacity 0.75s 1s linear;
    -moz-transition: 0.75s 1s ease-out, opacity 0.75s 1s linear;
    -ms-transition: 0.75s 1s ease-out, opacity 0.75s 1s linear;
    -o-transition: 0.75s 1s ease-out, opacity 0.75s 1s linear;
    transition: 0.75s 1s ease-out, opacity 0.75s 1s linear;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}

.intro-text2.showEnd {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.intro-cta {
    opacity: 0;
    filter: alpha(opacity=0);
}

.intro-cta.onShow {
    -webkit-transition: 0.75s 1.75s ease-out, opacity 0.75s 1.75s linear;
    -moz-transition: 0.75s 1.75s ease-out, opacity 0.75s 1.75s linear;
    -ms-transition: 0.75s 1.75s ease-out, opacity 0.75s 1.75s linear;
    -o-transition: 0.75s 1.75s ease-out, opacity 0.75s 1.75s linear;
    transition: 0.75s 1.75s ease-out, opacity 0.75s 1.75s linear;
    opacity: 1;
    filter: alpha(opacity=100);
}

.intro-cta.showEnd {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.story-image {
    opacity: 0;
    filter: alpha(opacity=0);
}

.story-image.onShow {
    -webkit-transition: 1s 0.25s ease-out, opacity 1s 0.25s linear;
    -moz-transition: 1s 0.25s ease-out, opacity 1s 0.25s linear;
    -ms-transition: 1s 0.25s ease-out, opacity 1s 0.25s linear;
    -o-transition: 1s 0.25s ease-out, opacity 1s 0.25s linear;
    transition: 1s 0.25s ease-out, opacity 1s 0.25s linear;
    opacity: 1;
    filter: alpha(opacity=100);
}

.story-image.showEnd {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.swiper-pagination.story-image.swiper-pagination-bullets {
    transform: translate(-105px, 0);
}

.story-text1 {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
}

.story-text1.onShow {
    -webkit-transition: 0.75s 0.25s ease-out, opacity 0.75s 0.25s linear;
    -moz-transition: 0.75s 0.25s ease-out, opacity 0.75s 0.25s linear;
    -ms-transition: 0.75s 0.25s ease-out, opacity 0.75s 0.25s linear;
    -o-transition: 0.75s 0.25s ease-out, opacity 0.75s 0.25s linear;
    transition: 0.75s 0.25s ease-out, opacity 0.75s 0.25s linear;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}

.story-text1.showEnd {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.story-text2 {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
    overflow-x: hidden;
}

.story-text2.onShow {
    -webkit-transition: 1s 0.5s ease-out, opacity 1s 0.5s linear;
    -moz-transition: 1s 0.5s ease-out, opacity 1s 0.5s linear;
    -ms-transition: 1s 0.5s ease-out, opacity 1s 0.5s linear;
    -o-transition: 1s 0.5s ease-out, opacity 1s 0.5s linear;
    transition: 1s 0.5s ease-out, opacity 1s 0.5s linear;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}

.story-text2.showEnd {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.story-text3 {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
}

.story-text3.onShow {
    -webkit-transition: 1.25s 0.75s ease-out, opacity 1.25s 0.75s linear;
    -moz-transition: 1.25s 0.75s ease-out, opacity 1.25s 0.75s linear;
    -ms-transition: 1.25s 0.75s ease-out, opacity 1.25s 0.75s linear;
    -o-transition: 1.25s 0.75s ease-out, opacity 1.25s 0.75s linear;
    transition: 1.25s 0.75s ease-out, opacity 1.25s 0.75s linear;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}

.story-text3.showEnd {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.story-detail-text1 {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translateX(40px);
    -moz-transform: translateX(40px);
    -ms-transform: translateX(40px);
    -o-transform: translateX(40px);
    transform: translateX(40px);
    overflow-x: hidden;
}

.story-detail-text1.onShow {
    -webkit-transition: 0.75s 0s ease-out, opacity 0.75s 0s linear;
    -moz-transition: 0.75s 0s ease-out, opacity 0.75s 0s linear;
    -ms-transition: 0.75s 0s ease-out, opacity 0.75s 0s linear;
    -o-transition: 0.75s 0s ease-out, opacity 0.75s 0s linear;
    transition: 0.75s 0s ease-out, opacity 0.75s 0s linear;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
}

.story-detail-text1.showEnd {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.story-detail-text2 {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translateX(40px);
    -moz-transform: translateX(40px);
    -ms-transform: translateX(40px);
    -o-transform: translateX(40px);
    transform: translateX(40px);
    overflow-x: hidden;
}

.story-detail-text2.onShow {
    -webkit-transition: 1s 0.5s ease-out, opacity 1s 0.5s linear;
    -moz-transition: 1s 0.5s ease-out, opacity 1s 0.5s linear;
    -ms-transition: 1s 0.5s ease-out, opacity 1s 0.5s linear;
    -o-transition: 1s 0.5s ease-out, opacity 1s 0.5s linear;
    transition: 1s 0.5s ease-out, opacity 1s 0.5s linear;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
}

.story-detail-text2.showEnd {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.story-detail-btn {
    opacity: 0;
    filter: alpha(opacity=0);
}

.story-detail-btn.onShow {
    -webkit-transition: 1s 0.75s ease-out, opacity 1s 0.75s linear;
    -moz-transition: 1s 0.75s ease-out, opacity 1s 0.75s linear;
    -ms-transition: 1s 0.75s ease-out, opacity 1s 0.75s linear;
    -o-transition: 1s 0.75s ease-out, opacity 1s 0.75s linear;
    transition: 1s 0.75s ease-out, opacity 1s 0.75s linear;
    opacity: 1;
    filter: alpha(opacity=100);
}

.story-detail-btn.showEnd {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.product-text1 {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translateX(-80px);
    -moz-transform: translateX(-80px);
    -ms-transform: translateX(-80px);
    -o-transform: translateX(-80px);
    transform: translateX(-80px);
    overflow-x: hidden;
}

.product-text1.onShow {
    -webkit-transition: 0.75s 0s ease-out, opacity 0.75s 0s linear;
    -moz-transition: 0.75s 0s ease-out, opacity 0.75s 0s linear;
    -ms-transition: 0.75s 0s ease-out, opacity 0.75s 0s linear;
    -o-transition: 0.75s 0s ease-out, opacity 0.75s 0s linear;
    transition: 0.75s 0s ease-out, opacity 0.75s 0s linear;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}

.product-text1.showEnd {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.product-text2 {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translateX(80px);
    -moz-transform: translateX(80px);
    -ms-transform: translateX(80px);
    -o-transform: translateX(80px);
    transform: translateX(80px);
    overflow-x: hidden;
}

.product-text2.onShow {
    -webkit-transition: 0.75s 0s ease-out, opacity 0.75s 0s linear;
    -moz-transition: 0.75s 0s ease-out, opacity 0.75s 0s linear;
    -ms-transition: 0.75s 0s ease-out, opacity 0.75s 0s linear;
    -o-transition: 0.75s 0s ease-out, opacity 0.75s 0s linear;
    transition: 0.75s 0s ease-out, opacity 0.75s 0s linear;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
}

.product-text2.showEnd {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.product-text3 {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
}

.product-text3.onShow {
    -webkit-transition: 0.75s 1s ease-out, opacity 0.75s 1s linear;
    -moz-transition: 0.75s 1s ease-out, opacity 0.75s 1s linear;
    -ms-transition: 0.75s 1s ease-out, opacity 0.75s 1s linear;
    -o-transition: 0.75s 1s ease-out, opacity 0.75s 1s linear;
    transition: 0.75s 1s ease-out, opacity 0.75s 1s linear;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}

.product-text3.showEnd {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.product-text4 {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
}

.product-text4.onShow {
    -webkit-transition: 1s 1.25s ease-out, opacity 1s 1.25s linear;
    -moz-transition: 1s 1.25s ease-out, opacity 1s 1.25s linear;
    -ms-transition: 1s 1.25s ease-out, opacity 1s 1.25s linear;
    -o-transition: 1s 1.25s ease-out, opacity 1s 1.25s linear;
    transition: 1s 1.25s ease-out, opacity 1s 1.25s linear;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}

.product-text4.showEnd {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.product-text5 {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
}

.product-text5.onShow {
    -webkit-transition: 1.25s 1.5s ease-out, opacity 1.25s 1.5s linear;
    -moz-transition: 1.25s 1.5s ease-out, opacity 1.25s 1.5s linear;
    -ms-transition: 1.25s 1.5s ease-out, opacity 1.25s 1.5s linear;
    -o-transition: 1.25s 1.5s ease-out, opacity 1.25s 1.5s linear;
    transition: 1.25s 1.5s ease-out, opacity 1.25s 1.5s linear;
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
}

.product-text5.showEnd {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.product-btn {
    opacity: 0;
    filter: alpha(opacity=0);
}

.product-btn.onShow {
    -webkit-transition: 0.75s 2s ease-out, opacity 0.75s 2s linear;
    -moz-transition: 0.75s 2s ease-out, opacity 0.75s 2s linear;
    -ms-transition: 0.75s 2s ease-out, opacity 0.75s 2s linear;
    -o-transition: 0.75s 2s ease-out, opacity 0.75s 2s linear;
    transition: 0.75s 2s ease-out, opacity 0.75s 2s linear;
    opacity: 1;
    filter: alpha(opacity=100);
}

.product-btn.showEnd {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.product-text6 {
    opacity: 0;
    filter: alpha(opacity=0);
}

.product-text6.onShow {
    -webkit-transition: 0.75s 2s ease-out, opacity 0.75s 2s linear;
    -moz-transition: 0.75s 2s ease-out, opacity 0.75s 2s linear;
    -ms-transition: 0.75s 2s ease-out, opacity 0.75s 2s linear;
    -o-transition: 0.75s 2s ease-out, opacity 0.75s 2s linear;
    transition: 0.75s 2s ease-out, opacity 0.75s 2s linear;
    opacity: 1;
    filter: alpha(opacity=100);
}

.product-text6.showEnd {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}

.login_confirm {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 200;
    font-size: 13px;
    line-height: 18px;
    color: #604448;
    width: 312px;
    min-height: 150px;
}

.login_confirm .login_confirm_msg {
    padding: 24px;
    min-height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.login_confirm .cta_buttons {
    font-family: "Noto Serif KR", serif;
    font-weight: 200;
    font-size: 13px;
    line-height: 22px;
    display: flex;
    height: 48px;
    line-height: 48px;
    width: 100%;
    cursor: pointer;
}

.login_confirm .cta_buttons .cta_cancel {
    width: 50%;
    text-align: center;
    background-color: #ecd7ae;
}

.login_confirm .cta_buttons .cta_accept {
    width: 50%;
    text-align: center;
    background-color: #e0ff43;
}

.login_confirm .cta_buttons .cta_confirm {
    width: 100%;
    text-align: center;
    background-color: #ecd7ae;
}

#products_dt {
    display: none;
}

@media only screen and (max-height: 640px) {
    .home-main {
        height: 640px;
    }

    .intro {
        height: 640px;
    }

    .intro-video {
        height: 640px;
    }
}

.form-order-no {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #604448;
    display: flex;
    margin-bottom: 16px;
}

.form-order-no .ml1 {
    margin-right: 8px;
}

.form-items {
    display: flex;
    justify-content: center;
    flex-flow: column;
}

.form-items .form-item {
    display: flex;
    justify-content: center;
}

.form-items .form-item .title {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #c0a691;
}

.form-items .form-item .value {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #604448;
}

.form-items .form-item .ml1 {
    margin-right: 8px;
}

.form-items .mb1 {
    margin-bottom: 8px;
}

.nm {
    margin-top: 0px;
}

.order-btns {
    display: flex;
    margin-top: 48px;
    flex-wrap: wrap;
    justify-content: center;
}

.order-btns .gray-cta-btn {
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: 8px;
}

#home_cta_btn_dt {
    visibility: hidden;
}

.cartIconWidthNumberDt.hidden {
    display: none;
}

.cartIconWidthNumberMo.hidden {
    display: none;
}

.menuCartIconWidthNumberDt.hidden {
    display: none;
}

.menuCartIconWidthNumberMo.hidden {
    display: none;
}

.menuCartIconWidthNumberMo {
    margin-right: 8px;
}

.cartIconWidthNumberMo {
    margin-right: 10px;
}

@media only screen and (min-width: 768px) {
    #homePage .story {
        padding: 80px 40px;
    }

    #homePage .story .story-content {
        flex-flow: row;
        width: 100%;
        max-width: 1024px;
    }

    #homePage .story .front2-img {
        width: 600px;
        height: 209px;
        margin-right: 88px;
        margin-left: 0px;
        margin-top: 0px;
    }

    #homePage .front2-msgs {
        font-family: "Noto Serif KR", serif;
        font-weight: 200;
        line-height: 22px;
        line-height: 26px;
    }
}

@media only screen and (min-width: 1200px) {
    .visibleInMo {
        display: none;
    }

    .visibleInDt {
        display: inherit;
    }

    .swiper-pagination.story-image.swiper-pagination-bullets {
        transform: translate(0, 0);
    }

    #header_product_dt {
        display: block;
    }

    #header_product_dt .header-inside {
        padding: 40px 48px 0 48px;
    }

    #header_product_dt .header-menu {
        margin: 0;
    }

    #header_cart_dt {
        padding: 0;
    }

    #header_cart_dt .header-inside {
        padding: 40px 56px 0 48px;
    }

    #header_cart_dt .header-menu {
        margin: 0;
    }

    #header_account_dt {
        padding: 0px;
    }

    #header_account_dt .header-inside {
        padding: 40px 56px 0 48px;
    }

    #header_account_dt .header-menu {
        margin: 0;
    }

    #header_dt,
    #header_menu_dt,
    #header_common_dt,
    #header_product_dt,
    #header_cart_dt,
    #header_account_dt {
        visibility: visible;
        z-index: 22;
        opacity: 1;
    }

    #header_menu_dt,
    #header_common_dt {
        position: relative;
    }

    #header_mo,
    #header_menu_mo,
    #header_common_mo,
    #header_product_mo,
    #header_cart_mo,
    #header_account_mo {
        visibility: hidden;
        opacity: 0;
    }

    #header_menu_mo,
    #header_common_mo,
    #header_product_mo,
    #header_cart_mo,
    #header_cart_mo {
        position: fixed;
    }

    .welcome-only-mb {
        display: none;
    }

    .header-inside {
        padding: 40px 0px;
        min-height: 40px;
    }

    .header .header-menu {
        width: 40px;
        height: 40px;
        margin-right: 0px;
        margin-left: 40px;
    }

    .header .header-logo {
        margin-left: 0px;
    }

    .header.popup .header-menu {
        margin-left: 24px;
        margin-top: 24px;
    }

    .header.popup .header-logo {
        margin-top: 22px;
    }

    .header .header-acc {
        width: 40px;
        height: 40px;
        margin-right: 40px;
    }

    .form-content {
        margin-top: 0;
    }

    .intro {
        align-items: center;
        justify-content: center;
    }

    .intro .intro-content {
        width: 100%;
        max-width: 1024px;
    }

    .story .story-content {
        flex-flow: row;
        width: 100%;
        max-width: 1024px;
    }

    .content3 {
        padding: 180px 360px;
        flex-flow: row;
    }

    .content3-1 {
        padding: 180px 360px;
        flex-flow: row;
    }

    .content3-2 {
        padding: 180px 360px;
        flex-flow: row;
    }

    .content3-3 {
        padding: 180px 360px;
        flex-flow: row;
    }

    .story .front2-img {
        width: 600px;
        height: 209px;
        margin-right: 88px;
        margin-left: 0px;
        margin-top: 0px;
    }

    .storyimg {
        width: 500px;
        height: 500px;
    }

    .menu .menu-content .menu-content-top {
        padding: 144px 96px 32px;
    }

    .menu .menu-content .menu-content-bottom {
        padding: 0px 96px;
    }

    .intro .front1-msg1 {
        font-family: "Noto Serif KR", serif;
        font-weight: 300;
        font-size: 36px;
        line-height: 50px;
    }

    .intro .front1-msg2 {
        font-family: "Noto Serif KR", serif;
        font-weight: 200;
        font-size: 15px;
        line-height: 22px;
        margin-top: 16px;
    }

    #home_cta_btn {
        visibility: hidden;
    }

    #home_cta_btn_dt {
        visibility: visible;
    }

    #homePage .story {
        padding: 240px 0px;
    }

    .front2-msgs {
        font-family: "Noto Serif KR", serif;
        font-weight: 200;
        font-size: 15px;
        line-height: 22px;
        line-height: 26px;
    }

    .front2-msgs .msg-header {
        font-family: "Noto Serif KR", serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 4px;
        margin-top: 0px;
    }

    .front2-msgs .msg-sub1 {
        margin-top: 24px;
    }

    .front2-msgs .msg-sub2 {
        margin-top: 24px;
    }

    .story-msg {
        min-width: 350px;
    }

    .story-msg .msg-title {
        font-family: "Noto Serif KR", serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-top: 32px;
        margin-top: 0px;
    }

    .story-msg .msg-body {
        font-family: "Noto Serif KR", serif;
        font-weight: 200;
        font-size: 15px;
        line-height: 22px;
        margin-top: 40px;
    }

    .msg-acc {
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 300;
        font-size: 13px;
        line-height: 18px;
        margin-top: 48px;
    }

    #products_mo {
        display: none;
    }

    #products_dt {
        display: block;
    }

    .menu {
        left: -600px;
        width: 600px;
        transform: none;
        /* transform: translateX(-120%) skewX(-10deg); */
    }

    .menu.toggle {
        left: 0px;
    }

    #header_menu_dt {
        opacity: 0;
    }

    .menu.toggle #header_menu_dt {
        opacity: 1;
    }

    .product {
        padding: 180px 64px;
        background-image: url(https://fly.gitt.co/collins/img/products.png);
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-clip: padding-box;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .product .product-content {
        max-width: 1024px;
        width: 100%;
    }

    .product-msgs .msg-sub1 {
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #604448;
        margin-top: 16px;
    }

    .product-msgs .msg-title {
        font-family: "Noto Serif KR", serif;
        font-weight: 300;
        font-size: 36px;
        line-height: 50px;
        color: #604448;
        margin-top: 8px;
    }

    .product-msgs .msg-body1 {
        font-family: "Noto Serif KR", serif;
        font-weight: 200;
        font-size: 15px;
        line-height: 22px;
        color: #604448;
        margin-top: 48px;
        line-height: 26px;
    }

    .product-msgs .msg-body2 {
        font-family: "Noto Serif KR", serif;
        font-weight: 200;
        font-size: 15px;
        line-height: 22px;
        color: #604448;
        margin-top: 16px;
        line-height: 26px;
    }

    .product-msgs .msg-body3 {
        font-family: "Noto Serif KR", serif;
        font-weight: 200;
        font-size: 15px;
        line-height: 22px;
        color: #604448;
        margin-top: 16px;
        line-height: 26px;
    }

    .product-msgs .msg-bottom {
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 300;
        font-size: 13px;
        line-height: 18px;
        margin-top: 12px;
    }

    .product-msgs {
        min-width: 300px;
    }

    .reg-content {
        margin-top: 47px;
        margin-bottom: 60px;
    }

    .reg-content .reg-msgs .reg-msg1 {
        font-family: "Noto Serif KR", serif;
        font-weight: 200;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 16px;
    }

    .reg-content .reg-msgs .reg-msg2 {
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 48px;
    }

    .reg-content .reg-msgs .reg-msg3 {
        font-family: "Noto Serif KR", serif;
        font-weight: 200;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 48px;
    }

    .cta-btn {
        width: 380px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #604448;
        font-family: "Noto Serif KR", serif;
        font-weight: 200;
        font-size: 15px;
        line-height: 22px;
        font-weight: normal;
        border: 1px solid #c0a691;
    }

    .reg-content .reg-sub {
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
    }

    .reg-content {
        padding-top: 0;
    }

    .reg-content .reg-msg4 {
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        color: #c0a691;
        margin-bottom: 8px;
    }

    .reg-content .reg-msg5 {
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        color: #604448;
        text-decoration: underline;
    }

    .green-btn .cta-btn {
        width: 380px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #e0ff43;
        color: #000000;
        font-family: "Noto Serif KR", serif;
        font-weight: 200;
        font-size: 15px;
        line-height: 22px;
        border: 0;
    }

    .product .green-btn .cta-btn {
        width: 264px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #e0ff43;
        color: #000000;
        font-family: "Noto Serif KR", serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        border: 0;
    }

    .intro .green-btn .cta-btn {
        width: 264px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #e0ff43;
        color: #000000;
        font-family: "Noto Serif KR", serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        border: 0;
    }

    .reg-content .reg-msgs .reg-msg-s {
        font-family: "Noto Serif KR", serif;
        font-weight: 200;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 48px;
    }

    .reg-info {
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 300;
        font-size: 13px;
        line-height: 18px;
        width: 380px;
    }

    .account-msgs .account-msg1 {
        font-family: "Noto Serif KR", serif;
        font-weight: 200;
        font-size: 20px;
        line-height: 28px;
    }

    .account-info-menu {
        display: flex;
        justify-content: space-between;
        margin-top: 48px;
    }

    .account-content {
        max-width: 676px;
    }

    .account-content .line {
        height: 1px;
        border-bottom: 1px solid #efebe2;
        margin-top: 48px;
    }

    .account-content .account-info-menu .menu-item {
        font-family: "Noto Serif KR", serif;
        font-weight: 300;
        font-size: 18px;
        line-height: 26px;
    }

    .account-content .account-info-menu .menu-item-edit {
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
    }

    .account-content .account-info {
        margin-top: 24px;
    }

    .account-content .account-info .info-item {
        margin-bottom: 8px;
    }

    .account-content .account-info .info-item .info-item-label {
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        min-width: 56px;
    }

    .account-content .account-info .info-item .info-item-value {
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
    }

    .account-content-dt {
        display: block;
    }

    .account-content .account-msgs {
        display: none;
    }

    .account-content .account-btns {
        display: none;
    }

    #basicinfo_menu {
        margin-top: 0px;
    }

    .thankyou-img {
        width: 380px;
        height: 380px;
    }

    .swiper-button-next,
    .swiper-button-prev {
        visibility: visible;
    }

    .email-reg .cta-btn {
        width: 380px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #604448;
        color: #ffffff;
        opacity: 0.2;
        font-family: "Noto Serif KR", serif;
        font-weight: 200;
        font-size: 15px;
        line-height: 22px;
        border: 0px;
    }

    .brown-btn .cta-btn {
        width: 380px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #604448;
        color: #ffffff;
        opacity: 0.2;
        font-family: "Noto Serif KR", serif;
        font-weight: 200;
        font-size: 15px;
        line-height: 22px;
        border: 0px;
    }

    .menu .menu-msg {
        font-family: "Noto Serif KR", serif;
        font-weight: 300;
        font-size: 24px;
        line-height: 1.42;
        color: #604448;
    }

    .menu .menu-sub {
        margin-top: 24px;
    }

    .menu .menu-sub .sub-item {
        margin-right: 16px;
        font-family: "Noto Sans KR", sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.43;
        cursor: pointer;
    }

    .login-form {
        max-width: 380px;
    }

    .form-content.err {
        margin-top: 100px;
    }

    .reg-btns.err .other-actions {
        font-size: 14px;
    }

    .form-content .form-inputs {
        max-width: 380px;
    }

    .form-content .form-terms {
        max-width: 380px;
    }

    .form-content .promotion-msgs {
        border-top: 0;
        margin-top: 0;
    }

    .form-content .promotion-msgs .coupon-msgs .orange-text-wrapper .orange-text {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.43;
    }

    .form-content .promotion-msgs .coupon-msgs .sub-text {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.43;
    }


    .form-content .form-msgs .form-msg1 {
        font-family: "Noto Serif KR", serif;
        font-weight: 200;
        font-size: 20px;
        line-height: 28px;
    }

    .form-content .form-msgs .form-msg2 {
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
    }

    .footer .footer-content {
        width: 100%;
        max-width: 1024px;
    }

    .footer .footer-logo-img {
        width: 80px;
        height: 26px;
    }

    .footer .footer-company {
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
    }

    .footer .footer-menu {
        font-family: "Noto Serif KR", serif;
        font-weight: 200;
        font-size: 15px;
        line-height: 22px;
        flex-wrap: wrap;
        width: 70%;
    }

    .footer .footer-menu .footer-menu-item {
        margin-right: 40px;
        width: 140px;
        margin-bottom: 16px;
    }

    .footer .footer-submenu {
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        margin-top: 32px;
    }

    .footer .footer-submenu .footer-menu-item {
        margin-right: 24px;
    }

    .footer .footer-address {
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        margin-top: 14px;
    }

    .footer .footer-subtitle {
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        margin-top: 24px;
        opacity: 0.5;
    }

    .footer .footer-acc {
        font-family: "Noto Sans KR", sans-serif;
        font-weight: 300;
        font-size: 13px;
        line-height: 18px;
        margin-top: 26px;
        float: right;
        display: flex;
        align-items: center;
        margin-top: -22px;
    }

    .footer .footer-acc .footer-menu-sns {
        margin-top: 0;
    }

    .menu .menu-list {
        font-family: "Noto Serif KR", serif;
        font-weight: 300;
        font-size: 18px;
        line-height: 26px;
    }

    .swiper-button-prev:after,
    .swiper-container-rtl .swiper-button-prev:after {
        display: none;
    }

    .swiper-button-next:after,
    .swiper-container-rtl .swiper-button-next:after {
        display: none;
    }

    .overlay {
        max-width: 500px;
        max-height: 600px;
        left: 50%;
        top: 50%;
        margin-left: -250px;
        margin-top: -300px;
        opacity: 0;
        visibility: hidden;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
        -webkit-background-clip: padding-box;
        -moz-background-clip: padding-box;
        background-clip: padding-box;
    }

    .overlay .overlay-content {
        max-height: 550px;
    }

    .overlay.overlay-toggle {
        visibility: visible;
        opacity: 1;
        top: 50%;
    }

    .swiper-pagination {
        margin-bottom: 67px;
        text-align: center;
    }

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 0px;
        left: 0px;
        width: 100%;
    }

    .footer {
        padding: 72px 48px;
        min-height: 600px;
    }
}

@keyframes ldio-gv9lev5i3zo-o {
    0% {
        opacity: 1;
        transform: translate(0 0);
    }
    49.99% {
        opacity: 1;
        transform: translate(48px, 0);
    }
    50% {
        opacity: 0;
        transform: translate(48px, 0);
    }
    100% {
        opacity: 0;
        transform: translate(0, 0);
    }
}

@keyframes ldio-gv9lev5i3zo {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(48px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}

.loadingio-spinner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(96, 68, 72, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000000;
}

.ldio-gv9lev5i3zo div {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    top: 36px;
    left: 25px;
}

.ldio-gv9lev5i3zo div:nth-child(1) {
    background: #604448;
    animation: ldio-gv9lev5i3zo 1s linear infinite;
    animation-delay: -0.5s;
}

.ldio-gv9lev5i3zo div:nth-child(2) {
    background: #ecd7ae;
    animation: ldio-gv9lev5i3zo 1s linear infinite;
    animation-delay: 0s;
}

.ldio-gv9lev5i3zo div:nth-child(3) {
    background: #604448;
    animation: ldio-gv9lev5i3zo-o 1s linear infinite;
    animation-delay: -0.5s;
}

.loadingio-spinner-dual-ball-hqgr8n5kmog {
    width: 120px;
    height: 120px;
    display: inline-block;
    overflow: hidden;
    background: none;
}

.ldio-gv9lev5i3zo {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
    /* see note above */
}

.ldio-gv9lev5i3zo div {
    box-sizing: content-box;
}

/* generated by https://loading.io/ */
.cartIconWidthNumberDt.hidden {
    display: none;
}

.cartIconWidthNumberMo.hidden {
    display: none;
}

.backWordArrow.hidden {
    display: none;
}

.menu #header_menu_mo {
    position: fixed;
}

.menu #header_menu_dt {
    position: fixed;
    width: 600px;
}

body .npay_button_box {
    background-color: transparent !important;
}

.hoverCursorNext:hover {
    cursor: url("../img/arrow_mouse_next_b_72.svg") 30 30, pointer;
}

.hoverCursorPrev:hover {
    cursor: url("../img/arrow_mouse_prev_b_72.svg") 30 30, pointer;
}

