@import '/src/css/variable.scss';


.bubble-content-wrapper{
    position: absolute;
    left: 0;
    top: calc(100% + 8px);
    padding:32px 24px 24px 24px;
    background-color: $main_bg;
    z-index: 50;
    width: auto;
    min-width: 264px;
    max-width: 310px;
    border : solid 1px $main_line;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.05);

    // &.first, &.center{
    //     left: 25%;
    //     transform: translate(-50%, 0);
    //   }

    //   &.second {
    //     left: 75%;
    //     transform: translate(-50%, 0);
    //   }

      

      &.first::after{
        border-top:0px solid transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 10px solid $main_bg;
        content:"";
        position:absolute;
        top:-10px;
        left:45%;
      }

      &.first::before{
        border-top:0px solid transparent;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 10px solid $main_line;
        content:"";
        position:absolute;
        top:-10px;
        left: calc(45% - 2px);
      }


      &.second::after{
        border-top:0px solid transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 10px solid $main_bg;
        content:"";
        position:absolute;
        top:-10px;
        left:50%;
      }

      &.second::before{
        border-top:0px solid transparent;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 10px solid $main_line;
        content:"";
        position:absolute;
        top:-10px;
        left: calc(50% - 2px);
      }


      &.center::after{
        border-top:0px solid transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 10px solid $main_bg;
        content:"";
        position:absolute;
        top:-10px;
        left:50%;
      }

      &.center::before{
        border-top:0px solid transparent;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 10px solid $main_line;
        content:"";
        position:absolute;
        top:-10px;
        left: calc(50% - 2px);
      }


}

@media(min-width: $breakpoint-desktop){


}