@import '/src/css/variable.scss';


.modal-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;
  background-color: $dim_bg;
}

.modal-bg-position {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  background-color: $dim_bg;

  display: flex;
  justify-content: center;
  align-items: center;

  .modal-box {
    background-color: $main_bg;
    min-width: 312px;
    max-width: 312px;
    min-height: 158px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    .cta-btn.green-cta {
      color: #000000;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      font-size: 13px;

    }

    .cta-btn.brown-cta {
      color: #000000;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      font-size: 13px;

    }

    &.vertical {
      min-height: 227px;

    }


    .modal-text {
      @include f12-sa(300);
      color: $main_black;
      text-align: center;
      margin: 8px 32px;
      flex: 1;
      @include flex_center;


    }

    .close {
      position: absolute;
      right: 3px;
      top: 3px;
      cursor: pointer;
    }


    .modal-buttonWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      // position: absolute;
      // bottom:0;
      .modal-button {
        width: 100%;
        height: 48px;
      }

      .cta-btn {
        border: none !important;
      }

      &.vertical {
        display: block;
        padding: 32px;
        padding-top: 0;

        .modal-button-vertical {
          width: 100%;
          margin-bottom: 8px;
        }

      }
    }


  }
}


@media(min-width: $breakpoint-desktop) {


  .modal-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 900;
    background-color: $dim_bg;
  }
  .modal-bg-position {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // z-index: 1000;
    background-color: $dim_bg;

    display: flex;
    justify-content: center;
    align-items: center;


    .modal-box {
      background-color: $main_bg;

      width: 44.9%;
      height: 28%;
      max-width: 460px;
      max-height: 215px;

      min-width: 312px;
      min-height: 158px;

      &.vertical {
        min-height: 284px;

      }

      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;


      .close {
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
      }


      .modal-text {
        @include f14-sa(300);
        color: $main_black;
        text-align: center;
        margin: 8px 32px;
        flex: 1;
        @include flex_center;


      }


      .modal-buttonWrapper {
        width: 100%;
        display: flex;
        align-items: center;
        // position: absolute;
        // bottom:0;
        .modal-button {
          width: 100%;
          height: 48px;
        }
      }
    }
  }


}



