@import '/src/css/variable.scss';





.popup-bg{
    position: fixed;
    top: 0; 
    bottom : 0;
    background-color : $main_bg;
    left: 0;
    right:0;
    z-index: 1000;
    overflow-y: scroll;
    

    .popup-box{
        // position: fixed;
        top: 0;
        bottom : 0;
        left: 0;
        right:0;
        background-color: $main_bg;
        // overflow-y: scroll;
        // min-height: 100%;
        


    }


}

@media(min-width: $breakpoint-desktop){


.popup-bg{
    z-index: 1000;
    position: fixed;
    top: 0; 
    bottom : 0;
    left: 0;
    right:0;
    background-color : $dim_bg;
    display: flex;
    align-items:  center;
    justify-content: center;
    overflow-y: scroll;

    .popup-box{
        top: 94px;
        // left: 50%;
        bottom: unset;
        position: absolute;
        // margin-top: 300px;
        width: 680px;
        // height: 76.5%; 
        overflow-y: hidden;
        margin-bottom: 140px;
        margin-right: auto;
        margin-left: auto;
        // transform: translate(-50%, 0);
        
    }


}









}