@import '../../../css/variable.scss';

.cta-btn {
  border: 0;
  &.default {
    background-color: $main_line;
  }
}

.green-cta {
  background-color: $main_button;
}

.black-cta {
  background-color: $main_black;
}

.brown-cta {
  background-color: $main_etc;
}

.darkBrown-cta {
  background-color: $main_brown;
  color: $main_bg;
  @include f12-sa(300);
}

.white-cta {
  background-color: unset !important;
  border: 1px solid #000000 !important;
  color: #000 !important;
}

.orange-cta {
  color: #000000 !important;
  background-color: #ff5316 !important;
  border: 1px solid #000000 !important;
}

.font-sa {
  @include f13-sa(300);
}

.incenseCustomWhiteBtn {
  border: 1px solid #000;
  background-color: #fffff5;
  color: #000000;

  span {
    color: #ff5316;
  }
}

.disable-cta {
  background-color: #60444833;
  border: none;
  color: #FFFFFF;
}

.incenseCustomOrangeBtn {
  border: 1px solid #000;
  background-color: #ff5316;
  color: #000000;

  span {
    color: #000000;
  }
}

.incenseCustomBlackBtn {
  background-color: #000 !important;
  color: #fffff5 !important;

  span {
    color: #fffff5 !important;
  }
}

.incenseCustomGrayBtn {
  background-color: #ecd7ae;
  color: #000000 !important;
}
