@import '/src/css/variable.scss';

.hamberger{
    width: 32px;
    height :32px; 
}


@media(min-width: $breakpoint-desktop){



    .hamberger{
        width: 40px;
        height :40px; 
    }
    

}