@import '/src/css/variable.scss';

.dropdown-wrapper-simple {
  position: relative;
  z-index: 40;
  user-select: none;
  cursor: pointer;

  // margin-left : 48px;
  // margin-right : 48px;

  .dropdown-default {
    @include f13-sa(400);
    color: $main_brown;
    height: 48px;
    width: 100%;
    border: solid 1px $main_line;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      @include f13-sa(normal);
      margin-left: 16px;
    }
    div {
      margin-right: 16px;
    }
  }

  .dropdown-menu {
    @include f13-sa(400);
    color: $main_brown;
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: 207px;
    background-color: $main_bg;
    border: solid 1px #efebe2;
    border-top: none;
    padding: 16px;
    padding-top: 24px;
    max-height: 280px;
    overflow-y: scroll;

    .dropdown-item:hover {
      .imgAndName {
        color: $main_black;
      }
      .imgAndName.noHover {
        color: $main_brown;
      }
    }

    .dropdown-item {
      // display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      padding-bottom: 16px;
      border-bottom: solid 1px $main_line;
      // height: 100px;
      // &.mobile{
      //     margin-bottom: 26px;
      //     margin-top: 26px;

      // }

      cursor: pointer;

      .imgAndName {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
      }
      .outOfStock {
        color: $main_black;
      }

      img {
        width: 16px;
        height: 16px;
        object-fit: contain;
        margin-right: 8px;
      }
      .price-infos {
        display: flex;
        // flex-direction: column;
        // align-items: flex-end;

        .crosslined {
          text-decoration: line-through;
        }

        .regular-price {
          @include f12-sa(300);
          margin-right: 2px;
        }
        .price {
          @include f12-sa(300);
          color: $main_point;
          margin-right: 8px;
        }

        .discount-rate {
          @include f12-sa(300);
          color: $main_point;
        }
      }
    }

    .dropdown-item:last-of-type {
      border-bottom: none;
    }
    .dropdown-item:first-of-type {
      margin-top: 0;
    }

    .dropdown-item.coupons:hover {
      color: $main_black;
    }
  }
}
@media (min-width: $breakpoint-desktop-home) {
  .dropdown-wrapper-simple {
    margin-left: 0px;
    margin-right: 0px;

    .dropdown-menu {
      &.multiOptions {
        position: absolute;
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .dropdown-wrapper {
    .dropdown-menu {
      max-height: 300px;
      overflow-y: scroll;

      background-color: $product_back_rgb;
    }
  }
}
